import { makeStyles, createStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme =>
  createStyles({
    denseMargin: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    role: {
      fontSize: theme.typography.caption.fontSize,
    },
    commentsInput: {
      fontSize: theme.typography.body2.fontSize,
    },
  })
)

export default useStyles