import { useEffect, useContext, useState, useCallback } from "react"
import FeedbackContext from "state/FeedbackContext"

export const useConcurrentLoading = (concurrentLoadingsCount: number): ((loading: boolean) => void) => {
  const { setLoading } = useContext(FeedbackContext)
  useEffect(() => {
    setLoading(true)
  }, [setLoading])
  const [loadedCounter, setLoadedCounter] = useState(0)
  const onLoading = useCallback(
    (loading: boolean) => {
      if (!loading) {
        setLoadedCounter((prev) => prev + 1)
      }
    },
    [setLoadedCounter]
  )
  useEffect(() => {
    if (loadedCounter >= concurrentLoadingsCount) {
      setLoading(false)
      setLoadedCounter(0)
    }
  }, [loadedCounter, setLoading, concurrentLoadingsCount])
  return onLoading
}
