import React from "react"
import { FormUploadedFile, UploadedFile } from "api/common/models"
import { SectionHeader } from "components/forms/FormFields"
import { FieldArray } from "formik"
import Grid from "@material-ui/core/Grid"
import FileIcon from "@material-ui/icons/DescriptionOutlined"
import DeleteIcon from "@material-ui/icons/Delete"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import AddCircle from "@material-ui/icons/AddCircle"
import CircularProgress from "@material-ui/core/CircularProgress"
import useAddFile from "hooks/useAddFile"
import useStyles from "./FilesFields.styles"

const FilesFields: React.FC<{
  name: string
  files?: FormUploadedFile[] | null
  title: string
  addFileApi: (formData: FormData) => Promise<UploadedFile>
}> = ({ name, files, title, addFileApi }) => {
  const classes = useStyles()
  const { addFileHandler, fileUploading } = useAddFile(addFileApi)

  return <FieldArray name={name}>
    {arrayHelpers => (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className={classes.fileTitleContainer}>
            <SectionHeader title={title} />
            <input
              className={classes.fileInput}
              id={`${name}-button-file`}
              type="file"
              onChange={e => addFileHandler(e.target.files, file => arrayHelpers.push({ ...file, isLoose: true }))}
            />
            {fileUploading ?
              <IconButton className={classes.addButton} disabled>
                <CircularProgress size={20} />
              </IconButton>
              :
              <label htmlFor={`${name}-button-file`}>
                <IconButton className={classes.addButton} aria-label="Add" component="span">
                  <AddCircle />
                </IconButton>
              </label>
            }
          </div>
        </Grid>
        {files && files.map((file, index) => (
          <Grid key={index} container alignItems="center">
            <a className={classes.fileLink} href={file.url} rel="noopener noreferrer" target="_blank">
              <Typography variant="body1">{file.isLoose && "* "}</Typography>
              <FileIcon className={classes.fileIcon} />
              <Typography variant="body1">{file.name}</Typography>
            </a>
            <IconButton
              className={classes.deleteButton}
              aria-label="Delete"
              size="small"
              onClick={() => arrayHelpers.remove(index)}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Grid>
        ))}
      </Grid>
    )}
  </FieldArray>
}

export default FilesFields
