import React, { FunctionComponent } from "react"
import useStyles from "./SnackbarWrapper.styles"
import { SnackbarContent, IconButton } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import WarningIcon from "@material-ui/icons/Warning"
import ErrorIcon from "@material-ui/icons/Error"
import InfoIcon from "@material-ui/icons/Info"
import { SvgIconProps } from "@material-ui/core/SvgIcon"
import classNames from "classnames"

export type SnackbarVariant = "success" | "warning" | "error" | "info"

const variantIcon: { [key in SnackbarVariant]: React.ComponentType<SvgIconProps> } = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

interface SnackbarWrapperProps {
  className?: string
  message: React.ReactNode
  onClose: () => void
  variant: SnackbarVariant
}

const SnackbarWrapper: FunctionComponent<SnackbarWrapperProps> = (props) => {
  const classes = useStyles()
  const { className, message, onClose, variant, ...other } = props
  const Icon = variantIcon[variant]

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  )
}

export default SnackbarWrapper
