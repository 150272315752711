import React, { useCallback } from "react"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useSubmit } from "hooks/useSubmit"
import MaterialTypeEditForm from "./editForm/MaterialTypeEditForm"
import { MaterialType, CreateMaterialTypeResponse } from "api/materialTypes/models"
import materialTypesApi from "api/materialTypes/materialTypesApi"
import useQueryParam from "hooks/useQueryParam"

const emptyMaterialType: Omit<MaterialType, "id"> & { id?: string } = {
  comments: "",
  name: "",
}

const MaterialTypeAdd: React.FC = () => {
  const history = useHistory()
  const referrer = useQueryParam("referrer")
  const additionSuccessAction = useCallback(
    (res: CreateMaterialTypeResponse) => history.push(referrer ? `${referrer}?materialTypeId=${res.id}` : "/management/materialTypes"),
    [history, referrer]
  )
  const { t } = useTranslation("api")
  const onSubmit = useSubmit(
    materialTypesApi.createMaterialType,
    t("Form saved"),
    t("Error saving data"),
    additionSuccessAction
  )
  return (
    <MaterialTypeEditForm
      data={emptyMaterialType}
      onSubmit={onSubmit}
    />
  )
}

export default MaterialTypeAdd
