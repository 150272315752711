import React, { Fragment, useCallback } from "react"
import { useHistory, useParams } from "react-router-dom"
import mountingMaterialsApi from "api/mountingMaterials/mountingMaterialsApi"
import { useTranslation } from "react-i18next"
import { useGetDetails } from "hooks/useGetDetails"
import { useSubmit } from "hooks/useSubmit"
import useQueryParam from "hooks/useQueryParam"
import { EditMountingMaterialRequest } from "api/mountingMaterials/models"
import MountingMaterialEditForm, { MountingMaterialEditData } from "./editForm/MountingMaterialEditForm"
import { useGetAll } from "hooks/useGetAll"
import counterpartiesApi from "api/counterparties/counterpartiesApi"
import { useConcurrentLoading } from "hooks/useConcurrentLoading"
import materialTypesApi from "api/materialTypes/materialTypesApi"
import imagesApi from "api/images/imagesApi"

const MountingMaterialEdit: React.FC = () => {
  const { t } = useTranslation("api")
  const history = useHistory()
  const { id } = useParams()

  const onLoading = useConcurrentLoading(3)
  const data = useGetDetails(mountingMaterialsApi.getMountingMaterial, t("Error getting data"), onLoading)
  const suppliers = useGetAll(counterpartiesApi.getAllSuppliers, t("Error getting suppliers"), onLoading)
  const materialTypes = useGetAll(materialTypesApi.getAllMaterialTypes, t("Error getting material types"), onLoading)

  const referrer = useQueryParam("referrer")
  const editSuccessAction = useCallback(
    () => history.push(referrer || "/stock/mountingMaterials"),
    [history, referrer]
  )
  const submitAction = useCallback((formData: EditMountingMaterialRequest) => mountingMaterialsApi.editMountingMaterial(+id, formData), [id])
  const onSubmit = useSubmit(
    submitAction,
    t("Form saved"),
    t("Error saving data"),
    editSuccessAction
  )

  const materialTypeId = useQueryParam("materialTypeId")
  const counterpartyId = useQueryParam("counterpartyId")
  const type = (materialTypeId && materialTypes) ? materialTypes.find(t => t.id === materialTypeId) : undefined
  const supplier = (counterpartyId && suppliers) ? suppliers.find(t => t.id === counterpartyId) : undefined
  const mountingMaterial: MountingMaterialEditData | undefined = data && { ...data, type: type || data.type, supplier: supplier || data.supplier }

  return (
    <Fragment>
      {mountingMaterial && 
        <MountingMaterialEditForm
          data={mountingMaterial}
          suppliers={suppliers}
          types={materialTypes}
          onSubmit={onSubmit}
          addImageApi={imagesApi.addImage}
        />
      }
    </Fragment>
  )
}

export default MountingMaterialEdit
