import React, { Fragment, useCallback } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useGetDetails } from "hooks/useGetDetails"
import { useSubmit } from "hooks/useSubmit"
import useQueryParam from "hooks/useQueryParam"
import MaterialTypeEditForm from "./editForm/MaterialTypeEditForm"
import materialTypesApi from "api/materialTypes/materialTypesApi"
import { EditMaterialTypeRequest } from "api/materialTypes/models"

const MaterialTypeEdit: React.FC = () => {
  const { t } = useTranslation("api")
  const history = useHistory()
  const { id } = useParams()

  const data = useGetDetails(materialTypesApi.getMaterialType, t("Error getting data"))

  const referrer = useQueryParam("referrer")
  const editSuccessAction = useCallback(
    () => history.push(referrer || "/management/materialTypes"),
    [history, referrer]
  )
  const submitAction = useCallback((formData: EditMaterialTypeRequest) => materialTypesApi.editMaterialType(+id, formData), [id])
  const onSubmit = useSubmit(
    submitAction,
    t("Form saved"),
    t("Error saving data"),
    editSuccessAction
  )

  return (
    <Fragment>
      {data && 
        <MaterialTypeEditForm
          data={data}
          onSubmit={onSubmit}
        />
      }
    </Fragment>
  )
}

export default MaterialTypeEdit
