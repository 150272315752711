import React, { Fragment, useCallback } from "react"
import { useHistory, useParams } from "react-router-dom"
import offersApi from "api/offers/offersApi"
import { Offer, EditOfferRequest } from "api/offers/models"
import OfferEditForm from "./editForm/OfferEditForm"
import { useTranslation } from "react-i18next"
import { useGetDetails } from "hooks/useGetDetails"
import { useSubmit } from "hooks/useSubmit"
import counterpartiesApi from "api/counterparties/counterpartiesApi"
import dealersApi from "api/dealers/dealersApi"
import { useGetAll } from "hooks/useGetAll"
import { useConcurrentLoading } from "hooks/useConcurrentLoading"
import filesApi from "api/files/filesApi"
import useQueryParam from "hooks/useQueryParam"
import productsApi from "api/products/productsApi"

const OfferEdit: React.FC = () => {
  const { t } = useTranslation("api")
  const history = useHistory()
  const { id } = useParams()

  const onLoading = useConcurrentLoading(5)
  const editedOffer = useGetDetails<Offer>(offersApi.getOffer, t("Error getting offer data"), onLoading)
  const clients = useGetAll(counterpartiesApi.getAllClients, t("Error getting clients"), onLoading)
  const dealers = useGetAll(dealersApi.getAllDealers, t("Error getting dealers"), onLoading)
  const products = useGetAll(productsApi.getAllProducts, t("Error getting products"), onLoading)
  const suppliers = useGetAll(counterpartiesApi.getAllSuppliers, t("Error getting suppliers"), onLoading)

  const referrer = useQueryParam("referrer")
  const editSuccessAction = useCallback(
    () => history.push(referrer || "/sales/offers"),
    [history, referrer]
  )
  const submitAction = useCallback((formData: EditOfferRequest) => offersApi.editOffer(+id, formData), [id])
  const onSubmit = useSubmit(
    submitAction,
    t("Form saved"),
    t("Error saving data"),
    editSuccessAction
  )
  const counterpartyId = useQueryParam("counterpartyId")
  const client = (counterpartyId && clients && editedOffer) ? clients.find(c => c.id === counterpartyId) : undefined
  const offerData = client && editedOffer ? { ...editedOffer, client } : editedOffer

  return (
    <Fragment>
      {offerData &&
        <OfferEditForm
          offerData={offerData}
          onSubmit={onSubmit}
          clients={clients}
          dealers={dealers}
          products={products}
          suppliers={suppliers}
          addFileApi={filesApi.addFile}
        />
      }
    </Fragment>
  )
}

export default OfferEdit
