import React, { Fragment } from "react"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import useStyles from "./UserEditForm.styles"
import { Formik, Form, Field, FieldProps } from "formik"
import { StandardTextField } from "components/forms/FormFields"
import getValidationSchema from "./UserEditForm.validation"
import { useTranslation } from "react-i18next"
import { User } from "api/user/models"
import AutocompleteField from "components/forms/AutocompleteField"
import { Role } from "api/roles/models"

export type UserEditData = Omit<User, "id"> & {
  id?: string
}

type UserSubmitRequest = Omit<User, "id"> & {
  id?: string
}

const UserEditForm: React.FC<{
  userData?: UserEditData
  roles?: Role[]
  onSubmit: (formData: UserSubmitRequest) => Promise<void>
}> = ({ userData, onSubmit, roles }) => {
  const classes = useStyles()
  const { t } = useTranslation(["users", "general", "userRoles"])
  return (
    <Fragment>
      {userData && roles && (
        <Formik
          initialValues={userData}
          onSubmit={onSubmit}
          validationSchema={getValidationSchema(!userData.id)}
          validateOnChange={false}
        >
          <Form noValidate className={classes.container}>
            <Paper className={classes.paper}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Field name="login">
                    {(fieldProps: FieldProps) => (
                      <StandardTextField {...fieldProps} required label={t("Login")} />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field name="password">
                    {(fieldProps: FieldProps) => (
                      <StandardTextField
                        {...fieldProps}
                        type="password"
                        autoComplete="new-password"
                        required={!userData.id}
                        label={t("New password")}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Field name="firstName">
                    {(fieldProps: FieldProps) => (
                      <StandardTextField {...fieldProps} required label={t("First name")} />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={3}>
                  <Field name="lastName">
                    {(fieldProps: FieldProps) => (
                      <StandardTextField {...fieldProps} required label={t("Last name")} />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field name="email">
                    {(fieldProps: FieldProps) => (
                      <StandardTextField {...fieldProps} required label={t("Email")} />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Field name="roles">
                    {(fieldProps: FieldProps) => (
                      <AutocompleteField<Role | null>
                        {...fieldProps}
                        placeholder={t("Select roles")}
                        label={t("Roles")}
                        multiple
                        required
                        options={roles}
                        getOptionSelected={(option, value) =>
                          option && value ? option.id === value.id : false
                        }
                        getOptionLabel={(m) => t(`userRoles:${m?.name || ""}`)}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </Paper>
            <Grid container justifyContent="center">
              <Grid item xs={6}>
                <Button
                  className={classes.submit}
                  type="submit"
                  variant="contained"
                  fullWidth
                  color="primary"
                >
                  {t("general:Save")}
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      )}
    </Fragment>
  )
}

export default UserEditForm
