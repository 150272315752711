import { useState, useEffect, useContext } from "react"
import FeedbackContext from "state/FeedbackContext"

export const useGetAll = <T>(
  getAll: () => Promise<T>,
  errorMessage: string,
  setLoading?: (loading: boolean) => void
): T | undefined => {
  const [all, setAll] = useState<T | undefined>(undefined)
  const { showSnackbar, setLoading: globalSetLoading } = useContext(FeedbackContext)
  const onLoading = setLoading || globalSetLoading
  useEffect(() => {
    const fetchAll = async () => {
      onLoading(true)
      try {
        const fetched = await getAll()
        setAll(fetched)
      } catch (e) {
        showSnackbar("error", errorMessage)
      }
      onLoading(false)
    }
    fetchAll()
  }, [setAll, getAll, onLoading, showSnackbar, errorMessage])
  return all
}
