/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState, useCallback, useMemo } from "react"
import pick from "lodash/pick"
import reduce from "lodash/reduce"

import { FilterValue, FilterSerializer } from "components/filters/model"

export const useFilters = <T extends { [key: string]: FilterValue<unknown>[] }>(
  initialFilters: T,
  serializers: { [key in keyof T]: FilterSerializer<unknown> }
) => {
  const [filterDrawerOpened, setFilterDrawerOpened] = useState(false)
  const [filters, setFilters] = useState(initialFilters)
  const toggleFilterDrawer = useCallback(() => {
    setFilterDrawerOpened(!filterDrawerOpened)
  }, [setFilterDrawerOpened, filterDrawerOpened])

  const onFilterChange = useCallback(
    (filterName: keyof T, values: unknown[]) => {
      setFilters((prev) => ({ ...prev, [filterName]: values }))
    },
    [setFilters]
  )

  const onFilterRemove = useCallback(
    (filterName: keyof T) => onFilterChange(filterName, []),
    [onFilterChange]
  )

  const serializedFilters = useMemo(
    () =>
      reduce(
        pick(serializers, Object.keys(filters)),
        (result, value, key) => ({ ...result, ...value(key, filters[key]) }),
        {}
      ),
    [filters, serializers]
  )

  return {
    filterDrawerOpened,
    setFilterDrawerOpened,
    toggleFilterDrawer,
    onFilterChange,
    filters,
    onFilterRemove,
    serializedFilters,
  }
}
