export interface ListPage {
  size: number,
  totalElements: number,
  totalPages: number,
  number: number
}

export interface ListModel<T> {
  data: T[]
  page: ListPage
}

export interface UploadedFile {
  id: string
  url: string
  size: number
  contentType: string
  name: string
}

export interface FormUploadedFile {
  id: string
  url?: string
  name: string
  isLoose?: boolean
}

export interface GetListResponse<TData> {
  result: TData[]
  page: ListPage
}

export type EditSubmit<Request, Response = void> = (id: number, data: Request) => Promise<Response>

export type SubmitAction<Request, Response = void> = (data: Request) => Promise<Response>

export enum UnitOfMeasurement {
  PIECE = "PIECE",
  PACKAGE = "PACKAGE",
  METRE = "METRE",
  KILOGRAM = "KILOGRAM",
}

export interface UploadedImage {
  id: string
  urls: {
    full: string
    thumbnail: string
  }
  name: string
}
