import { ChartConfiguration } from "chart.js"

export const doughnutChartConfig: ChartConfiguration = {
  type: "doughnut",
  options: {
    legend: {
      position: "bottom",
      labels: {
        padding: 30,
      }
    },
    layout: {
      padding: {
        top: 50,
      },
    },
    responsive: true,
    title: {
      display: false,
    },
    animation: {
      animateRotate: true
    },
  }
}

export const getBackgroundColor: Chart.Scriptable<Chart.ChartColor> = (context) => {
  const alpha = context.dataset && context.dataset.data && context.dataIndex !== undefined ? context.dataIndex / context.dataset.data.length : 1
  return context.dataIndex !== undefined && context.dataIndex % 2 === 1 ? `rgb(32, 32, 32, ${1 - alpha})` : `rgb(108, 181, 46, ${1 - alpha})`
}