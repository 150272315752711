import React, { useMemo } from "react"
import DataTable, { TableData, HeadRow } from "components/dataTable/DataTable"
import { useTranslation } from "react-i18next"
import { useList } from "hooks/useList"
import userApi from "api/user/userApi"
import { useHistory } from "react-router-dom"
import useRowDelete from "hooks/useRowDelete"

interface Data extends TableData {
  login: string
  name: string
  email: string
  roles: string
}

const filters: { [key: string]: string } = {}

const UsersList: React.FC = () => {
  const { t } = useTranslation(["users", "general", "api", "userRoles"])
  const headRows: HeadRow<Data>[] = useMemo(
    () => [
      { id: "id", numeric: false, disablePadding: false, label: "Id" },
      { id: "login", numeric: false, disablePadding: false, label: t("Login") },
      { id: "name", numeric: false, disablePadding: false, label: t("Name") },
      { id: "email", numeric: false, disablePadding: false, label: t("Email") },
      { id: "roles", numeric: false, disablePadding: false, label: t("Roles") },
    ],
    [t]
  )
  const history = useHistory()

  const { pageData, data, setPageSize, setPageNumber, onSearch, getList } = useList(
    filters,
    userApi.getUsersList
  )

  const addRowAction = () => history.push("/management/users/add")
  const editRowAction = (rowId: string) => history.push(`/management/users/${rowId}/edit`)
  const deleteRowAction = useRowDelete(userApi.deleteUser, getList)

  const rows: Data[] | undefined =
    data &&
    data.map((row) => ({
      id: row.id.toString(),
      login: row.login,
      name: `${row.firstName} ${row.lastName}`,
      email: row.email,
      roles: row.roles.map(r => t(`userRoles:${r.name}`)).join(", ")
    }))
  return (
    <DataTable
      rows={rows}
      headRows={headRows}
      pageData={pageData}
      addRowAction={addRowAction}
      rowClickAction={editRowAction}
      editRowAction={editRowAction}
      deleteRowAction={deleteRowAction}
      setPageNumber={setPageNumber}
      setPageLimit={setPageSize}
      onSearch={onSearch}
    />
  )
}

export default UsersList
