const translation = {
  validation: {
    "Field is required": "Pole jest wymagane",
    "Value is too long": "Wartość jest zbyt długa",
    "Value is too short": "Wartość jest zbyt krótka",
    "Incorrect email": "Niepoprawny adres email",
    NaN: "Wartość nie jest liczbą",
    "Invalid date": "Niepoprawna data",
  },
  api: {
    "Error saving data": "Wystąpił błąd przy zapisywaniu danych",
    "Error getting data": "Wystąpił błąd przy pobieraniu danych",
    "Error getting dealers": "Error getting dealers",
    "Error getting clients": "Error getting clients",
    "Error getting offer data": "Error getting offer data",
    "Form saved": "Formularz został poprawnie zapisany",
    "Error deleting data": "Wystąpił błąd przy usuwaniu danych",
    "Row deleted": "Dane zostały poprawnie usunięte",
    "Error uploading file": "Wystąpił błąd przy załączaniu pliku",
    "Error getting offers summary stats": "Wystąpił błąd przy pobieraniu statystyk",
    "Error getting offers acquisition stats": "Wystąpił błąd przy pobieraniu statystyk",
    "Error getting product data": "Wystąpił błąd przy pobieraniu danych produktu",
    "Error getting products": "Wystąpił błąd przy pobieraniu produktów",
    "Error getting suppliers": "Wystąpił błąd przy pobieraniu dostawców",
    "Error getting material types": "Error getting material types",
    "Error getting mounting materials": "Error getting mounting materials",
  },
  menu: {
    Sales: "Sprzedaż",
    Counterparties: "Kontrahenci",
    "Counterparty editing": "Edycja kontrahenta",
    "Counterparty addition": "Dodawanie kontrahenta",
    "Counterparty details": "Szczegóły kontrahenta",
    Orders: "Zamówienia",
    Offers: "Oferty",
    Stock: "Magazyn",
    Products: "Produkty",
    "Product editing": "Edycja produktu",
    "Product addition": "Dodawanie produktu",
    Complaints: "Reklamacje",
    Logout: "Wyloguj",
    "Offer details": "Szczegóły oferty",
    "Offer editing": "Edycja oferty",
    "Offer addition": "Dodawanie oferty",
    "Offer acquisition": "Pozyskanie ofert",
    "Offer execution": "Reisevorbereitung",
    Statistics: "Statystyki",
    Profits: "Statystyka zysków",
    Management: "Zarządzanie",
    "Material types": "Typy materiałów montażowych",
    "Material type addition": "Dodawanie typu materiałów montażowych",
    "Material type editing": "Edycja typu materiałów montażowych",
    "Mounting materials": "Materiały montażowe",
    "Mounting material editing": "Edycja materiału montażowego",
    "Mounting material addition": "Dodawanie materiału montażowego",
    "Mounting material calculation editing": "Edycja kalkulacji wstępnej",
    "Adding mounting material calculation": "Dodawanie kalkulacji wstępnej",
    Users: "Użytkownicy",
    "User editing": "Edycja użytkownika",
    "User addition": "Dodawanie użytkownika",
  },
  login: {
    "Email Address": "Adres email",
    "Sign in": "Zaloguj się",
    Password: "Hasło",
    "Logging in failed": "Logowanie nie powiodło się",
  },
  "counterparties.edit": {
    "Main data": "Dane ogólne",
    "Address data": "Dane adresowe",
    "Contact data": "Dane kontaktowe",
    "Detailed data": "Dane szczegółowe",
    "Additional contacts": "Dodatkowe osoby kontaktowe",
    Name: "Nazwa",
    "Legal form": "Forma prawna",
    INDIVIDUAL: "Osoba",
    COMPANY: "Firma",
    Honorific: "Nagłówek",
    MR: "Pan",
    MRS: "Pani",
    MR_MRS: "Państwo",
    Role: "Typ",
    CUSTOMER: "Odbiorca",
    SUPPLIER: "Dostawca",
    Street: "Ulica",
    House: "Numer budynku",
    Apartment: "Numer mieszkania",
    "Zip Code": "Kod pocztowy",
    City: "Miasto",
    Country: "Kraj",
    Phone: "Telefon",
    MobilePhone: "Telefon komórkowy",
    Fax: "Fax",
    Email: "Email",
    NIP: "NIP",
    "Payment method": "Sposób płatności",
    BANK_TRANSFER: "Przelew",
    CASH: "Gotówka",
    CHEQUE: "Czek",
    CREDIT: "Kredyt",
    "Down payment": "% przedpłaty",
    "Payment period": "Termin płatności",
    Discount: "% upustu",
    Comments: "Uwagi",
    "Contact person": "Osoba kontaktowa",
    ACName: "Imię i nazwisko",
    ACEmail: "Email",
    ACPhone: "Telefon",
    ACComments: "Uwagi",
    "Acquisition method": "Sposób zdobycia klienta",
    "Edit counterparty": "Edytuj kontrahenta",
  },
  confirmationDialog: {
    "Confirmation title": "Potwierdzenie usunięcia",
    "Confirmation description": "Czy chcesz usunąć ten rekord?",
    Cancel: "Anuluj",
    Delete: "Usuń",
  },
  list: {
    Add: "Dodaj element",
    Delete: "Usuń",
    Edit: "Edytuj",
    Details: "Wyświetl szczegóły",
    "Rows per page": "Wiersze na stronę",
    Actions: "Akcje",
    "No data to display": "Lista jest pusta",
  },
  general: {
    Filters: "Filtry",
    PL: "Polska",
    DE: "Niemcy",
    Yes: "Tak",
    No: "Nie",
    DateFormat: "DD.MM.YYYY",
    Save: "Zapisz",
    Clear: "Wyczyść",
    Urgent: "Pilne",
    Important: "Ważne",
    Normal: "Normalne",
    "N/A": "nd.",
    "Add image": "Dodaj fotografię",
  },
  acquisitionMethods: {
    RECOMMENDATION: "Weiterempfehlung",
    GOOGLE: "Google",
    FACEBOOK: "Facebook",
    INSTAGRAM: "Instagram",
    CAR: "Fahrzeuge",
    EXHIBITION: "Messe",
    BUILDING: "Gebaude",
    FLYER: "Flyer/Prospekt",
    RADIO: "Radio",
    TV: "TV",
    PRESS: "Zeitung",
    EXISTING_CLIENT: "Bestehender",
    CLIENT: "Kunde",
    WEBSITE: "Strona internetowa",
    ARCHITECT: "Architekt",
    OTHER: "Inny",
  },
  offers: {
    Offer: "Oferta",
    Details: "Szczegóły oferty",
    Measurement: "Pomiar",
    "Offer amounts": "Wartość oferty",
    Installation: "Montaż",
    Comments: "Uwagi",
    "Client files": "Pliki dla klienta",
    "Internal files": "Pliki wewnętrzne",
    "Construction address": "Adres budowy",
    Dealer: "Sprzedawca",
    "Select dealer": "Wybierz sprzedawcę",
    Status: "Status",
    "Creation date": "Data utworzenia",
    ID: "ID",
    Priority: "Priorytet",
    Client: "Klient",
    "Select client": "Wybierz klienta",
    "Offer number": "Numer oferty",
    "Construction type": "Typ budowy",
    "Client wish date": "Wunschtermin",
    "Acquisition method": "Sposób pozyskania oferty",
    "Delivery place": "Miejsce dostawy",
    "Offer with measurement": "Pomiar",
    "Measurement until": "Aufmassdatum bis",
    "Measurement date": "Aufmasstermin",
    "Order in progress": "Auftragsversion in arbeit",
    "Sent to customer": "Gesendet an Kunde",
    "Customer confirmed": "Auftrag erhalten",
    "Order checked": "Auftrag gepruft",
    "Offer with advance payment": "Abschlag",
    "Advance payment date": "Abschlagdatum",
    "Payment completed": "Bezahlt",
    "Payment date": "Bezahltdatum",
    "Payment place": "Payment place",
    "Offer amount": "Wartość oferty",
    Costs: "Koszt",
    Profit: "Zysk",
    "Profit percentage": "Zysk %",
    "Installation days": "Dni montażystów",
    "Assemblers count": "Liczba montażystów",
    "Delivery place other": "Inne miejsce dostawy",
    "Add client": "Dodaj nowego klienta",
    "Edit offer": "Edytuj ofertę",
    Type: "Typ",
    Supplier: "Dostawca",
    Quantity: "Wartość",
    Unit: "Jednostka",
    "Running meters": "Metry bieżące",
    Price: "Cena",
    Product: "Produkt",
    Products: "Produkty",
    "Add product type": "Dodaj nowy typ produktu",
    "Select product type": "Wybierz produkt",
    "Product type": "Typ produktu",
    "Add supplier": "Dodaj nowego dostawcę",
    "Select supplier": "Wybierz dostawcę",
    "Offer with installation": "Montaż",
    "Add offer product": "Dodaj kolejny produkt",
    "Calculation value": "Wartość kalulacji",
    "Edit calculation": "Edytuj kalkulację",
    "Mounting materials calculation": "Kalkulacja wstępna",
    "Add offer calculation": "Dodaj kalkulację wstępną",
  },
  "offers.status": {
    OPENED: "Offen",
    IN_PROCESSING: "In Bearbeitung",
    OFFER_READY: "Angebot fertig: Termin&Abschluss!",
    OFFER_AT_CUSTOMER: "Angebot beim Kunden: Abschluss!",
    GET_ORDER: "Auftrag holen (Druck!)",
    ENDED_WITHOUT_ORDER: "Beendet ohne Auftrag",
    ENDED_WITH_ORDER: "Beendet mit Auftrag",
    TRADE: "Handel",
  },
  "offers.constructionType": {
    NEW: "Nowa budowa",
    RENOVATION: "Renowacja",
  },
  "offers.deliveryPlaceType": {
    OFFICE: "Biuro",
    CONSTRUCTION: "BV",
    OTHER: "Inne",
  },
  calculations: {
    "Mounting materials calculation": "Materiały montażowe",
    Material: "Materiał",
    "Select material type": "Wybierz rodzaj materiału",
    "Material type": "Rodzaj materiału",
    "Add material": "Dodaj kolejny materiał",
    "Add material type": "Dodaj nowy typ materiału",
    "Price x Quantity": "Cena x Ilość",
    "Error getting mounting materials": "Error getting mounting materials",
    "Add new mounting material": "Dodaj materiał montażowy",
    Quantity: "Ilość",
    "Unit price": "Cena jednostkowa",
  },
  "stats.profits": {
    "Offers summary": "Podsumowanie ofert",
    "Total count": "Liczba ofert",
    "Total amount": "Całkowita kwota",
    "Total profit": "Całkowity zysk",
    "Average profit": "Średni zysk",
    "Average profit percentage": "Średni zysk procentowy",
  },
  searchBar: {
    Search: "Wyszukaj",
  },
  offerExecution: {
    "Measurement until": "Aufmassdatum",
    "Measurement date": "Aufmasstermin",
    "Order version": "Auftragsversion",
    "Sent to customer": "Gesendet an Kunde",
    "Customer confirmed": "Auftrag erhalten",
    Payment: "Zahlung",
    AdvancePaymentNotPaid: "Zaliczka nie zapłacona",
    AdvancePaymentPaid: "Zaliczka zapłacona",
    NoAdvancePayment: "Bez zaliczki",
  },
  products: {
    Name: "Nazwa",
    Unit: "Jednostka",
    Comment: "Komentarz",
  },
  unitsOfMeasurement: {
    PIECE: "Stck.",
    PACKAGE: "Packung",
    METRE: "lfdm",
    KILOGRAM: "kg",
  },
  materialTypes: {
    Name: "Nazwa",
    Comments: "Komentarz",
  },
  mountingMaterials: {
    Description: "Opis",
    Type: "Typ",
    Supplier: "Dostawca",
    "Index number": "Numer indeksu",
    Location: "Lokalizacja",
    "Purchase price": "Cena zakupu",
    "Selling price": "Cena sprzedaży",
    "Add type": "Dodaj typ",
    "Add supplier": "Dodaj dostawcę",
    "Stock minimum": "Min bestand",
    "Stock maximum": "Max bestand",
    "Select type": "Wybierz typ",
    "Select supplier": "Wybierz dostawcę",
    Markup: "Marża",
    "Purchase unit": "Bestelleinheit",
    "Selling unit": "Kalkulationeinheit",
    Norm: "Norma",
  },
  users: {
    Name: "Name",
    Login: "Login",
    Email: "Email",
    Roles: "Role",
    "New password": "Nowe hasło",
    "First name": "Imię",
    "Last name": "Nazwisko",
    "Select roles": "Wybierz role",
  },
  userRoles: {
    ROLE_ADMIN: "Administrator",
    ROLE_DEALER: "Sprzedawca",
  },
}

export default translation
