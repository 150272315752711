import { getTranslation } from "i18n"

export type GetCounterpartyResponse = Counterparty

export type CreateCounterpartyRequest = Counterparty

export type EditCounterpartyRequest = Counterparty

export interface CreateCounterpartyResponse {
  id: number
}

export type EditCounterpartyResponse = Counterparty

export enum Role {
  CUSTOMER = "CUSTOMER",
  SUPPLIER = "SUPPLIER",
}

export enum PaymentMethod {
  BANK_TRANSFER = "BANK_TRANSFER",
  CASH = "CASH",
  CHEQUE = "CHEQUE",
  CREDIT = "CREDIT",
}

export enum LegalForm {
  INDIVIDUAL = "INDIVIDUAL",
  COMPANY = "COMPANY",
}

export enum AcquisitionMethod {
  RECOMMENDATION = "RECOMMENDATION",
  GOOGLE = "GOOGLE",
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  CAR = "CAR",
  EXHIBITION = "EXHIBITION",
  BUILDING = "BUILDING",
  FLYER = "FLYER",
  RADIO = "RADIO",
  TV = "TV",
  PRESS = "PRESS",
  CLIENT = "CLIENT",
  WEBSITE = "WEBSITE",
  ARCHITECT = "ARCHITECT",
  OTHER = "OTHER",
}

export enum Honorific {
  MR = "MR",
  MRS = "MRS",
  COMPANY = "COMPANY",
  MR_MRS = "MR_MRS",
}

export enum Country {
  PL = "PL",
  DE = "DE",
}

export interface Address {
  street: string
  houseNumber: string
  apartmentNumber: string
  postalCode: string
  city: string
  country: Country
}

export interface Contact {
  phone: string
  mobilePhone: string
  fax: string
  email: string
}

export interface AdditionalContact {
  name: string
  phone: string
  email: string
  comments: string
}

export interface Payment {
  method: PaymentMethod
  downPaymentPercentage: number
  discountPercentage: number
  period: number
}

export interface Counterparty {
  id: string
  name: string
  legalForm: LegalForm
  honorific: Honorific
  address: Address
  contact: Contact
  additionalContacts: AdditionalContact[]
  roles: Role[]
  payment: Payment
  nip: string
  comments: string
  acquisitionMethod: AcquisitionMethod
  website: string
}

export interface DeleteCounterpartyResponse {
  id: string
}

export const getCounterpartyShortName = (counterparty: Counterparty): string =>
  `${counterparty.name}, ${counterparty.address.city}, ${getTranslation(
    "general",
    counterparty.address.country
  )}`
