import { useCallback, useContext } from "react"
import FeedbackContext from "state/FeedbackContext"
import { useTranslation } from "react-i18next"

const useRowDelete = <DeleteResponse>(
  deleteAction: (id: string) => Promise<DeleteResponse>,
  afterDeleteAction: () => void
): ((rowToDelete: string) => Promise<void>) => {
  const { t } = useTranslation("api")
  const { showSnackbar } = useContext(FeedbackContext)
  const deleteRowAction = useCallback(
    async (rowToDelete: string) => {
      try {
        await deleteAction(rowToDelete)
        showSnackbar("success", t("Row deleted"))
        afterDeleteAction()
      } catch (e) {
        showSnackbar("error", t("Error deleting data"))
      }
    },
    [showSnackbar, afterDeleteAction, deleteAction, t]
  )
  return deleteRowAction
}

export default useRowDelete
