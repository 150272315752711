import React, { Fragment, useCallback } from "react"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import { Formik, Form, Field, FieldProps, FieldArray } from "formik"
import { useTranslation } from "react-i18next"
import { MountingMaterial } from "api/mountingMaterials/models"
import AutocompleteField from "components/forms/AutocompleteField"
import Tooltip from "@material-ui/core/Tooltip"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import { Link, useLocation } from "react-router-dom"
import AddCircle from "@material-ui/icons/AddCircle"
import { getTotalAmount, getSellingPrice } from "api/mountingMaterials/utils"
import { Typography } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import useStyles from "./MountingMaterialCalculationEditForm.styles"
import { EditMountingMaterialCalculationRequest, MountingMaterialCalculationItem, MountingMaterialCalculationResponse } from "api/calculations/models"
import { Delete } from "@material-ui/icons"
import validationSchema from "./validationSchema"
import Badge from "components/Badge"
import { StandardTextField } from "components/forms/FormFields"

export type MountingMaterialCalculationEditData = Omit<MountingMaterialCalculationResponse, "id" | "totalAmount"> & {
  id?: string,
  totalAmount?: string
}


const MountingMaterialCalculationEditForm: React.FC<{
  data: MountingMaterialCalculationEditData
  offerId: string
  allMaterials: MountingMaterial[]
  onSubmit: (formData: EditMountingMaterialCalculationRequest) => Promise<void>
}> =
  ({ data, onSubmit, allMaterials, offerId }) => {
    const classes = useStyles()
    const { t } = useTranslation(["calculations", "general", "unitsOfMeasurement"])
    const { pathname } = useLocation()
    const submitHandler = useCallback((formData: EditMountingMaterialCalculationRequest) => {
      onSubmit(formData)
    }, [onSubmit])
    const calculateTotalAmount = (quantity: number, id?: string): string => {
      const materialItem = id && allMaterials.find(item => item.id === id)
      return materialItem ? getTotalAmount(materialItem?.purchasePrice, materialItem?.markup, quantity) : "0"
    }
    const calculateSellingPrice = (id: string): string | undefined => {
      const materialItem = allMaterials.find(item => item.id === id)
      return materialItem && getSellingPrice(materialItem?.purchasePrice, materialItem?.markup)
    }

    return (
      <Fragment>
        {data &&
          <Formik
            initialValues={{ mountingMaterials: data.mountingMaterials, offerId }}
            onSubmit={submitHandler}
            validateOnChange={false}
            validationSchema={validationSchema}
          >
            {({ values }) => (
              <Form noValidate className={classes.container}>
                <Paper className={classes.paper}>
                  <Grid container spacing={2}>
                    <FieldArray name="mountingMaterials">
                      {arrayHelpers => (
                        <Fragment>
                          <Grid item xs={12}>
                            <Typography variant="h6">{t("Mounting materials calculation")}</Typography>
                          </Grid>
                          {values.mountingMaterials?.map((mountingMaterials, index) => {
                            return <Fragment key={index}>
                              <Grid item xs={12}>
                                <div className={classes.materialContainer}>
                                  <Typography className={classes.label} variant="body1">
                                    {`${t("Material")} #${index + 1}`}
                                  </Typography>
                                  <IconButton
                                    className={classes.deleteButton} aria-label="Delete"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <Delete />
                                  </IconButton>
                                </div>
                              </Grid>
                              <Grid item xs={12}>
                                <div className={classes.rowContainer}>
                                  <Field name={`mountingMaterials[${index}].mountingMaterial`}>
                                    {(fieldProps: FieldProps) => (
                                      <AutocompleteField<MountingMaterial | null>
                                        {...fieldProps}
                                        multiple={false}
                                        placeholder={t("Select material type")}
                                        label={t("Material type")}
                                        required
                                        options={allMaterials}
                                        getOptionSelected={(option, value) => (option && value) ? option.id === value.id : false}
                                        getOptionLabel={m => m?.description || ""}
                                      />
                                    )}
                                  </Field>
                                  <Tooltip title={t("Add new mounting material") || ""}>
                                    <Link to={`/stock/mountingMaterials/add?referrer=${encodeURIComponent(pathname)}`}>
                                      <IconButton className={classes.addButton} aria-label={t("Add new mounting material")} component="span">
                                        <AddCircle />
                                      </IconButton>
                                    </Link>
                                  </Tooltip>
                                </div>
                              </Grid>
                              <Grid container justifyContent="space-between" className={classes.secondRow}>
                                <Grid item xs={3}>
                                  <Field name={`mountingMaterials[${index}].quantity`}>
                                    {(fieldProps: FieldProps<MountingMaterialCalculationItem>) => (
                                      <StandardTextField<MountingMaterialCalculationItem>
                                        {...fieldProps}
                                        label={t("Quantity")}
                                        InputProps={{
                                          endAdornment: <InputAdornment position="start">
                                            {mountingMaterials.mountingMaterial?.id
                                              ? t(`unitsOfMeasurement:${allMaterials.find(i => i.id === mountingMaterials.mountingMaterial.id)?.sellingUnit}`)
                                              : ""}
                                          </InputAdornment>,
                                        }}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={3}>
                                  <TextField
                                    className={classes.unit}
                                    label={t("Unit price")}
                                    value={calculateSellingPrice(mountingMaterials.mountingMaterial?.id) || ""}
                                    variant="standard"
                                    InputProps={{
                                      readOnly: true,
                                      endAdornment: "€"
                                    }}
                                    size="medium"
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <div className={classes.badge}>
                                    <Typography variant="body2" component="span" className={classes.label}>{t("Price x Quantity")}:</Typography>
                                    <Badge
                                      label={calculateTotalAmount(mountingMaterials.quantity, mountingMaterials.mountingMaterial?.id)}
                                      backgroundColor="gold"
                                      unit="euro"
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </Fragment>
                          })}
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => arrayHelpers.push({
                                mountingMaterial: null,
                                quantity: 0,
                              })}
                            >
                              {t("Add material")}
                            </Button>
                          </Grid>
                        </Fragment>
                      )}
                    </FieldArray>
                  </Grid>
                </Paper>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={6}>
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      color="primary"
                    >
                      {t("general:Save")}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        }
      </Fragment>
    )
  }

export default MountingMaterialCalculationEditForm
