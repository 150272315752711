/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState, useContext } from "react"
import FeedbackContext from "state/FeedbackContext"
import { useTranslation } from "react-i18next"

const useAddFile = <T>(addFileApi: (formData: FormData) => Promise<T>) => {
  const [fileUploading, setFileUploading] = useState(false)
  const { showSnackbar } = useContext(FeedbackContext)
  const { t } = useTranslation("api")
  const addFileHandler = async (files: FileList | null, onFileUploaded?: (file: T) => void) => {
    if (files && files.length > 0) {
      const filesList = Array.from(files)
      const formData = new FormData()
      formData.append("file", filesList[0])
      setFileUploading(true)
      try {
        const response = await addFileApi(formData)
        if (onFileUploaded) {
          onFileUploaded(response)
        }
      } catch (e) {
        showSnackbar("error", t("Error uploading file"))
      }
      setFileUploading(false)
    }
  }
  return { fileUploading, addFileHandler }
}

export default useAddFile
