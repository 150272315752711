import React, { useCallback } from "react"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useSubmit } from "hooks/useSubmit"
import MountingMaterialEditForm, { MountingMaterialEditData } from "./editForm/MountingMaterialEditForm"
import mountingMaterialsApi from "api/mountingMaterials/mountingMaterialsApi"
import { useConcurrentLoading } from "hooks/useConcurrentLoading"
import { useGetAll } from "hooks/useGetAll"
import counterpartiesApi from "api/counterparties/counterpartiesApi"
import materialTypesApi from "api/materialTypes/materialTypesApi"
import useQueryParam from "hooks/useQueryParam"
import { UnitOfMeasurement } from "api/common/models"
import imagesApi from "api/images/imagesApi"

const emptyMountingMaterial: MountingMaterialEditData = {
  description: "",
  indexNumber: "",
  location: "",
  markup: 100,
  maxStock: 0,
  minStock: 0,
  norm: "",
  purchasePrice: "",
  purchaseUnit: UnitOfMeasurement.PIECE,
  sellingUnit: UnitOfMeasurement.PIECE,
  supplier: null,
  type: null,
  images: [],
}

const MountingMaterialAdd: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation("api")
  const onLoading = useConcurrentLoading(2)
  const suppliers = useGetAll(counterpartiesApi.getAllSuppliers, t("Error getting suppliers"), onLoading)
  const materialTypes = useGetAll(materialTypesApi.getAllMaterialTypes, t("Error getting material types"), onLoading)
  const additionSuccessAction = useCallback(() => history.push("/stock/mountingMaterials"), [history])
  const onSubmit = useSubmit(
    mountingMaterialsApi.createMountingMaterial,
    t("Form saved"),
    t("Error saving data"),
    additionSuccessAction
  )
  const materialTypeId = useQueryParam("materialTypeId")
  const counterpartyId = useQueryParam("counterpartyId")
  const type = (materialTypeId && materialTypes) ? materialTypes.find(t => t.id === materialTypeId) : undefined
  const supplier = (counterpartyId && suppliers) ? suppliers.find(t => t.id === counterpartyId) : undefined
  const mountingMaterial: MountingMaterialEditData = { ...emptyMountingMaterial, type: type || null, supplier: supplier || null }
  return (
    <MountingMaterialEditForm
      data={mountingMaterial}
      suppliers={suppliers}
      types={materialTypes}
      onSubmit={onSubmit}
      addImageApi={imagesApi.addImage}
    />
  )
}

export default MountingMaterialAdd
