import React from "react"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import { FieldProps } from "formik"

const SwitchField: <T>(p: FieldProps<T> & { label: string }) => JSX.Element =
  ({ field, label }) => (
    <FormControlLabel
      control={
        <Switch
          {...field}
          color="primary"
          value={Boolean(field.value) || false}
          checked={Boolean(field.value) || false}
        />
      }
      label={label}
    />
  )

export default SwitchField