import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles, createStyles } from "@material-ui/core"
import classNames from "classnames"

const useStyles = makeStyles(theme =>
  createStyles({
    section: {
      width: "100%",
      paddingBottom: theme.spacing(0.5),
    },
    title: {
      paddingBottom: theme.spacing(1),
    },
    inner: {
      paddingTop: theme.spacing(2),
    }
  }))

const DetailsSection: React.FC<{ title: string | JSX.Element, inner?: boolean }> = ({ children, title, inner }) => {
  const classes = useStyles()
  return (
    <Grid container spacing={2}>
      <div className={classNames({ [classes.inner]: inner }, classes.section)}>
        <Typography variant="h6" component="div" className={classes.title}>{title}</Typography>
        {children}
      </div>
    </Grid>
  )
}

export default DetailsSection
