import React, { Fragment, useCallback } from "react"
import { useHistory, useParams } from "react-router-dom"
import mountingMaterialsApi from "api/mountingMaterials/mountingMaterialsApi"
import { useTranslation } from "react-i18next"
import { useSubmit } from "hooks/useSubmit"
import useQueryParam from "hooks/useQueryParam"
import { useGetAll } from "hooks/useGetAll"
import calculationsApi from "api/calculations/calculationsApi"
import MountingMaterialCalculationEditForm, { MountingMaterialCalculationEditData } from "./MountingMaterialCalculationEditForm"
import { EditMountingMaterialCalculationRequest } from "api/calculations/models"

const MountingMaterialCalculationAdd: React.FC = () => {
  const { t } = useTranslation("api")
  const history = useHistory()
  const { id } = useParams()

  const referrer = useQueryParam("referrer")
  const editSuccessAction = useCallback(
    () => history.push(referrer || "/sales/offers"),
    [history, referrer]
  )
  const submitAction = useCallback((formData: EditMountingMaterialCalculationRequest) => 
    calculationsApi.createCalculation({ ...formData, offerId: id }), [id])
  const onSubmit = useSubmit(
    submitAction,
    t("Form saved"),
    t("Error saving data"),
    editSuccessAction
  )

  const emptyMountingMaterialCalculationData: MountingMaterialCalculationEditData = {
    mountingMaterials: []
  }

  const allMaterials = useGetAll(mountingMaterialsApi.getAllMountingMaterials, t("Error getting mounting materials"))

  return (
    <Fragment>
      {allMaterials && 
        <MountingMaterialCalculationEditForm
          allMaterials={allMaterials}
          data={emptyMountingMaterialCalculationData}
          offerId={id}
          onSubmit={onSubmit}
        />
      }
    </Fragment>
  )
}

export default MountingMaterialCalculationAdd
