import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(1),
  },
  selected: {
    backgroundColor: theme.palette.primary.dark,
  },
}))

export default useStyles