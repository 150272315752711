import React, { ChangeEvent, useMemo } from "react"
import { FormControl, Select, InputLabel, MenuItem, Checkbox, ListItemText, Input } from "@material-ui/core"
import uniqueId from "lodash/uniqueId"

import { FilterComponentProps } from "./model"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

type MultiSelectFilterType = <T>(props: FilterComponentProps<T>) => React.ReactElement

const MultiselectFilter: MultiSelectFilterType = ({ id, selected, available, name, onChange, valueTranslator = label => label }) => {
  const elementId = useMemo(() => uniqueId("multiselect-filter-"), [])
  const onSelectChange = (event: ChangeEvent<{ name?: string | undefined, value: unknown }>) => {
    if (onChange && available) {
      const selectedLabels = event.target.value as string[]
      onChange(id, available.filter(av => selectedLabels.indexOf(valueTranslator(av.label)) > -1))
    }
  }

  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel htmlFor={elementId}>{name}</InputLabel>
      <Select
        multiple
        value={selected.map(s => valueTranslator(s.label))}
        onChange={onSelectChange}
        input={<Input id={elementId} />}
        renderValue={selected => (selected as string[]).join(", ")}
        MenuProps={MenuProps}
      >
        {available && available.map(af => (
          <MenuItem key={af.label} value={valueTranslator(af.label)}>
            <Checkbox checked={selected.map(s => s.label).indexOf(af.label) > -1} />
            <ListItemText primary={valueTranslator(af.label)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default MultiselectFilter