import React, { Fragment, useCallback } from "react"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import useStyles from "./OfferEditForm.styles"
import { Formik, Form, Field, FieldProps, FieldArray } from "formik"
import {
  SelectFormField,
  StandardTextField,
  DateField,
  MultilineTextField,
  SectionHeader,
} from "components/forms/FormFields"
import useFormFieldStyles from "components/forms/FormFields.styles"
import validationSchema from "./OfferEditForm.validation"
import { useTranslation } from "react-i18next"
import {
  AcquisitionMethod,
  Offer,
  OfferStatus,
  ConstructionType,
  DeliveryPlaceType,
  OfferProduct,
} from "api/offers/models"
import { Counterparty, getCounterpartyShortName } from "api/counterparties/models"
import { Dealer } from "api/dealers/models"
import StatusCircle from "components/StatusCircle"
import {
  getProfit,
  getProfitPercentage,
  colorByOfferStatus,
  colorByOfferField,
  getCosts,
} from "api/offers/utils"
import CheckboxField from "components/forms/CheckboxField"
import SwitchField from "components/forms/SwitchField"
import { UploadedFile, FormUploadedFile } from "api/common/models"
import FilesFields from "./FilesFields"
import MeasurementField from "./MeasurementField"
import { Link, useLocation } from "react-router-dom"
import Tooltip from "@material-ui/core/Tooltip"
import AddCircle from "@material-ui/icons/AddCircle"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import AutocompleteField from "components/forms/AutocompleteField"
import Typography from "@material-ui/core/Typography"
import Delete from "@material-ui/icons/Delete"
import { Product } from "api/products/models"
import StickySubmit from "components/forms/StickySubmit"
import Button from "@material-ui/core/Button"
import DetailsSection from "components/details/DetailsSection"
import Badge from "components/Badge"
import EditIcon from "@material-ui/icons/Edit"

export type OfferEditData = Omit<Offer, "id" | "dealer" | "client" | "internalFiles" | "customerFiles"> & {
  id?: string
  dealer: Dealer | null
  client: {
    id: string
    name: string
    address: {
      city: string
      country: string
    }
  } | null
  internalFiles?: FormUploadedFile[] | null
  customerFiles?: FormUploadedFile[] | null
}

type OfferSubmitRequest = Omit<Offer, "id" | "client" | "dealer" | "internalFiles" | "customerFiles"> & {
  id?: string
  clientId: string
  dealerId: string
  internalFiles?: { id: string }[] | null
  customerFiles?: { id: string }[] | null
}

const normalizeOffer: (offer: OfferEditData) => OfferEditData = (offer) => ({
  ...offer,
  installation: offer.installation || {},
  measurement: offer.measurement
    ? {
        advancePayment: offer.measurement.advancePayment || { withAdvancePayment: false },
        customerConfirmed: offer.measurement.customerConfirmed || {},
        measurementDate: offer.measurement.measurementDate || {},
        measurementUntil: offer.measurement.measurementUntil || {},
        orderChecked: offer.measurement.orderChecked || {},
        orderInProgress: offer.measurement.orderInProgress || {},
        payment: offer.measurement.payment || {},
        sentToCustomer: offer.measurement.sentToCustomer || {},
      }
    : {},
  offerProducts: offer.offerProducts || [],
})

const OfferEditForm: React.FC<{
  offerData?: OfferEditData
  onSubmit: (formData: OfferSubmitRequest) => Promise<void>
  clients?: Counterparty[]
  dealers?: Dealer[]
  products?: Product[]
  suppliers?: Counterparty[]
  addFileApi: (formData: FormData) => Promise<UploadedFile>
}> = ({ offerData, onSubmit, clients, dealers, addFileApi, products, suppliers }) => {
  const submitHandler = useCallback(
    (formData: OfferEditData) => {
      const { client, dealer, ...payload } = formData
      if (client && dealer) {
        onSubmit({ ...payload, clientId: client.id, dealerId: dealer.id })
      }
    },
    [onSubmit]
  )
  const classes = useStyles()
  const formStylesClasses = useFormFieldStyles()
  const { t } = useTranslation([
    "offers",
    "general",
    "offers.status",
    "acquisitionMethods",
    "offers.constructionType",
    "offers.deliveryType",
    "unitsOfMeasurement",
  ])
  const normalizedOffer: OfferEditData | undefined = offerData ? normalizeOffer(offerData) : undefined
  const { pathname } = useLocation()
  return (
    <Fragment>
      {normalizedOffer && clients && dealers && products && suppliers && (
        <Formik
          initialValues={normalizedOffer}
          onSubmit={submitHandler}
          validationSchema={validationSchema}
          validateOnChange={false}
        >
          {({ values }) => {
            const costs = getCosts(values.offerProducts, values.calculation?.amount)
            return (
              <Form noValidate className={classes.container}>
                <Paper className={classes.paper}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <div className={classes.rowContainer}>
                        <Field name="client">
                          {(fieldProps: FieldProps) => (
                            <AutocompleteField<Counterparty | null>
                              {...fieldProps}
                              placeholder={t("Select client")}
                              label={t("Client")}
                              multiple={false}
                              required
                              options={clients}
                              getOptionSelected={(option, value) =>
                                option && value ? option.id === value.id : false
                              }
                              getOptionLabel={(c) => (c && getCounterpartyShortName(c)) ?? ""}
                            />
                          )}
                        </Field>
                        <Tooltip title={t("Add client") || ""}>
                          <Link to={`/sales/counterparties/add?referrer=${encodeURIComponent(pathname)}`}>
                            <IconButton
                              className={classes.addButton}
                              aria-label={t("Add client")}
                              component="span"
                            >
                              <AddCircle />
                            </IconButton>
                          </Link>
                        </Tooltip>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Field name="status">
                        {(fieldProps: FieldProps) => (
                          <SelectFormField
                            {...fieldProps}
                            label={t("Status")}
                            options={(Object.keys(OfferStatus) as OfferStatus[]).map((el) => ({
                              value: el,
                              label: (
                                <div className={classes.rowCenterContainer}>
                                  <StatusCircle
                                    className={classes.statusCircle}
                                    color={colorByOfferStatus[el]}
                                  />
                                  {t(`offers.status:${el}`)}
                                </div>
                              ),
                            }))}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      <Field name="dealer">
                        {(fieldProps: FieldProps) => (
                          <SelectFormField<Dealer>
                            {...fieldProps}
                            placeholder={t("Select dealer")}
                            label={t("Dealer")}
                            uniqueIdentifier={(dealer) => dealer.id}
                            options={dealers.map((d) => ({
                              value: d,
                              label: d.name,
                            }))}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper className={classes.paper}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <SectionHeader title={t("Details")} />
                    </Grid>
                    <Grid item xs={2}>
                      <Field name="priority">
                        {(fieldProps: FieldProps) => (
                          <StandardTextField {...fieldProps} required label={t("Priority")} />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={2}>
                      <Field name="creationDate">
                        {(fieldProps: FieldProps) => (
                          <DateField
                            {...fieldProps}
                            required
                            label={t("Creation date")}
                            format={t("general:DateFormat")}
                            clearLabel={t("general:Clear")}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={3}>
                      <Field name="offerNumber">
                        {(fieldProps: FieldProps) => (
                          <StandardTextField {...fieldProps} label={t("Offer number")} />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={5}>
                      <Field name="acquisitionMethod">
                        {(fieldProps: FieldProps) => (
                          <SelectFormField
                            {...fieldProps}
                            label={t("Acquisition method")}
                            options={(Object.keys(AcquisitionMethod) as AcquisitionMethod[]).map((el) => ({
                              value: el,
                              label: t(`acquisitionMethods:${el}`),
                            }))}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={3}>
                      <Field name="construction.type">
                        {(fieldProps: FieldProps) => (
                          <SelectFormField
                            {...fieldProps}
                            label={t("Construction type")}
                            options={(Object.keys(ConstructionType) as ConstructionType[]).map((el) => ({
                              value: el,
                              label: t(`offers.constructionType:${el}`),
                            }))}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={7}>
                      <Field name="construction.address">
                        {(fieldProps: FieldProps) => (
                          <StandardTextField {...fieldProps} label={t("Construction address")} />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={2}>
                      <Field name="clientWishDate">
                        {(fieldProps: FieldProps) => (
                          <DateField
                            {...fieldProps}
                            label={t("Client wish date")}
                            format={t("general:DateFormat")}
                            clearLabel={t("general:Clear")}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={3}>
                      <Field name="deliveryPlace.type">
                        {(fieldProps: FieldProps) => (
                          <SelectFormField
                            {...fieldProps}
                            label={t("Delivery place")}
                            options={(Object.keys(DeliveryPlaceType) as DeliveryPlaceType[]).map((el) => ({
                              value: el,
                              label: t(`offers.deliveryPlaceType:${el}`),
                            }))}
                          />
                        )}
                      </Field>
                    </Grid>
                    {values.deliveryPlace.type === DeliveryPlaceType.OTHER && (
                      <Grid item xs={9}>
                        <Field name="deliveryPlace.other">
                          {(fieldProps: FieldProps) => (
                            <StandardTextField {...fieldProps} label={t("Delivery place other")} />
                          )}
                        </Field>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="withMeasurement">
                      {(fieldProps: FieldProps) => (
                        <SwitchField {...fieldProps} label={t("Offer with measurement")} />
                      )}
                    </Field>
                  </Grid>
                </Paper>
                {values.withMeasurement && (
                  <Paper className={classes.paper}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <SectionHeader title={t("Measurement")} />
                      </Grid>
                      <MeasurementField
                        dateName="measurement.measurementUntil.date"
                        dateLabel={t("Measurement until")}
                        commentsName="measurement.measurementUntil.comments"
                        commentsLabel={t("Comments")}
                      />
                      <MeasurementField
                        dateName="measurement.measurementDate.date"
                        dateLabel={t("Measurement date")}
                        commentsName="measurement.measurementDate.comments"
                        commentsLabel={t("Comments")}
                      />
                      <MeasurementField
                        dateName="measurement.orderInProgress.date"
                        dateLabel={t("Order in progress")}
                        commentsName="measurement.orderInProgress.comments"
                        commentsLabel={t("Comments")}
                      />
                      <MeasurementField
                        dateName="measurement.sentToCustomer.date"
                        dateLabel={t("Sent to customer")}
                        commentsName="measurement.sentToCustomer.comments"
                        commentsLabel={t("Comments")}
                      />
                      <MeasurementField
                        dateName="measurement.customerConfirmed.date"
                        dateLabel={t("Customer confirmed")}
                        commentsName="measurement.customerConfirmed.comments"
                        commentsLabel={t("Comments")}
                      />
                      <MeasurementField
                        dateName="measurement.orderChecked.date"
                        dateLabel={t("Order checked")}
                        commentsName="measurement.orderChecked.comments"
                        commentsLabel={t("Comments")}
                      />
                      <Grid item xs={12}>
                        <Field name="measurement.advancePayment.withAdvancePayment">
                          {(fieldProps: FieldProps) => (
                            <CheckboxField {...fieldProps} label={t("Offer with advance payment")} />
                          )}
                        </Field>
                      </Grid>
                      {values.measurement &&
                        values.measurement.advancePayment &&
                        values.measurement.advancePayment.withAdvancePayment && (
                          <Grid container spacing={1} className={classes.measurementFieldGroup}>
                            <MeasurementField
                              dateName="measurement.advancePayment.date"
                              dateLabel={t("Advance payment date")}
                              commentsName="measurement.advancePayment.comments"
                              commentsLabel={t("Comments")}
                            />
                          </Grid>
                        )}
                      <Grid item xs={12}>
                        <Field name="measurement.payment.paymentCompleted">
                          {(fieldProps: FieldProps) => (
                            <CheckboxField {...fieldProps} label={t("Payment completed")} />
                          )}
                        </Field>
                      </Grid>
                      {values.measurement &&
                        values.measurement.payment &&
                        values.measurement.payment.paymentCompleted && (
                          <Grid container spacing={1} className={classes.measurementFieldGroup}>
                            <Grid item xs={3}>
                              <Field name="measurement.payment.date">
                                {(fieldProps: FieldProps) => (
                                  <DateField
                                    {...fieldProps}
                                    label={t("Payment date")}
                                    format={t("general:DateFormat")}
                                    clearLabel={t("general:Clear")}
                                  />
                                )}
                              </Field>
                            </Grid>
                            <Grid item xs={9}>
                              <Field name="measurement.payment.paymentPlace">
                                {(fieldProps: FieldProps) => (
                                  <StandardTextField {...fieldProps} label={t("Payment place")} />
                                )}
                              </Field>
                            </Grid>
                          </Grid>
                        )}
                    </Grid>
                  </Paper>
                )}
                <Paper className={classes.paper}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <SectionHeader title={t("Offer amounts")} />
                    </Grid>
                    <Grid item xs={3}>
                      <Field name="amounts.offerAmount">
                        {(fieldProps: FieldProps) => (
                          <StandardTextField
                            {...fieldProps}
                            InputProps={{
                              style: { backgroundColor: colorByOfferField.offerAmount },
                              className: classes.amountsField,
                              endAdornment: <InputAdornment position="start">€</InputAdornment>,
                            }}
                            label={t("Offer amount")}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        className={formStylesClasses.denseMargin}
                        fullWidth
                        InputProps={{
                          readOnly: true,
                          style: { backgroundColor: colorByOfferField.costs },
                          className: classes.amountsField,
                          endAdornment: <InputAdornment position="start">€</InputAdornment>,
                        }}
                        value={costs}
                        label={t("Costs")}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        className={formStylesClasses.denseMargin}
                        fullWidth
                        InputProps={{
                          readOnly: true,
                          style: { backgroundColor: colorByOfferField.profit },
                          className: classes.amountsField,
                          endAdornment: <InputAdornment position="start">€</InputAdornment>,
                        }}
                        value={getProfit(values.amounts.offerAmount, costs)}
                        label={t("Profit")}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        className={formStylesClasses.denseMargin}
                        fullWidth
                        InputProps={{
                          readOnly: true,
                          style: { backgroundColor: colorByOfferField.profitPercentage },
                          className: classes.amountsField,
                        }}
                        value={getProfitPercentage(values.amounts.offerAmount, costs)}
                        label={t("Profit percentage")}
                      />
                    </Grid>
                  </Grid>
                </Paper>
                <Paper className={classes.paper}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <SectionHeader title={t("Installation")} />
                    </Grid>
                    <Grid item xs={2}>
                      <Field name="installation.days">
                        {(fieldProps: FieldProps) => (
                          <StandardTextField {...fieldProps} label={t("Installation days")} />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={2}>
                      <Field name="installation.assemblersCount">
                        {(fieldProps: FieldProps) => (
                          <StandardTextField {...fieldProps} label={t("Assemblers count")} />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper className={classes.paper}>
                  <Grid container spacing={1}>
                    <FieldArray name="offerProducts">
                      {(arrayHelpers) => (
                        <Fragment>
                          <Grid item xs={12}>
                            <Typography variant="h6">{t("Products")}</Typography>
                          </Grid>
                          {values.offerProducts?.map((offerProduct, index) => (
                            <Fragment key={index}>
                              <Grid item xs={12}>
                                <div className={classes.productContainer}>
                                  <Typography className={classes.productLabel} variant="body1">
                                    {`${t("Product")} #${index + 1}`}
                                  </Typography>
                                  <IconButton
                                    className={classes.deleteButton}
                                    aria-label="Delete"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <Delete />
                                  </IconButton>
                                </div>
                              </Grid>
                              <Grid item xs={12}>
                                <div className={classes.rowContainer}>
                                  <Field name={`offerProducts[${index}].product`}>
                                    {(fieldProps: FieldProps) => (
                                      <AutocompleteField<Product | null>
                                        {...fieldProps}
                                        placeholder={t("Select product type")}
                                        label={t("Product type")}
                                        multiple={false}
                                        required
                                        options={products}
                                        getOptionSelected={(option, value) =>
                                          option && value ? option.id === value.id : false
                                        }
                                        getOptionLabel={(c) => c?.name ?? ""}
                                      />
                                    )}
                                  </Field>
                                  <Tooltip title={t("Add product type") || ""}>
                                    <Link
                                      to={`/management/products/add?referrer=${encodeURIComponent(pathname)}`}
                                    >
                                      <IconButton
                                        className={classes.addButton}
                                        aria-label={t("Add product type")}
                                        component="span"
                                      >
                                        <AddCircle />
                                      </IconButton>
                                    </Link>
                                  </Tooltip>
                                </div>
                              </Grid>
                              <Grid item xs={12}>
                                <div className={classes.rowContainer}>
                                  <Field name={`offerProducts[${index}].supplier`}>
                                    {(fieldProps: FieldProps) => (
                                      <AutocompleteField<Counterparty | null>
                                        {...fieldProps}
                                        placeholder={t("Select supplier")}
                                        label={t("Supplier")}
                                        required
                                        multiple={false}
                                        options={suppliers}
                                        getOptionSelected={(option, value) =>
                                          option && value ? option.id === value.id : false
                                        }
                                        getOptionLabel={(c) => c?.name ?? ""}
                                      />
                                    )}
                                  </Field>
                                  <Tooltip title={t("Add supplier") || ""}>
                                    <Link
                                      to={`/sales/counterparties/add?referrer=${encodeURIComponent(
                                        pathname
                                      )}`}
                                    >
                                      <IconButton
                                        className={classes.addButton}
                                        aria-label={t("Add supplier")}
                                        component="span"
                                      >
                                        <AddCircle />
                                      </IconButton>
                                    </Link>
                                  </Tooltip>
                                </div>
                              </Grid>
                              <Grid item xs={4}>
                                <Field name={`offerProducts[${index}].quantity`}>
                                  {(fieldProps: FieldProps<OfferProduct>) => (
                                    <StandardTextField<OfferProduct>
                                      {...fieldProps}
                                      label={t("Quantity")}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="start">
                                            {offerProduct.product
                                              ? t(`unitsOfMeasurement:${offerProduct.product.unit}`)
                                              : ""}
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  )}
                                </Field>
                              </Grid>
                              <Grid item xs={4}>
                                <Field name={`offerProducts[${index}].runningMeters`}>
                                  {(fieldProps: FieldProps<OfferProduct>) => (
                                    <StandardTextField<OfferProduct>
                                      {...fieldProps}
                                      label={t("Running meters")}
                                    />
                                  )}
                                </Field>
                              </Grid>
                              <Grid item xs={4}>
                                <Field name={`offerProducts[${index}].price`}>
                                  {(fieldProps: FieldProps<OfferProduct>) => (
                                    <StandardTextField<OfferProduct>
                                      {...fieldProps}
                                      label={t("Price")}
                                      InputProps={{
                                        endAdornment: <InputAdornment position="start">€</InputAdornment>,
                                      }}
                                    />
                                  )}
                                </Field>
                              </Grid>
                              <Grid item xs={4}>
                                <Field name={`offerProducts[${index}].withInstallation`}>
                                  {(fieldProps: FieldProps) => (
                                    <CheckboxField {...fieldProps} label={t("Offer with installation")} />
                                  )}
                                </Field>
                              </Grid>
                            </Fragment>
                          ))}
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() =>
                                  arrayHelpers.push({
                                    product: null,
                                    supplier: null,
                                    quantity: 0,
                                    runningMeters: 0,
                                    price: 0,
                                    withInstallation: false,
                                  })
                                }
                              >
                                {t("Add offer product")}
                              </Button>
                            </Grid>
                          </Grid>
                        </Fragment>
                      )}
                    </FieldArray>
                  </Grid>
                </Paper>
                <Paper className={classes.paper}>
                  <DetailsSection
                    title={
                      <div>
                        {t("Mounting materials calculation")}
                        {!values.calculation && offerData && (
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Link
                                to={`/sales/offers/calculation/${
                                  offerData.id
                                }/add?referrer=${encodeURIComponent(pathname)}`}
                                className={classes.addOfferCalculation}
                              >
                                <Button variant="contained" color="primary" size="small">
                                  {t("Add offer calculation")}
                                </Button>
                              </Link>
                            </Grid>
                          </Grid>
                        )}
                      </div>
                    }
                  >
                    {values.calculation && offerData && (
                      <div className={classes.badge}>
                        <Typography variant="body2" component="span" className={classes.label}>
                          {t("Calculation value")}:
                        </Typography>
                        <Badge label={values.calculation.amount} backgroundColor="gold" unit="euro" />
                        <Tooltip title={t("Edit calculation") || ""}>
                          <Link
                            to={`/sales/offers/${offerData.id}/calculation/${
                              values.calculation.id
                            }/edit?referrer=${encodeURIComponent(pathname)}`}
                          >
                            <IconButton
                              className={classes.editButton}
                              aria-label={t("Edit calculation")}
                              component="span"
                            >
                              <EditIcon />
                            </IconButton>
                          </Link>
                        </Tooltip>
                      </div>
                    )}
                  </DetailsSection>
                </Paper>
                <Paper className={classes.paper}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <SectionHeader title={t("Comments")} />
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="comments">
                        {(fieldProps: FieldProps) => <MultilineTextField {...fieldProps} />}
                      </Field>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper className={classes.paper}>
                  <FilesFields
                    name="internalFiles"
                    files={values.internalFiles}
                    title={t("Internal files")}
                    addFileApi={addFileApi}
                  />
                </Paper>
                <Paper className={classes.paper}>
                  <FilesFields
                    name="customerFiles"
                    files={values.customerFiles}
                    title={t("Client files")}
                    addFileApi={addFileApi}
                  />
                </Paper>
                <StickySubmit />
              </Form>
            )
          }}
        </Formik>
      )}
    </Fragment>
  )
}

export default OfferEditForm
