import React, { FunctionComponent } from "react"
import Button from "@material-ui/core/Button"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import { useTranslation } from "react-i18next"
import useStyles from "./LangSwitch.styles"
import { PropTypes } from "@material-ui/core"
import classNames from "classnames"

const LangSwitch: FunctionComponent<{ color?: PropTypes.Color; variant?: "outlined" | "contained" }> = ({
  color,
  variant,
}) => {
  const { i18n } = useTranslation()
  const classes = useStyles()
  return (
    <ButtonGroup color={color} variant={variant} aria-label={`${variant} ${color} button group`}>
      {[...i18n.languages]
        .sort((val1, val2) => val2.localeCompare(val1))
        .map((lang) => (
          <Button
            key={lang}
            className={classNames({ [classes.selected]: i18n.language === lang })}
            onClick={() => i18n.changeLanguage(lang)}
          >
            {lang}
          </Button>
        ))}
    </ButtonGroup>
  )
}

export default LangSwitch
