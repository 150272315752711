import Axios from "axios"
import pickBy from "lodash/pickBy"
import identity from "lodash/identity"
import {
  Offer,
  GetOfferResponse,
  EditOfferRequest,
  EditOfferResponse,
  CreateOfferRequest,
  CreateOfferResponse,
  GetOfferSummaryStatsResponse,
  GetOfferAcquisitionStatsResponse,
} from "./models"
import { ListModel, EditSubmit, GetListResponse } from "api/common/models"

interface OffersApi {
  getOfferList: (
    page: number,
    limit: number,
    filters: { [key: string]: string },
    searchPhrase: string
  ) => Promise<ListModel<Offer>>
  createOffer: (data: CreateOfferRequest) => Promise<CreateOfferResponse>
  editOffer: EditSubmit<EditOfferRequest, EditOfferResponse>
  getOffer: (id: number) => Promise<GetOfferResponse>
  getOfferAcquisitionStats: (filters: { [key: string]: string }) => Promise<GetOfferAcquisitionStatsResponse>
  getOfferSummaryStats: (filters: { [key: string]: string }) => Promise<GetOfferSummaryStatsResponse>
}

const offersApi: OffersApi = {
  getOffer: (id) => Axios.get<GetOfferResponse>(`/api/v1/offers/${id}`).then((response) => response.data),
  createOffer: (data) =>
    Axios.post<CreateOfferResponse>("/api/v1/offers", data).then((response) => response.data),
  editOffer: (id, data) =>
    Axios.put<EditOfferResponse>(`/api/v1/offers/${id}`, data).then((response) => response.data),
  getOfferList: async (page, limit, filters, searchPhrase) => {
    const params = pickBy(
      { page: page.toString(10), limit: limit.toString(10), ...filters, searchPhrase },
      identity
    )
    const urlParams = new URLSearchParams(params)
    const { data } = await Axios.get<GetListResponse<Offer>>(`/api/v1/offers?${urlParams.toString()}`)
    return { data: data.result, page: data.page }
  },
  getOfferAcquisitionStats: async (filters) => {
    const urlParams = new URLSearchParams(pickBy(filters, identity))
    const { data } = await Axios.get<GetOfferAcquisitionStatsResponse>(
      `/api/v1/offers/stats/acquisition-method?${urlParams.toString()}`
    )
    return data
  },
  getOfferSummaryStats: async (filters) => {
    const urlParams = new URLSearchParams(pickBy(filters, identity))
    const { data } = await Axios.get<GetOfferSummaryStatsResponse>(
      `/api/v1/offers/stats/profits?${urlParams.toString()}`
    )
    return data
  },
}

export default offersApi
