import Axios from "axios"
import Cookies from "js-cookie"

Axios.defaults.withCredentials = true

Axios.interceptors.request.use(config => {
  const xsrfValue = Cookies.get(config.xsrfCookieName || "XSRF-TOKEN")
  if (xsrfValue) {
    config.headers = { ...config.headers, [config.xsrfHeaderName || "X-XSRF-TOKEN"]: xsrfValue }
  }
  return config
})

Axios.interceptors.response.use(res => res, error => {
  if (error.response.status === 401 && !error.config.url.includes("/login")) {
    window.location.href = "/login"
  }
  return Promise.reject(error)
})
