import { makeStyles, createStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      maxWidth: "1000px",
      display: "flex",
      flexDirection: "column",
      margin: `${theme.spacing(3)}px ${theme.spacing(3)}px 50px ${theme.spacing(3)}px`,
    },
    paper: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(3),
      width: "100%",
    },
    rowContainer: {
      display: "flex",
    },
    addButton: {
      marginTop: theme.spacing(1),
    },
    materialContainer: {
      display: "flex"
    },
    label: {
      marginTop: theme.spacing(0.25),
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
    },
    deleteButton: {
      padding: theme.spacing(1),
    },
    materialDetailField: {
      marginLeft : theme.spacing(3),
    },
    badge: {
      display: "flex",
      alignItems: "center",
      height: "100%",
    },
    unit: {
      width: "100%",
      marginTop: `${theme.spacing(1)}px`,
      marginBottom: `${theme.spacing(1)}px`,
    },
    secondRow: {
      padding: `${theme.spacing(1)}px`,
    }
  }))

export default useStyles