import React, { ChangeEvent } from "react"
import { FormControl, Select, InputLabel, MenuItem, ListItemText, Input } from "@material-ui/core"
import { FilterComponentProps } from "./model"

const ITEM_HEIGHT = 48
const EMPTY_HEIGHT = 40
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

type SingleSelectFilterType = <T>(props: FilterComponentProps<T>) => React.ReactElement

const SingleSelectFilter: SingleSelectFilterType = ({ id, selected, available, name, onChange, valueTranslator = label => label }) => {
  const onSelectChange = (event: ChangeEvent<{ name?: string | undefined, value: unknown }>) => {
    if (onChange && available) {
      const selectedLabels = event.target.value as string[]
      onChange(id, available.filter(av => selectedLabels.indexOf(valueTranslator(av.label)) > -1))
    }
  }

  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel htmlFor="select-label">{name}</InputLabel>
      <Select
        value={selected.map(s => valueTranslator(s.label))}
        onChange={onSelectChange}
        input={<Input id="select-label" />}
        renderValue={selected => (selected as string[]).join(", ")}
        MenuProps={MenuProps}
        displayEmpty
      >
        <MenuItem style={{ height: EMPTY_HEIGHT }} value="">
          <ListItemText primary="" />
        </MenuItem>
        {available && available.map(af => (
          <MenuItem key={af.label} value={valueTranslator(af.label)}>
            <ListItemText primary={valueTranslator(af.label)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SingleSelectFilter