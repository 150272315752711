import Axios, { AxiosResponse } from "axios"
import { UploadedFile } from "api/common/models"

interface FilesApi {
  addFile: (formData: FormData) => Promise<UploadedFile>
}

const filesApi: FilesApi = {
  addFile: formData => Axios.post<FormData, AxiosResponse<UploadedFile>>(`/api/v1/files`, formData).then(res => res.data),
}

export default filesApi
