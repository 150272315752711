import React from "react"
import EuroSymbol from "@material-ui/icons/EuroSymbol"
import ShoppingCart from "@material-ui/icons/ShoppingCart"
import Assignment from "@material-ui/icons/Assignment"
import People from "@material-ui/icons/People"
import Cached from "@material-ui/icons/Cached"
import Store from "@material-ui/icons/Store"
import Ballot from "@material-ui/icons/Ballot"
import Assessment from "@material-ui/icons/Assessment"
import PieChart from "@material-ui/icons/PieChart"
import TrendingUp from "@material-ui/icons/TrendingUp"
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn"
import Settings from "@material-ui/icons/Settings"
import Apps from "@material-ui/icons/Apps"
import Sort from "@material-ui/icons/Sort"
import PeopleAlt from "@material-ui/icons/PeopleAlt"
import { Permission } from "api/user/models"

export interface MenuConfigItem {
  link?: string
  label: string
  path?: string[]
  icon: JSX.Element
  subLabel?: string
  subItems?: MenuConfigItem[]
  permissions?: Permission[]
}

const menuConfig: MenuConfigItem[] = [
  {
    label: "Sales",
    icon: <EuroSymbol />,
    subItems: [
      {
        link: "/sales/counterparties",
        label: "Counterparties",
        icon: <People />,
        permissions: ["COUNTERPARTIES_READ"]
      },
      {
        link: "/sales/orders",
        label: "Orders",
        icon: <ShoppingCart />,
      },
      {
        link: "/sales/offers",
        label: "Offers",
        icon: <Assignment />,
        permissions: ["OFFERS_READ"]
      },
      {
        link: "/sales/offerExecution/measurementUntil",
        path: ["/sales/offerExecution"],
        label: "Offer execution",
        icon: <AssignmentTurnedInIcon />,
        permissions: ["OFFER_EXECUTION_READ"]
      }
    ],
  },
  {
    label: "Statistics",
    icon: <Assessment />,
    subItems: [
      {
        link: "/stats/offerAcquisition",
        label: "Offer acquisition",
        icon: <PieChart />,
        permissions: ["STATS_OFFER_ACQUISITION_READ"]
      },
      {
        link: "/stats/profit",
        label: "Profits",
        icon: <TrendingUp />,
        permissions: ["STATS_PROFIT_READ"]
      }
    ]
  },
  {
    label: "Stock",
    icon: <Store />,
    subItems: [
      {
        link: "/stock/mountingMaterials",
        label: "Mounting materials",
        icon: <Ballot />,
        permissions: ["MOUNTING_MATERIALS_READ"]
      }
    ]
  },
  {
    label: "Complaints",
    icon: <Cached />,
    link: "/complaints",
  },
  {
    label: "Management",
    icon: <Settings />,
    subItems: [
      {
        link: "/management/products",
        label: "Products",
        icon: <Apps />,
        permissions: ["PRODUCTS_READ"]
      },
      {
        link: "/management/materialTypes",
        label: "Material types",
        icon: <Sort />,
        permissions: ["MOUNTING_MATERIAL_TYPES_READ"]
      },
      {
        link: "/management/users",
        label: "Users",
        icon: <PeopleAlt />,
        permissions: ["ROLES_READ"]
      },
    ]
  },
]

export default menuConfig