import { EditSubmit, ListModel, GetListResponse } from "api/common/models"
import Axios from "axios"
import pickBy from "lodash/pickBy"
import identity from "lodash/identity"
import {
  CreateUserRequest,
  CreateUserResponse,
  EditUserRequest,
  EditUserResponse,
  GetUserResponse,
  User,
  UserProfile,
} from "./models"

interface UserApi {
  getUserProfile: () => Promise<UserProfile>
  getUsersList: (
    page: number,
    limit: number,
    filters: { [key: string]: string },
    searchPhrase: string
  ) => Promise<ListModel<User>>
  deleteUser: (id: string) => Promise<void>
  createUser: (data: CreateUserRequest) => Promise<CreateUserResponse>
  getUser: (id: number) => Promise<GetUserResponse>
  editUser: EditSubmit<EditUserRequest, EditUserResponse>
}

const userApi: UserApi = {
  getUserProfile: () => Axios.get<UserProfile>("/api/v1/auth/user-profile").then((value) => value.data),
  getUsersList: async (page, limit, filters, searchPhrase) => {
    const params = pickBy(
      { page: page.toString(10), limit: limit.toString(10), ...filters, searchPhrase },
      identity
    )
    const urlParams = new URLSearchParams(params)
    const { data } = await Axios.get<GetListResponse<User>>(`/api/v1/users?${urlParams.toString()}`)
    return { data: data.result, page: data.page }
  },
  deleteUser: (id) => Axios.delete(`/api/v1/users/${id}`),
  createUser: (data) =>
    Axios.post<CreateUserResponse>("/api/v1/users", data).then((response) => response.data),
  getUser: (id) => Axios.get<GetUserResponse>(`/api/v1/users/${id}`).then((response) => response.data),
  editUser: (id, data) =>
    Axios.put<EditUserResponse>(`/api/v1/users/${id}`, data).then((response) => response.data),
}

export default userApi
