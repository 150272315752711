import { Dealer } from "./models"
import Axios from "axios"

interface DealersApi {
  getAllDealers: () => Promise<Dealer[]>
}

const dealersApi: DealersApi = {
  getAllDealers: () => Axios.get<Dealer[]>("/api/v1/dealers").then((response) => response.data),
}

export default dealersApi
