import React, { Fragment } from "react"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import useStyles from "./ProductEditForm.styles"
import { Formik, Form, Field, FieldProps } from "formik"
import { SelectFormField, StandardTextField } from "components/forms/FormFields"
import validationSchema from "./ProductEditForm.validation"
import { useTranslation } from "react-i18next"
import { Product } from "api/products/models"
import { UnitOfMeasurement } from "api/common/models"

export type ProductEditData = Omit<Product, "id"> & {
  id?: string
}

type ProductSubmitRequest = Omit<Product, "id"> & {
  id?: string
}

const ProductEditForm: React.FC<{
  productData?: ProductEditData
  onSubmit: (formData: ProductSubmitRequest) => Promise<void>
}> =
  ({ productData, onSubmit }) => {
    const classes = useStyles()
    const { t } = useTranslation(["products", "general", "unitsOfMeasurement"])
    return (
      <Fragment>
        {productData &&
          <Formik
            initialValues={productData}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            validateOnChange={false}>
            {() =>
              <Form noValidate className={classes.container}>
                <Paper className={classes.paper}>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <Field name="name">
                        {(fieldProps: FieldProps) => (
                          <StandardTextField
                            {...fieldProps}
                            required
                            label={t("Name")}
                          />)}
                      </Field>
                    </Grid>
                    <Grid item xs={4}>
                      <Field name="unit">
                        {(fieldProps: FieldProps) => (
                          <SelectFormField
                            {...fieldProps}
                            label={t("Unit")}
                            options={(Object.keys(UnitOfMeasurement) as UnitOfMeasurement[]).map(el => ({
                              value: el,
                              label: t(`unitsOfMeasurement:${el}`)
                            }))}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={4}>
                      <Field name="comment">
                        {(fieldProps: FieldProps) => (
                          <StandardTextField
                            {...fieldProps}
                            label={t("Comment")}
                          />)}
                      </Field>
                    </Grid>
                  </Grid>
                </Paper>
                <Grid container justifyContent="center">
                  <Grid item xs={6}>
                    <Button
                      className={classes.submit}
                      type="submit"
                      variant="contained"
                      fullWidth
                      color="primary"
                    >
                      {t("general:Save")}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            }
          </Formik>
        }
      </Fragment>
    )
  }

export default ProductEditForm
