import { FilterValue, FiltersPillsConfig, DateRange } from "components/filters/model"
import { getStandardPill, getMultiselectFilterRenderer, getSingleSelectFilterRenderer, getDateRangeFilterRenderer, dateRangeSerializer } from "components/filters/utils"
import { ConstructionType, OfferStatus } from "api/offers/models"
import { getTranslation } from "i18n"

export type FiltersModel = {
  constructionType: FilterValue<ConstructionType>[]
  dealer: FilterValue<string>[]
  status: FilterValue<OfferStatus>[]
  creationDate: FilterValue<DateRange>[]
}

type FiltersFields = keyof FiltersModel

const filtersConfig: FiltersPillsConfig<FiltersFields> = {
  constructionType: {
    filterRenderer: ({ valueTranslator, ...restProps }) => getSingleSelectFilterRenderer({
      valueTranslator: label => getTranslation("offers.constructionType", label),
      ...restProps
    }),
    pillRenderer: ({ valueTranslator, ...restProps }) => getStandardPill({
      valueTranslator: label => getTranslation("offers.constructionType", label),
      ...restProps
    }),
  },
  dealer: {
    filterRenderer: ({ valueTranslator, ...restProps }) => getMultiselectFilterRenderer({
      valueTranslator: label => label,
      ...restProps
    }),
    pillRenderer: ({ valueTranslator, ...restProps }) => getStandardPill({
      valueTranslator: label => label,
      ...restProps
    }),
    customSerializer: (filterName: string, filters: FilterValue<string>[]) => ({
      [`${filterName}Id`]: filters.map(fv => fv.value).join()
    })
  },
  status: {
    filterRenderer: ({ valueTranslator, ...restProps }) => getMultiselectFilterRenderer({
      valueTranslator: label => getTranslation("offers.status", label),
      ...restProps
    }),
    pillRenderer: ({ valueTranslator, ...restProps }) => getStandardPill({
      valueTranslator: label => getTranslation("offers.status", label),
      ...restProps
    }),
  },
  creationDate: {
    filterRenderer: getDateRangeFilterRenderer,
    pillRenderer: ({ valueTranslator, ...restProps }) => getStandardPill({
      valueTranslator: label => label,
      ...restProps
    }),
    customSerializer: dateRangeSerializer,
  }
}

export default filtersConfig