import React, { useCallback } from "react"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useSubmit } from "hooks/useSubmit"
import { User } from "api/user/models"
import UserEditForm from "./editForm/UserEditForm"
import userApi from "api/user/userApi"
import { useGetAll } from "hooks/useGetAll"
import rolesApi from "api/roles/rolesApi"

const emptyUser: Omit<User, "id"> & { id?: string } = {
  email: "",
  firstName: "",
  lastName: "",
  login: "",
  roles: [],
}

const UserAdd: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation("api")  
  const roles = useGetAll(rolesApi.getAllRoles, t("Error getting data"))
  const additionSuccessAction = useCallback(() => history.push("/management/users"), [history])
  const onSubmit = useSubmit(
    userApi.createUser,
    t("Form saved"),
    t("Error saving data"),
    additionSuccessAction
  )
  return (
    <UserEditForm
      userData={emptyUser}
      roles={roles}
      onSubmit={onSubmit}
    />
  )
}

export default UserAdd
