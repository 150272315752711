import Axios from "axios"
import pickBy from "lodash/pickBy"
import identity from "lodash/identity"
import {
  MountingMaterial,
  DeleteMountingMaterialResponse,
  GetMountingMaterialResponse,
  EditMountingMaterialRequest,
  EditMountingMaterialResponse,
  CreateMountingMaterialRequest,
  CreateMountingMaterialResponse,
} from "./models"
import { ListModel, EditSubmit, GetListResponse } from "api/common/models"

interface MountingMaterialsApi {
  getMountingMaterialsList: (
    page: number,
    limit: number,
    filters: { [key: string]: string },
    searchPhrase: string
  ) => Promise<ListModel<MountingMaterial>>
  createMountingMaterial: (data: CreateMountingMaterialRequest) => Promise<CreateMountingMaterialResponse>
  deleteMountingMaterial: (id: string) => Promise<DeleteMountingMaterialResponse>
  getMountingMaterial: (id: number) => Promise<GetMountingMaterialResponse>
  editMountingMaterial: EditSubmit<EditMountingMaterialRequest, EditMountingMaterialResponse>
  getAllMountingMaterials: () => Promise<MountingMaterial[]>
}

const mountingMaterialsApi: MountingMaterialsApi = {
  getMountingMaterialsList: async (page, limit, filters, searchPhrase) => {
    const params = pickBy(
      { page: page.toString(10), limit: limit.toString(10), ...filters, searchPhrase },
      identity
    )
    const urlParams = new URLSearchParams(params)
    const { data } = await Axios.get<GetListResponse<MountingMaterial>>(
      `/api/v1/mounting-materials?${urlParams.toString()}`
    )
    return { data: data.result, page: data.page }
  },
  createMountingMaterial: (data) =>
    Axios.post<CreateMountingMaterialResponse>("/api/v1/mounting-materials", data).then(
      (response) => response.data
    ),
  deleteMountingMaterial: (id) =>
    Axios.delete<DeleteMountingMaterialResponse>(`/api/v1/mounting-materials/${id}`).then(
      (response) => response.data
    ),
  getMountingMaterial: (id) =>
    Axios.get<GetMountingMaterialResponse>(`/api/v1/mounting-materials/${id}`).then(
      (response) => response.data
    ),
  editMountingMaterial: (id, data) =>
    Axios.put<EditMountingMaterialResponse>(`/api/v1/mounting-materials/${id}`, data).then(
      (response) => response.data
    ),
  getAllMountingMaterials: async () => {
    const urlParams = new URLSearchParams({
      limit: "9999",
    })
    const {
      data: { result },
    } = await Axios.get<{ result: MountingMaterial[] }>(`/api/v1/mounting-materials?${urlParams.toString()}`)
    return result
  },
}

export default mountingMaterialsApi
