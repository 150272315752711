import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterContainer: {
      margin: theme.spacing(1),
      minWidth: 300,
      maxWidth: 300,
      marginTop: theme.spacing(2),
    }
  }),
)

export default useStyles
