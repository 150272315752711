import React from "react"
import { FieldProps, getIn } from "formik"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import { makeStyles, createStyles } from "@material-ui/core"

const useStyles = makeStyles(
  createStyles({
    fullWidth: {
      width: "100%",
    },
  })
)

const AutocompleteField: <T>(
  p: ((FieldProps<T[]> & { multiple: true }) | (FieldProps<T> & { multiple: false })) & {
    placeholder?: string
    label: string
    options: T[]
    getOptionLabel?: (option: T) => string
    getOptionSelected?: (option: T, value: T) => boolean
    disableClearable?: boolean
    required?: boolean
  }
) => JSX.Element = ({
  field,
  form,
  options,
  placeholder,
  label,
  getOptionLabel,
  disableClearable,
  required,
  getOptionSelected,
  multiple,
}) => {
  const classes = useStyles()
  const error = !!getIn(form.touched, field.name) && getIn(form.errors, field.name)
  return (
    <Autocomplete
      {...field}
      onChange={(event, newValue) => {
        form.setFieldTouched(field.name)
        form.setFieldValue(field.name, newValue, true)
      }}
      onBlur={() => form.setFieldTouched(field.name)}
      multiple={multiple}
      options={options}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      disableClearable={disableClearable}
      className={classes.fullWidth}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          error={Boolean(error)}
          helperText={error}
          placeholder={placeholder}
          label={label}
        />
      )}
    />
  )
}

export default AutocompleteField
