import React, { Fragment, useMemo } from "react"
import { NavLink, Link, useLocation } from "react-router-dom"
import offersApi from "api/offers/offersApi"
import { Offer, DeliveryPlaceType, OfferProduct } from "api/offers/models"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import FormControl from "@material-ui/core/FormControl"
import AccountCircle from "@material-ui/icons/AccountCircle"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import useStyles from "./OfferDetails.styles"
import { useTranslation } from "react-i18next"
import DetailField from "components/DetailField"
import StatusCircle from "components/StatusCircle"
import { getTranslation } from "i18n"
import { Address } from "api/counterparties/models"
import moment from "moment"
import { getProfit, getProfitPercentage, colorByOfferStatus, getCosts } from "api/offers/utils"
import Badge from "components/Badge"
import DetailsSection from "components/details/DetailsSection"
import { useGetDetails } from "hooks/useGetDetails"
import { UploadedFile } from "api/common/models"
import FileIcon from "@material-ui/icons/DescriptionOutlined"
import EditIcon from "@material-ui/icons/Edit"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import DataTable, { HeadRow, TableData } from "components/dataTable/DataTable"
import Authorize from "components/Authorize"

const formatAddress = (address: Address) => {
  const number = address.apartmentNumber
    ? `${address.houseNumber}/${address.apartmentNumber}`
    : address.houseNumber
  return (
    <span>
      {address.street} {number}
      <br />
      {address.postalCode} {address.city}
      <br />
      {getTranslation("general", address.country)}
    </span>
  )
}

export interface Data extends TableData {
  type: string
  supplier: string
  quantity: string
  unit: string
  runningMeters: string
  price: string
  withInstallation: string
}

const OfferDetails: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation([
    "offers",
    "api",
    "general",
    "offers.status",
    "offers.constructionType",
    "acquisitionMethods",
    "offers.deliveryPlaceType",
    "unitsOfMeasurement",
  ])
  const errorMessage = t("Error getting data", { ns: "api" })
  const { pathname } = useLocation()

  const offer = useGetDetails<Offer>(offersApi.getOffer, errorMessage)

  const headRows: HeadRow<Data>[] = useMemo(
    () => [
      { id: "id", numeric: false, disablePadding: false, label: t("ID") },
      { id: "type", numeric: false, disablePadding: false, label: t("Type") },
      { id: "supplier", numeric: false, disablePadding: false, label: t("Supplier") },
      { id: "quantity", numeric: false, disablePadding: false, label: t("Quantity") },
      { id: "unit", numeric: false, disablePadding: false, label: t("Unit") },
      { id: "runningMeters", numeric: false, disablePadding: false, label: t("Running meters") },
      { id: "price", numeric: false, disablePadding: false, label: t("Price") },
      { id: "withInstallation", numeric: false, disablePadding: false, label: t("Installation") },
    ],
    [t]
  )

  const getRows: (data: OfferProduct[] | undefined) => Data[] | undefined = (data) =>
    data &&
    data.map((row) => ({
      id: row.id,
      type: row.product.name,
      supplier: row.supplier.name,
      quantity: row.quantity?.toString(10) ?? "0",
      unit: t(`${row.product.unit}`, { ns: "unitsOfMeasurement" }),
      runningMeters: row.runningMeters?.toString(10) ?? "0",
      price: `${row.price?.toString(10) ?? "0"} €`,
      withInstallation: row.withInstallation ? t("Yes", { ns: "general" }) : t("No", { ns: "general" }),
    }))

  return (
    <Fragment>
      {offer && (
        <div className={classes.mainContainer}>
          <Paper className={classes.paper}>
            <Typography variant="h4" component="div" className={classes.name}>
              <div className={classes.offerHeading}>
                <span>
                  {`${t("Offer")} Id: ${offer.id}`}
                  <Authorize permissions={["OFFERS_WRITE"]}>
                    <Tooltip title={t("Edit offer") || ""}>
                      <Link to={`/sales/offers/${offer.id}/edit?referrer=${encodeURIComponent(pathname)}`}>
                        <IconButton
                          className={classes.editButton}
                          aria-label={t("Edit offer")}
                          component="span"
                        >
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  </Authorize>
                </span>
                <div>
                  <Typography variant="body1" component="span">
                    {t(`${offer.status}`, { ns: "offers.status" })}
                  </Typography>
                  <StatusCircle
                    status={t(`${offer.status}`, { ns: "offers.status" })}
                    color={colorByOfferStatus[offer.status]}
                    className={classes.statusCircle}
                  />
                </div>
              </div>
            </Typography>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <DetailField label={t("Dealer")} value={offer.dealer.name} />
              <div className={classes.badge}>
                <Typography variant="body2" component="span" className={classes.label}>
                  {t("Priority")}:
                </Typography>
                <Badge label={offer.priority.toString(10)} />
              </div>
            </Grid>
            <Grid container style={{ paddingTop: 20 }}>
              <NavLink to={`/sales/counterparties/${offer.client.id}/details`}>
                <AccountCircle className={classes.customer} />
              </NavLink>
              <div className={classes.customerData}>
                <Typography variant="body1" component="span">
                  {offer.client.name}
                  <br />
                  {formatAddress(offer.client.address)}
                </Typography>
              </div>
            </Grid>
          </Paper>
          <Paper className={classes.paper}>
            <DetailsSection title={t("Details")}>
              <DetailField
                label={t("Construction type")}
                value={t(`${offer.construction.type}`, { ns: "offers.constructionType" })}
              />
              <DetailField label={t("Construction address")} value={offer.construction.address} />
              <DetailField label={t("Offer number")} value={offer.offerNumber} />
              <DetailField
                label={t("Creation date")}
                value={moment(offer.creationDate).format(t("DateFormat", { ns: "general" }))}
              />
              <DetailField
                label={t("Client wish date")}
                value={
                  offer.clientWishDate
                    ? moment(offer.clientWishDate).format(t("DateFormat", { ns: "general" }))
                    : ""
                }
              />
              <DetailField
                label={t("Acquisition method")}
                value={t(`${offer.acquisitionMethod}`, { ns: "acquisitionMethods" })}
              />
              <DetailField
                label={t("Delivery place")}
                value={
                  offer.deliveryPlace.type === DeliveryPlaceType.OTHER
                    ? offer.deliveryPlace.other || ""
                    : t(`${offer.deliveryPlace.type}`, { ns: "offers.deliveryPlaceType" })
                }
              />
              <DetailField
                label={t("Offer with measurement")}
                value={offer.withMeasurement ? t("Yes", { ns: "general" }) : t("No", { ns: "general" })}
              />
            </DetailsSection>
          </Paper>
          {offer.withMeasurement && offer.measurement && (
            <Paper className={classes.paper}>
              <DetailsSection title={t("Measurement")}>
                <DateCommentsField
                  label={t("Measurement until")}
                  date={offer.measurement.measurementUntil && offer.measurement.measurementUntil.date}
                  comments={offer.measurement.measurementUntil && offer.measurement.measurementUntil.comments}
                />
                <DateCommentsField
                  label={t("Measurement date")}
                  date={offer.measurement.measurementDate && offer.measurement.measurementDate.date}
                  comments={offer.measurement.measurementDate && offer.measurement.measurementDate.comments}
                />
                <DateCommentsField
                  label={t("Order in progress")}
                  date={offer.measurement.orderInProgress && offer.measurement.orderInProgress.date}
                  comments={offer.measurement.orderInProgress && offer.measurement.orderInProgress.comments}
                />
                <DateCommentsField
                  label={t("Sent to customer")}
                  date={offer.measurement.sentToCustomer && offer.measurement.sentToCustomer.date}
                  comments={offer.measurement.sentToCustomer && offer.measurement.sentToCustomer.comments}
                />
                <DateCommentsField
                  label={t("Customer confirmed")}
                  date={offer.measurement.customerConfirmed && offer.measurement.customerConfirmed.date}
                  comments={
                    offer.measurement.customerConfirmed && offer.measurement.customerConfirmed.comments
                  }
                />
                <DateCommentsField
                  label={t("Order checked")}
                  date={offer.measurement.orderChecked && offer.measurement.orderChecked.date}
                  comments={offer.measurement.orderChecked && offer.measurement.orderChecked.comments}
                />
                <DetailField
                  label={t("Offer with advance payment")}
                  value={
                    offer.measurement.advancePayment && offer.measurement.advancePayment.withAdvancePayment
                      ? t("Yes", { ns: "general" })
                      : t("No", { ns: "general" })
                  }
                />
                {offer.measurement.advancePayment && offer.measurement.advancePayment.withAdvancePayment && (
                  <DateCommentsField
                    label={t("Advance payment date")}
                    date={offer.measurement.advancePayment.date}
                    comments={offer.measurement.advancePayment.comments}
                  />
                )}
                <DetailField
                  label={t("Payment completed")}
                  value={
                    offer.measurement.payment && offer.measurement.payment.paymentCompleted
                      ? t("Yes", { ns: "general" })
                      : t("No", { ns: "general" })
                  }
                />
                <DateCommentsField
                  label={t("Payment date")}
                  date={offer.measurement.payment && offer.measurement.payment.date}
                />
              </DetailsSection>
            </Paper>
          )}
          <Paper className={classes.paper}>
            <DetailsSection title={t("Offer amounts")}>
              <div className={classes.amountsContainer}>
                <div className={classes.badge}>
                  <Typography variant="body2" component="span" className={classes.label}>
                    {t("Offer amount")}:
                  </Typography>
                  <Badge label={offer.amounts.offerAmount} backgroundColor="#6CB52E" unit="euro" />
                </div>
                <div className={classes.badge}>
                  <Typography variant="body2" component="span" className={classes.label}>
                    {t("Costs")}:
                  </Typography>
                  <Badge
                    label={getCosts(offer.offerProducts, offer.calculation?.amount)}
                    backgroundColor="orangered"
                    unit="euro"
                  />
                </div>
                <div className={classes.badge}>
                  <Typography variant="body2" component="span" className={classes.label}>
                    {t("Profit")}:
                  </Typography>
                  <Badge
                    label={getProfit(
                      offer.amounts.offerAmount,
                      getCosts(offer.offerProducts, offer.calculation?.amount)
                    )}
                    backgroundColor="skyblue"
                    unit="euro"
                  />
                </div>
                <div className={classes.badge}>
                  <Typography variant="body2" component="span" className={classes.label}>
                    {t("Profit percentage")}:
                  </Typography>
                  <Badge
                    label={getProfitPercentage(
                      offer.amounts.offerAmount,
                      getCosts(offer.offerProducts, offer.calculation?.amount)
                    )}
                    backgroundColor="lightblue"
                  />
                </div>
              </div>
            </DetailsSection>
          </Paper>
          <Paper className={classes.paper}>
            <DetailsSection title={t("Installation")}>
              <DetailField
                label={t("Installation days")}
                value={
                  offer.installation && offer.installation.days ? offer.installation.days.toString(10) : ""
                }
              />
              <DetailField
                label={t("Assemblers count")}
                value={
                  offer.installation && offer.installation.assemblersCount
                    ? offer.installation.assemblersCount.toString(10)
                    : ""
                }
              />
            </DetailsSection>
          </Paper>
          {offer.offerProducts && offer.offerProducts.length > 0 && (
            <Paper className={classes.paper}>
              <DetailsSection title={t("Products")}>
                <DataTable
                  tableClassName={classes.table}
                  rows={getRows(offer.offerProducts)}
                  headRows={headRows}
                />
              </DetailsSection>
            </Paper>
          )}
          {offer.calculation && (
            <Paper className={classes.paper}>
              <DetailsSection title={t("Mounting materials calculation")}>
                <div className={classes.badge}>
                  <Typography variant="body2" component="span" className={classes.label}>
                    {t("Calculation value")}:
                  </Typography>
                  <Badge label={offer.calculation.amount} backgroundColor="gold" unit="euro" />
                </div>
              </DetailsSection>
            </Paper>
          )}
          <Paper className={classes.paper}>
            <DetailsSection title={t("Comments")}>
              <FormControl fullWidth>
                <OutlinedInput
                  multiline
                  value={offer.comments || ""}
                  maxRows="10"
                  readOnly
                  className={classes.commentsInput}
                />
              </FormControl>
            </DetailsSection>
          </Paper>
          <Paper className={classes.paper}>
            <FilesFields title={t("Internal files")} files={offer.internalFiles} />
          </Paper>
          <Paper className={classes.paper}>
            <FilesFields title={t("Client files")} files={offer.customerFiles} />
          </Paper>
        </div>
      )}
    </Fragment>
  )
}

interface DateCommentsFieldProps {
  label: string
  date?: string | null
  comments?: string | null
}

const DateCommentsField: React.FC<DateCommentsFieldProps> = ({ label, date, comments }) => {
  const { t } = useTranslation(["offers", "general"])
  const classes = useStyles()
  return (
    <React.Fragment>
      <DetailField
        label={label}
        value={date ? moment(date).format(t("DateFormat", { ns: "general" })) : ""}
      />
      {comments && (
        <TextField
          className={classes.comments}
          label={t("Comments")}
          multiline
          maxRows="4"
          value={comments}
          variant="outlined"
          InputProps={{
            readOnly: true,
            classes: {
              input: classes.commentsInput,
            },
          }}
          size="small"
        />
      )}
    </React.Fragment>
  )
}

const FilesFields: React.FC<{
  files?: UploadedFile[] | null
  title: string
}> = ({ files, title }) => {
  const classes = useStyles()
  return (
    <DetailsSection title={title}>
      {files &&
        files.map((file, index) => (
          <Grid key={index} container alignItems="center">
            <a className={classes.fileLink} href={file.url}>
              <FileIcon className={classes.fileIcon} />
              <Typography variant="body1">{file.name}</Typography>
            </a>
          </Grid>
        ))}
    </DetailsSection>
  )
}

export default OfferDetails
