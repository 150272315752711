import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      padding: `0 ${theme.spacing(3)}px`,
      display: "flex",
    },
    spacer: {
      flex: "1 1 100%",
    },
    actions: {
      color: theme.palette.text.secondary,
    },
    title: {
      flex: "0 0 auto",
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    main: {
      minWidth: "100%",
      marginTop: theme.spacing(2),
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: "1000px",
    },
    actionCell: {
      width: "150px"
    },
    row: {
      cursor: "pointer"
    },
    rowBgEven: {
      background: theme.palette.grey[300]
    },
    rowBgOdd: {
      background: theme.palette.grey[50]
    },
    headerRow: {
      fontWeight: "bold"
    },
    loader: {
      display: "flex",
      justifyContent: "center",
      padding: theme.spacing(2),
    },
    addButton: {
      minWidth: theme.spacing(5),
      minHeight: theme.spacing(5)
    },
    searchBar: {
      padding: `0 ${theme.spacing(4)}px`,
      width: "100%"
    }
  }),
)

export default useStyles
