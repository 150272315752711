import * as Yup from "yup"
import { Offer, OfferAmounts, Installation, OfferProduct } from "api/offers/models"
import { getTranslation } from "i18n"
import { ValidationObjectShape } from "validation"

const validationSchema = Yup.object<ValidationObjectShape<Offer>>({
  priority: Yup.number()
    .typeError(() => getTranslation("validation", "NaN"))
    .required(() => getTranslation("validation", "Field is required")),
  creationDate: Yup.string()
    .typeError(() => getTranslation("validation", "Invalid date"))
    .required(() => getTranslation("validation", "Field is required")),
  amounts: Yup.object<ValidationObjectShape<OfferAmounts>>({
    offerAmount: Yup.number().typeError(() => getTranslation("validation", "NaN")),
  }),
  installation: Yup.object<ValidationObjectShape<Installation>>({
    days: Yup.number()
      .typeError(() => getTranslation("validation", "NaN"))
      .nullable(),
    assemblersCount: Yup.number()
      .typeError(() => getTranslation("validation", "NaN"))
      .nullable(),
  }),
  client: Yup.object()
    .required(() => getTranslation("validation", "Field is required"))
    .nullable(),
  dealer: Yup.object()
    .required(() => getTranslation("validation", "Field is required"))
    .nullable(),
  offerProducts: Yup.array().of(
    Yup.object<ValidationObjectShape<OfferProduct>>({
      product: Yup.object()
        .required(() => getTranslation("validation", "Field is required"))
        .nullable(),
      supplier: Yup.object()
        .required(() => getTranslation("validation", "Field is required"))
        .nullable(),
      quantity: Yup.number()
        .typeError(() => getTranslation("validation", "NaN"))
        .nullable(),
      runningMeters: Yup.number()
        .typeError(() => getTranslation("validation", "NaN"))
        .nullable(),
      price: Yup.number()
        .typeError(() => getTranslation("validation", "NaN"))
        .nullable(),
    })
  ),
})

export default validationSchema
