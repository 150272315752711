import React, { useState, MouseEvent, ChangeEvent, useCallback, Fragment } from "react"
import useStyles from "./DataTable.styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import Toolbar from "@material-ui/core/Toolbar"
import Paper from "@material-ui/core/Paper"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import DeleteIcon from "@material-ui/icons/Delete"
import FilterListIcon from "@material-ui/icons/FilterList"
import EditIcon from "@material-ui/icons/Edit"
import AddIcon from "@material-ui/icons/Add"
import Fab from "@material-ui/core/Fab"
import InfoIcon from "@material-ui/icons/Info"
import ConfirmationDialog from "components/confirmationDialog/ConfirmationDialog"
import { useTranslation } from "react-i18next"
import SearchBar from "components/SearchBar"
import { ListPage } from "api/common/models"
import classNames from "classnames"
import CircularProgress from "@material-ui/core/CircularProgress"
import identity from "lodash/identity"

export interface HeadRow<T> {
  disablePadding: boolean
  id: keyof T
  label: string
  numeric: boolean
}

export interface TableData {
  id: string
}

const DataTableHead = <T,>(props: { headRows: HeadRow<T>[]; hasActionCell?: boolean }) => {
  const { headRows, hasActionCell = true } = props
  const classes = useStyles()
  const { t } = useTranslation("list")

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) => (
          <TableCell
            key={row.id as string}
            align={row.numeric ? "right" : "left"}
            padding={row.disablePadding ? "none" : "normal"}
            className={classes.headerRow}
          >
            {row.label}
          </TableCell>
        ))}
        {hasActionCell && (
          <TableCell key="actions" className={classes.headerRow}>
            {t("Actions")}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  )
}

interface DataTableToolbarProps {
  addAction?: () => void
  onFiltersToggle?: () => void
  searchBar?: JSX.Element
}

const DataTableToolbar: React.FC<DataTableToolbarProps> = ({ addAction, onFiltersToggle, searchBar }) => {
  const classes = useStyles()
  const { t } = useTranslation(["list", "general"])
  return (
    <Fragment>
      {(addAction || onFiltersToggle || searchBar) && (
        <Toolbar className={classes.toolbar}>
          {addAction && (
            <Tooltip title={t("Add") || ""}>
              <Fab
                color="primary"
                size="small"
                className={classes.addButton}
                aria-label={t("Add")}
                onClick={addAction}
              >
                <AddIcon />
              </Fab>
            </Tooltip>
          )}
          <div className={classes.searchBar}>{searchBar}</div>
          {onFiltersToggle && (
            <div className={classes.actions}>
              <Tooltip title={t("general:Filters") || ""}>
                <IconButton aria-label="filters" onClick={onFiltersToggle}>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </Toolbar>
      )}
    </Fragment>
  )
}

export type ColumnRenderer<T> = (columnId: keyof T, row: T) => JSX.Element

interface DataTableInterface<T> {
  rows?: T[]
  headRows: HeadRow<T>[]
  addRowAction?: () => void
  rowDetailsAction?: (rowId: string) => void
  editRowAction?: (rowId: string) => void
  deleteRowAction?: (rowId: string) => void
  rowClickAction?: (rowId: string) => void
  pageData?: ListPage
  setPageNumber?: (page: number) => void
  setPageLimit?: (limit: number) => void
  onFiltersToggle?: () => void
  customColumnRenderers?: { [key in keyof T]?: ColumnRenderer<T> }
  onSearch?: (searchPhrase: string) => void
  className?: string
  tableClassName?: string
}

const DataTable = <T extends TableData>(props: DataTableInterface<T>): JSX.Element => {
  const {
    rows,
    headRows,
    pageData,
    setPageNumber,
    setPageLimit,
    addRowAction,
    editRowAction,
    rowDetailsAction,
    deleteRowAction,
    onFiltersToggle,
    customColumnRenderers,
    onSearch,
    className,
    rowClickAction,
    tableClassName,
  } = props
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [rowToDelete, setRowtoDelete] = useState<string | undefined>(undefined)
  const { t } = useTranslation(["list", "searchBar", "general"])

  const handleOpen = (id: string) => {
    setOpen(true)
    setRowtoDelete(id)
  }

  const handleChangePage = (event: MouseEvent | null, newPage: number) => {
    if (setPageNumber) {
      setPageNumber(newPage)
    }
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    if (setPageLimit) {
      setPageLimit(+event.target.value)
    }
  }

  const handleConfirm = () => {
    if (rowToDelete !== undefined && deleteRowAction) {
      deleteRowAction(rowToDelete)
      setOpen(false)
    }
  }

  const standardColumnRenderer: ColumnRenderer<T> = useCallback(
    (columnId, row) => <TableCell key={columnId.toString()}>{row[columnId]}</TableCell>,
    []
  )

  const onRowClick = (rowId: string) => {
    const action = rowClickAction || rowDetailsAction || identity
    action(rowId)
  }

  const hasActionCell =
    rowDetailsAction !== undefined || editRowAction !== undefined || deleteRowAction !== undefined

  return (
    <main className={classNames(classes.content, className)}>
      <div className={classes.main}>
        {rows ? (
          <Paper className={classes.paper}>
            <DataTableToolbar
              addAction={addRowAction}
              onFiltersToggle={onFiltersToggle}
              searchBar={onSearch && <SearchBar onSearch={onSearch} placeholder={t("searchBar:Search")} />}
            />
            <div>
              <Table
                className={classNames(classes.table, tableClassName)}
                aria-labelledby="tableTitle"
                size="small"
              >
                <DataTableHead headRows={headRows} hasActionCell={hasActionCell} />
                {rows.length > 0 ? (
                  <TableBody>
                    {rows.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row.id}
                          className={`${classes.row} ${index % 2 ? classes.rowBgEven : classes.rowBgOdd}`}
                          onClick={() => onRowClick(row.id)}
                        >
                          {headRows.map((el) => {
                            const renderer =
                              customColumnRenderers && customColumnRenderers[el.id]
                                ? (customColumnRenderers[el.id] as ColumnRenderer<T>)
                                : standardColumnRenderer
                            return renderer(el.id, row)
                          })}
                          {hasActionCell && (
                            <TableCell className={classes.actionCell} onClick={(e) => e.stopPropagation()}>
                              {rowDetailsAction && (
                                <Tooltip title={t("Details") || ""}>
                                  <IconButton
                                    edge="start"
                                    aria-label={t("Details")}
                                    onClick={() => rowDetailsAction(row.id)}
                                  >
                                    <InfoIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                              {editRowAction && (
                                <Tooltip title={t("Edit") || ""}>
                                  <IconButton
                                    edge="start"
                                    aria-label={t("Edit")}
                                    onClick={() => editRowAction(row.id)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                              {deleteRowAction && (
                                <Tooltip title={t("Delete") || ""}>
                                  <IconButton
                                    edge="start"
                                    aria-label={t("Delete")}
                                    onClick={() => handleOpen(row.id)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </TableCell>
                          )}
                        </TableRow>
                      )
                    })}
                  </TableBody>
                ) : (
                  <caption style={{ textAlign: "center" }}>{t("No data to display")}</caption>
                )}
              </Table>
            </div>
            {pageData && (
              <TablePagination
                rowsPerPageOptions={[10, 50, 100]}
                component="div"
                count={pageData.totalElements}
                rowsPerPage={pageData.size}
                page={pageData.number}
                labelRowsPerPage={t("Rows per page")}
                backIconButtonProps={{
                  "aria-label": "Previous Page",
                }}
                nextIconButtonProps={{
                  "aria-label": "Next Page",
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
            <ConfirmationDialog open={open} setOpen={setOpen} onConfirm={handleConfirm} />
          </Paper>
        ) : (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        )}
      </div>
    </main>
  )
}

export default DataTable
