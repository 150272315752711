import React from "react"
import { HeadRow, TableData } from "components/dataTable/DataTable"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { Offer } from "api/offers/models"
import offerExecutionApi from "api/offerExecution/offerExecutionApi"
import OfferExecutionTab from "./OfferExecutionTab"

export interface Data extends TableData {
  orderVersion: string
  orderVersionComments: string
  sentToCustomer: string
  sentToCustomerComments: string
  client: string
  constructionAddress: string
  installationsDays: string
  assemblersCount: string
  clientWishDate: string
  constructionType: string
}

const SentToCustomer: React.FC = () => {
  const { t } = useTranslation(["offers", "general", "offers.constructionType"])

  const headRows: HeadRow<Data>[] = [
    { id: "id", numeric: false, disablePadding: false, label: t("ID") },
    { id: "orderVersion", numeric: false, disablePadding: false, label: t("Order in progress") },
    { id: "orderVersionComments", numeric: false, disablePadding: false, label: `${t("Comments")}` },
    { id: "sentToCustomer", numeric: false, disablePadding: false, label: t("Sent to customer") },
    { id: "sentToCustomerComments", numeric: false, disablePadding: false, label: `${t("Comments")}` },
    { id: "client", numeric: false, disablePadding: false, label: t("Client") },
    {
      id: "constructionAddress",
      numeric: false,
      disablePadding: false,
      label: t("Construction address"),
    },
    { id: "installationsDays", numeric: false, disablePadding: false, label: t("Installation days") },
    { id: "assemblersCount", numeric: false, disablePadding: false, label: t("Assemblers count") },
    { id: "clientWishDate", numeric: false, disablePadding: false, label: t("Client wish date") },
    { id: "constructionType", numeric: false, disablePadding: false, label: t("Construction type") },
  ]

  const mapRows: (data: Offer[]) => Data[] = (data) =>
    data.map((r) => ({
      id: r.id,
      orderVersion: moment(r.measurement?.orderInProgress?.date ?? "").format(
        t("DateFormat", { ns: "general" })
      ),
      orderVersionComments: r.measurement?.orderInProgress?.comments ?? "",
      sentToCustomer: moment(r.measurement?.sentToCustomer?.date ?? "").format(
        t("DateFormat", { ns: "general" })
      ),
      sentToCustomerComments: r.measurement?.sentToCustomer?.comments ?? "",
      client: `${r.client.name}, ${r.client.address.city}, ${t(`${r.client.address.country}`, {
        ns: "general",
      })}`,
      constructionAddress: r.construction.address ?? "",
      installationsDays: r.installation?.days?.toString() ?? "",
      assemblersCount: r.installation?.assemblersCount?.toString() ?? "",
      clientWishDate: moment(r.creationDate).format(t("DateFormat", { ns: "general" })),
      constructionType: t(`${r.construction.type}`, { ns: "offers.constructionType" }),
    }))

  return (
    <OfferExecutionTab headRows={headRows} mapRows={mapRows} getData={offerExecutionApi.getSentToCustomer} />
  )
}

export default SentToCustomer
