import Axios from "axios"
import pickBy from "lodash/pickBy"
import identity from "lodash/identity"
import {
  MaterialType,
  DeleteMaterialTypeResponse,
  GetMaterialTypeResponse,
  EditMaterialTypeRequest,
  EditMaterialTypeResponse,
  CreateMaterialTypeRequest,
  CreateMaterialTypeResponse,
} from "./models"
import { ListModel, EditSubmit, GetListResponse } from "api/common/models"

interface MaterialTypesApi {
  getMaterialTypesList: (
    page: number,
    limit: number,
    filters: { [key: string]: string },
    searchPhrase: string
  ) => Promise<ListModel<MaterialType>>
  createMaterialType: (data: CreateMaterialTypeRequest) => Promise<CreateMaterialTypeResponse>
  deleteMaterialType: (id: string) => Promise<DeleteMaterialTypeResponse>
  getMaterialType: (id: number) => Promise<GetMaterialTypeResponse>
  editMaterialType: EditSubmit<EditMaterialTypeRequest, EditMaterialTypeResponse>
  getAllMaterialTypes: () => Promise<MaterialType[]>
}

const materialTypesApi: MaterialTypesApi = {
  getMaterialTypesList: async (page, limit, filters, searchPhrase) => {
    const params = pickBy(
      { page: page.toString(10), limit: limit.toString(10), ...filters, searchPhrase },
      identity
    )
    const urlParams = new URLSearchParams(params)
    const { data } = await Axios.get<GetListResponse<MaterialType>>(
      `/api/v1/mounting-material-types?${urlParams.toString()}`
    )
    return { data: data.result, page: data.page }
  },
  createMaterialType: (data) =>
    Axios.post<CreateMaterialTypeResponse>("/api/v1/mounting-material-types", data).then(
      (response) => response.data
    ),
  deleteMaterialType: (id) =>
    Axios.delete<DeleteMaterialTypeResponse>(`/api/v1/mounting-material-types/${id}`).then(
      (response) => response.data
    ),
  getMaterialType: (id) =>
    Axios.get<GetMaterialTypeResponse>(`/api/v1/mounting-material-types/${id}`).then(
      (response) => response.data
    ),
  editMaterialType: (id, data) =>
    Axios.put<EditMaterialTypeResponse>(`/api/v1/mounting-material-types/${id}`, data).then(
      (response) => response.data
    ),
  getAllMaterialTypes: async () => {
    const urlParams = new URLSearchParams({
      limit: "9999",
    })
    const {
      data: { result },
    } = await Axios.get<{ result: MaterialType[] }>(`/api/v1/mounting-material-types?${urlParams.toString()}`)
    return result
  },
}

export default materialTypesApi
