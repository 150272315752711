import Axios, { AxiosResponse } from "axios"
import { UploadedImage } from "api/common/models"

interface ImagesApi {
  addImage: (formData: FormData) => Promise<UploadedImage>
}

const imagesApi: ImagesApi = {
  addImage: formData => Axios.post<FormData, AxiosResponse<UploadedImage>>(`/api/v1/images`, formData).then(res => res.data),
}

export default imagesApi
