import { EditSubmit } from "api/common/models"
import Axios from "axios"
import {
  CreateMountingMaterialCalculationRequest,
  EditMountingMaterialCalculationRequest,
  MountingMaterialCalculationResponse,
} from "./models"

interface MaterialTypesApi {
  createCalculation: (
    data: CreateMountingMaterialCalculationRequest
  ) => Promise<MountingMaterialCalculationResponse>
  editCalculation: EditSubmit<EditMountingMaterialCalculationRequest, MountingMaterialCalculationResponse>
  getCalculation: (calculationId: number) => Promise<MountingMaterialCalculationResponse>
}

const materialTypesApi: MaterialTypesApi = {
  createCalculation: (data) =>
    Axios.post<MountingMaterialCalculationResponse>("/api/v1/offers/calculations", data).then(
      (response) => response.data
    ),
  getCalculation: (id) =>
    Axios.get<MountingMaterialCalculationResponse>(`/api/v1/offers/calculations/${id}`).then(
      (response) => response.data
    ),
  editCalculation: (id, data) =>
    Axios.put<MountingMaterialCalculationResponse>(`/api/v1/offers/calculations/${id}`, data).then(
      (response) => response.data
    ),
}

export default materialTypesApi
