import React from "react"
import { makeStyles, withStyles, Theme, createStyles } from "@material-ui/core/styles"
import MuiTabs from "@material-ui/core/Tabs"
import MuiTab from "@material-ui/core/Tab"
import Paper from "@material-ui/core/Paper"
import { useParams, useHistory, Switch, Route } from "react-router-dom"
import { useTranslation } from "react-i18next"
import MeasurementUntil from "./MeasurementUntil"
import MeasurementDate from "./MeasurementDate"
import OrderVersion from "./OrderVersion"
import SentToCustomer from "./SentToCustomer"
import CustomerConfirmed from "./CustomerConfirmed"
import Payment from "./Payment"

const Tabs = withStyles((theme: Theme) => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))(MuiTabs)

const Tab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(3),
      opacity: 1,
      "&:hover": {
        color: theme.palette.primary.main,
      },
      "&$selected": {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    selected: {},
  })
)((props: StyledTabProps) => <MuiTab disableRipple {...props} />)

interface StyledTabProps {
  label: string
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(3),
  },
  padding: {
    padding: theme.spacing(3),
  },
}))

const routes = [
  "measurementUntil",
  "measurementDate",
  "orderVersion",
  "sentToCustomer",
  "customerConfirmed",
  "payment",
]

const OfferExecution: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation("offerExecution")
  const { tab = "measurementUntil" } = useParams()
  const history = useHistory()

  const handleChange = (_: unknown, newValue: number) => {
    history.push(routes[newValue])
  }

  const tabIndex = routes.indexOf(tab)

  return (
    <Paper className={classes.root}>
      <Tabs value={tabIndex > -1 ? tabIndex : 0} onChange={handleChange}>
        <Tab label={t("Measurement until")} />
        <Tab label={t("Measurement date")} />
        <Tab label={t("Order version")} />
        <Tab label={t("Sent to customer")} />
        <Tab label={t("Customer confirmed")} />
        <Tab label={t("Payment")} />
      </Tabs>
      <Switch>
        <Route path={`/sales/offerExecution/${routes[0]}`}>
          <MeasurementUntil />
        </Route>
        <Route path={`/sales/offerExecution/${routes[1]}`}>
          <MeasurementDate />
        </Route>
        <Route path={`/sales/offerExecution/${routes[2]}`}>
          <OrderVersion />
        </Route>
        <Route path={`/sales/offerExecution/${routes[3]}`}>
          <SentToCustomer />
        </Route>
        <Route path={`/sales/offerExecution/${routes[4]}`}>
          <CustomerConfirmed />
        </Route>
        <Route path={`/sales/offerExecution/${routes[5]}`}>
          <Payment />
        </Route>
      </Switch>
    </Paper>
  )
}

export default OfferExecution
