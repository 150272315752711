import React from "react"
import { FilterValue } from "./model"
import { Chip } from "@material-ui/core"
import useStyles from "./FilterChip.styles"

interface FilterChipProps {
  filterLabel: string
  selectedFilters: FilterValue<unknown>[]
  onRemove?: () => void
  valueTranslator?: (label: string) => string
}

const FilterChip: React.FC<FilterChipProps> = ({
  selectedFilters,
  filterLabel,
  onRemove,
  valueTranslator = (label) => label,
}) => {
  const classes = useStyles()
  return (
    <Chip
      label={
        <div className={classes.chipLabel}>{`${filterLabel}: ${selectedFilters
          .map((sf) => valueTranslator(sf.label))
          .join(", ")}`}</div>
      }
      onDelete={onRemove}
      variant="outlined"
      className={classes.chip}
    />
  )
}

export default FilterChip
