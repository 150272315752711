import { makeStyles, createStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme =>
  createStyles({
    fileLink: {
      display: "flex",
      textDecoration: "none",
      color: theme.palette.text.primary,
      padding: theme.spacing(1),
      "&:hover": {
        textDecoration: "underline",
      }
    },
    fileIcon: {
      paddingRight: theme.spacing(0.25),
    },
    fileTitleContainer: {
      display: "flex",
    },
    deleteButton: {
      marginBottom: theme.spacing(0.25),
    },
    fileInput: {
      display: "none"
    },
    addButton: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(-0.5),
    },
  }))

export default useStyles
