import Axios from "axios"
import pickBy from "lodash/pickBy"
import identity from "lodash/identity"
import {
  Product,
  DeleteProductResponse,
  GetProductResponse,
  EditProductRequest,
  EditProductResponse,
  CreateProductRequest,
  CreateProductResponse
} from "./models"
import { ListModel, EditSubmit, GetListResponse } from "api/common/models"

interface ProductsApi {
  getProductsList: (page: number, limit: number, filters: { [key: string]: string }, searchPhrase: string) => Promise<ListModel<Product>>
  createProduct: (data: CreateProductRequest) => Promise<CreateProductResponse>
  deleteProduct: (id: string) => Promise<DeleteProductResponse>
  getProduct: (id: number) => Promise<GetProductResponse>
  editProduct: EditSubmit<EditProductRequest, EditProductResponse>
  getAllProducts: () => Promise<Product[]>
}

const productsApi: ProductsApi = {
  getProductsList: async (page, limit, filters, searchPhrase) => {
    const params = pickBy({ page: page.toString(10), limit: limit.toString(10), ...filters, searchPhrase }, identity)
    const urlParams = new URLSearchParams(params)
    const { data } = await Axios.get<GetListResponse<Product>>(`/api/v1/products?${urlParams.toString()}`)
    return ({ data: data.result, page: data.page })
  },
  createProduct: (data) => Axios.post<CreateProductResponse>("/api/v1/products", data).then(response => response.data),
  deleteProduct: id => Axios.delete<DeleteProductResponse>(`/api/v1/products/${id}`).then(response => response.data),
  getProduct: id => Axios.get<GetProductResponse>(`/api/v1/products/${id}`).then(response => response.data),
  editProduct: (id, data) => Axios.put<EditProductResponse>(`/api/v1/products/${id}`, data).then(response => response.data),
  getAllProducts: async () => {
    const urlParams = new URLSearchParams({
      limit: "9999",
    })
    const { data: { result } } = await Axios.get<{ result: Product[] }>(`/api/v1/products?${urlParams.toString()}`)
    return result
  },
}

export default productsApi
