import React, { Fragment } from "react"
import DataTable, { HeadRow, TableData } from "components/dataTable/DataTable"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router-dom"
import PriorityExpansionPanel from "./PriorityExpansionPanel"
import { Offer } from "api/offers/models"
import { useGetAll } from "hooks/useGetAll"
import { makeStyles, createStyles, Theme } from "@material-ui/core"
import { GetOfferExecutionTabResponse } from "api/offerExecution/models"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: "100%",
    },
  })
)

interface OfferExecutionProps<T> {
  headRows: HeadRow<T>[]
  mapRows: (data: Offer[]) => T[]
  getData: () => Promise<GetOfferExecutionTabResponse>
}

const OfferExecutionTab = <T extends TableData>(props: OfferExecutionProps<T>): JSX.Element => {
  const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation("api")
  const { pathname } = useLocation()

  const { headRows, mapRows, getData } = props

  const rowDetailsAction = (rowId: string) => history.push(`/sales/offers/${rowId}/details`)
  const editRowAction = (rowId: string) =>
    history.push(`/sales/offers/${rowId}/edit/?referrer=${encodeURIComponent(pathname)}`)

  const data = useGetAll(getData, t("Error getting data"))

  return (
    <Fragment>
      <PriorityExpansionPanel
        urgent={
          <DataTable<T>
            className={classes.table}
            rows={data && mapRows(data.urgent)}
            headRows={headRows}
            rowDetailsAction={rowDetailsAction}
            editRowAction={editRowAction}
          />
        }
        important={
          <DataTable<T>
            className={classes.table}
            rows={data && mapRows(data.important)}
            headRows={headRows}
            rowDetailsAction={rowDetailsAction}
            editRowAction={editRowAction}
          />
        }
        normal={
          <DataTable<T>
            className={classes.table}
            rows={data && mapRows(data.normal)}
            headRows={headRows}
            rowDetailsAction={rowDetailsAction}
            editRowAction={editRowAction}
          />
        }
      />
    </Fragment>
  )
}

export default OfferExecutionTab
