import * as Yup from "yup"
import { getTranslation } from "i18n"
import { MaterialType } from "api/materialTypes/models"
import { ValidationObjectShape } from "validation"

const validationSchema = Yup.object<ValidationObjectShape<MaterialType>>({
  name: Yup.string()
    .required(() => getTranslation("validation", "Field is required"))
    .nullable(),
})

export default validationSchema
