import Axios from "axios"
import { GetOfferExecutionTabResponse, GetOfferExecutionPaymentResponse } from "./models"

interface OfferExecutionApi {
  getMeasurementUntil: () => Promise<GetOfferExecutionTabResponse>
  getMeasurementDate: () => Promise<GetOfferExecutionTabResponse>
  getOrderVersion: () => Promise<GetOfferExecutionTabResponse>
  getSentToCustomer: () => Promise<GetOfferExecutionTabResponse>
  getCustomerConfirmed: () => Promise<GetOfferExecutionTabResponse>
  getPayment: () => Promise<GetOfferExecutionPaymentResponse>
}

const offersApi: OfferExecutionApi = {
  getMeasurementUntil: () =>
    Axios.get<GetOfferExecutionTabResponse>("/api/v1/offer-execution/measurement-until").then(
      (response) => response.data
    ),
  getMeasurementDate: () =>
    Axios.get<GetOfferExecutionTabResponse>("/api/v1/offer-execution/measurement-date").then(
      (response) => response.data
    ),
  getOrderVersion: () =>
    Axios.get<GetOfferExecutionTabResponse>("/api/v1/offer-execution/order-in-progress").then(
      (response) => response.data
    ),
  getSentToCustomer: () =>
    Axios.get<GetOfferExecutionTabResponse>("/api/v1/offer-execution/sent-to-customer").then(
      (response) => response.data
    ),
  getCustomerConfirmed: () =>
    Axios.get<GetOfferExecutionTabResponse>("/api/v1/offer-execution/customer-confirmed").then(
      (response) => response.data
    ),
  getPayment: () =>
    Axios.get<GetOfferExecutionPaymentResponse>("/api/v1/offer-execution/payment").then(
      (response) => response.data
    ),
}

export default offersApi
