import { useCallback, useContext } from "react"
import FeedbackContext from "state/FeedbackContext"
import { SubmitAction } from "api/common/models"

export const useSubmit = <Request, Response = void>(
  editSubmit: SubmitAction<Request, Response>,
  successMessage: string,
  errorMessage: string,
  successAction?: (response: Response) => void
): ((formData: Request) => Promise<void>) => {
  const { showSnackbar, setLoading } = useContext(FeedbackContext)
  return useCallback(
    async (formData: Request) => {
      setLoading(true)
      try {
        const response = await editSubmit(formData)
        showSnackbar("success", successMessage)
        successAction && successAction(response)
      } catch (e) {
        showSnackbar("error", errorMessage)
      }
      setLoading(false)
    },
    [editSubmit, errorMessage, setLoading, showSnackbar, successAction, successMessage]
  )
}
