import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    maxWidth: "1000px",
    display: "flex",
  },
  delete: {
    marginRight: theme.spacing(0.5),
  },
  denseMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  contactContainer: {
    display: "flex"
  },
  contactLabel: {
    marginTop: theme.spacing(0.25),
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
  },
  deleteButton: {
    padding: theme.spacing(1),
  },
  addButton: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(-0.5),
  },
}))

export default useStyles