import React, { Fragment } from "react"
import Paper from "@material-ui/core/Paper"
import Image from "@material-ui/icons/Image"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import Edit from "@material-ui/icons/Edit"
import CircularProgress from "@material-ui/core/CircularProgress"
import { useTranslation } from "react-i18next"
import { makeStyles, createStyles } from "@material-ui/core"

const useStyles = makeStyles(createStyles({
  container: (props: { thumbnail: string | undefined }) => ({
    paddingTop: "75%",
    backgroundImage: props.thumbnail ? `url(${props.thumbnail})` : "none",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    position: "relative",
    margin: "3px",
    cursor: "pointer"
  }),
  utilsWrapper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  placeholder: {
    marginTop: "0px",
    fontSize: "80px",
    opacity: 0.2,
  },
  fileInput: {
    display: "none"
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "-10px",
    marginLeft: "-10px",
  },
  edit: {
    position: "absolute",
    top: "-5px",
    right: "-5px",
    marginTop: "0px",
    padding: "5px"
  },
  uploadContainer: {
    position: "relative"
  }
}))

interface ImageUploadProps {
  image?: {
    full: string
    thumbnail: string
  }
  onUpload: (files: FileList | null) => void
  fileUploading: boolean
}

const ImageUpload: React.FC<ImageUploadProps> = ({ image, onUpload, fileUploading }) => {
  const { t } = useTranslation("general")
  const classes = useStyles({ thumbnail: image?.thumbnail })
  return (
    <Paper className={classes.container}>
      <div
        className={classes.utilsWrapper}
        onClick={image ? () => window.open(image.full, "_blank") : undefined}
      >
        {!image && <Image className={classes.placeholder} />}
        <Fragment>
          <input
            className={classes.fileInput}
            id="thumbnail-button-file"
            type="file"
            accept="image/x-png,image/gif,image/jpeg"
            onClick={e => e.stopPropagation()}
            onChange={e => onUpload(e.target.files)}
          />
          <label
            htmlFor="thumbnail-button-file"
            onClick={e => e.stopPropagation()}
          >
            {!image ? (
              <div className={classes.uploadContainer}>
                {fileUploading && <CircularProgress size={20} className={classes.progress} />}
                <Button
                  size="small"
                  component="span"
                  disabled={fileUploading}
                >
                  {t("Add image")}
                </Button>
              </div>
            ) : (
                <IconButton
                  className={classes.edit}
                  aria-label={t("Add image")}
                  component="span"
                >
                  <Edit />
                </IconButton>
              )}
          </label>
        </Fragment>
      </div>
    </Paper>
  )
}

export default ImageUpload
