import { Role, LegalForm } from "api/counterparties/models"
import { FilterValue, FiltersPillsConfig } from "components/filters/model"
import { getStandardPill, getMultiselectFilterRenderer } from "components/filters/utils"

export type FiltersModel = {
  role: FilterValue<Role>[]
  legalForm: FilterValue<LegalForm>[]
}

type FiltersFields = keyof FiltersModel

const filtersConfig: FiltersPillsConfig<FiltersFields> = {
  role: {
    filterRenderer: getMultiselectFilterRenderer,
    pillRenderer: getStandardPill
  },
  legalForm: {
    filterRenderer: getMultiselectFilterRenderer,
    pillRenderer: getStandardPill
  }
}

export default filtersConfig
