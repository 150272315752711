/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import useStyles from "./Pills.styles"
import { FiltersPillsConfig } from "./model"
import identity from "lodash/identity"

interface PillsProps<T extends { [key: string]: any }> {
  selectedFilters: T
  onRemove?: (filterName: keyof T) => void
  filtersConfig: FiltersPillsConfig<keyof T>
  filtersLabels: { [key in keyof T]: string }
  valueTranslator?: (label: string) => string
}

const Pills = <T extends { [key: string]: any }>(props: PillsProps<T>): JSX.Element => {
  const { selectedFilters, onRemove, filtersConfig, filtersLabels, valueTranslator = identity } = props
  const classes = useStyles()

  return (
    <div className={classes.pillsContainer}>
      {Object.keys(filtersConfig).map((fk) =>
        filtersConfig[fk].pillRenderer({
          key: fk,
          selectedFilters: selectedFilters[fk],
          onRemove,
          filterLabel: filtersLabels[fk],
          valueTranslator,
        })
      )}
    </div>
  )
}

export default Pills
