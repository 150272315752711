import * as Yup from "yup"
import { getTranslation } from "i18n"
import { MountingMaterialCalculationItem } from "api/calculations/models"
import { MountingMaterialCalculationEditData } from "./MountingMaterialCalculationEditForm"
import { ValidationObjectShape } from "validation"

const validationSchema = Yup.object<ValidationObjectShape<MountingMaterialCalculationEditData>>({
  mountingMaterials: Yup.array().of(
    Yup.object<ValidationObjectShape<MountingMaterialCalculationItem>>({
      quantity: Yup.number()
        .required(() => getTranslation("validation", "Field is required"))
        .nullable(),
      mountingMaterial: Yup.object<ValidationObjectShape<{ id: string }>>()
        .required(() => getTranslation("validation", "Field is required"))
        .nullable(),
    })
  ),
})

export default validationSchema
