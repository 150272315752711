import { Permission } from "api/user/models"
import { usePermissions } from "hooks/usePermissions"
import React, { Fragment } from "react"

const Authorize: React.FC<{ permissions: Permission[] | undefined }> = ({ children, permissions }) => {
  const authorize = usePermissions()
  return <Fragment>{authorize(permissions) && children}</Fragment>
}

export default Authorize
