import React, { useCallback } from "react"
import CounterpartiesEditForm from "./editForm/CounterpartiesEditForm"
import { Counterparty, Country, LegalForm, Honorific, PaymentMethod, AcquisitionMethod, CreateCounterpartyResponse } from "api/counterparties/models"
import counterpartiesApi from "api/counterparties/counterpartiesApi"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useSubmit } from "hooks/useSubmit"
import useQueryParam from "hooks/useQueryParam"

const emptyCounterparty: Counterparty = {
  id: "",
  name: "",
  legalForm: LegalForm.INDIVIDUAL,
  honorific: Honorific.MR,
  address: {
    street: "",
    houseNumber: "",
    apartmentNumber: "",
    postalCode: "",
    city: "",
    country: Country.DE,
  },
  contact: {
    phone: "",
    mobilePhone: "",
    fax: "",
    email: "",
  },
  additionalContacts: [],
  roles: [],
  payment: {
    method: PaymentMethod.BANK_TRANSFER,
    downPaymentPercentage: 0,
    discountPercentage: 0,
    period: 0,
  },
  nip: "",
  comments: "",
  acquisitionMethod: AcquisitionMethod.WEBSITE,
  website: ""
}

const CounterpartiesAdd: React.FC = () => {
  const { t } = useTranslation("api")
  const history = useHistory()
  const referrer = useQueryParam("referrer")
  const additionSuccessAction = useCallback(
    (res: CreateCounterpartyResponse) => history.push(referrer ? `${referrer}?counterpartyId=${res.id}` : "/sales/counterparties"),
    [history, referrer]
  )
  const onSubmit = useSubmit(
    counterpartiesApi.createCounterparty,
    t("Form saved"),
    t("Error saving data"),
    additionSuccessAction
  )
  return (
    <CounterpartiesEditForm counterpartyData={emptyCounterparty} onSubmit={onSubmit} />
  )
}

export default CounterpartiesAdd
