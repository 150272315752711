/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import {
  FilterValue,
  FilterRendererProps,
  PillRendererProps,
  FilterSerializer,
  FiltersPillsConfig,
  FiltersConfig,
  DateRange,
} from "./model"
import FilterChip from "./FilterChip"
import MultiselectFilter from "./MultiselectFilter"
import SingleSelectFilter from "./SingleSelectFilter"
import DateRangeFilter from "./DateRangeFilter"
import { getTranslation } from "i18n"

export const getFilterAsDict: (filtersConfig: { [key: string]: FilterValue<any>[] }) => {
  [key: string]: string[]
} = (filtersConfig) =>
  Object.keys(filtersConfig).reduce(
    (prev, curr) => ({ ...prev, [curr]: filtersConfig[curr].map((fv) => fv.label) }),
    {}
  )

export const defaultSerializer: FilterSerializer<any> = (filterName, filterValues) => ({
  [filterName]: filterValues.map((fv) => fv.label).join(),
})

export const dateRangeSerializer: FilterSerializer<DateRange> = (filterName, filters) => {
  if (filters.length > 0 && (filters[0].value.start || filters[0].value.end)) {
    return {
      [`${filterName}From`]: filters[0].value.start || "",
      [`${filterName}To`]: filters[0].value.end || "",
    }
  }
  return {}
}

export const getFilterSerializers: <T extends string | number | symbol>(
  filterConfig: FiltersPillsConfig<T> | FiltersConfig<T>
) => { [key in T]: FilterSerializer<unknown> } = (filterConfig) =>
  Object.keys(filterConfig).reduce(
    (prev, curr) => ({ ...prev, [curr]: (filterConfig as any)[curr].customSerializer || defaultSerializer }),
    {} as any
  )

export const getMultiselectFilterRenderer: <TKey extends string>(
  props: FilterRendererProps<TKey>
) => JSX.Element = ({
  selectedFilters,
  availableFilters,
  onFilterChange,
  filterLabel,
  valueTranslator,
  key,
}) => (
  <MultiselectFilter
    id={key}
    selected={selectedFilters}
    available={availableFilters}
    onChange={onFilterChange}
    name={filterLabel}
    valueTranslator={valueTranslator}
  />
)

export const getSingleSelectFilterRenderer: <TKey extends string>(
  props: FilterRendererProps<TKey>
) => JSX.Element = ({
  selectedFilters,
  availableFilters,
  onFilterChange,
  filterLabel,
  valueTranslator,
  key,
}) => (
  <SingleSelectFilter
    id={key}
    selected={selectedFilters}
    available={availableFilters}
    onChange={onFilterChange}
    name={filterLabel}
    valueTranslator={valueTranslator}
  />
)

export const getStandardPill: <TKey extends string>(props: PillRendererProps<TKey>) => JSX.Element = ({
  key,
  selectedFilters,
  filterLabel,
  onRemove,
  valueTranslator,
}) => (
  <React.Fragment key={key}>
    {selectedFilters.length > 0 && (
      <FilterChip
        filterLabel={filterLabel}
        selectedFilters={selectedFilters}
        onRemove={() => onRemove?.(key)}
        valueTranslator={valueTranslator}
      />
    )}
  </React.Fragment>
)

export const getDateRangeFilterRenderer: <TKey extends string>(
  props: FilterRendererProps<TKey>
) => JSX.Element = ({ selectedFilters, onFilterChange, filterLabel, valueTranslator, key }) => (
  <DateRangeFilter
    key={key}
    id={key}
    name={filterLabel}
    onChange={onFilterChange}
    selected={selectedFilters}
    valueTranslator={valueTranslator}
    dateFormat={getTranslation("general", "DateFormat")}
  />
)
