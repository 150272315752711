import React from "react"
import { makeStyles, createStyles, Theme } from "@material-ui/core"
import Paper from "@material-ui/core/Paper"

const useStyles = makeStyles<Theme, { backgroundColor?: string, color?: string }>(theme =>
  createStyles({
    badge: ({ backgroundColor = "lightgray", color = "black" }) => ({
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
      backgroundColor,
      fontSize: "16px",
      fontWeight: "bold",
      color
    }),
  })
)

type Unit = "euro"

interface BadgeProps {
  label: string
  unit?: Unit
  backgroundColor?: string
  color?: string
}

const unitSymbol: { [key in Unit]: string } = {
  euro: "€"
}

const Badge: React.FC<BadgeProps> = ({ label, unit, ...styleProps }) => {
  const classes = useStyles(styleProps)
  return <Paper className={classes.badge}>{label}{unit && ` ${unitSymbol[unit]}`}</Paper>
}

export default Badge
