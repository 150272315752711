import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import translationPL from "i18n/pl/translations"
import translationDE from "i18n/de/translations"
import detector from "i18next-browser-languagedetector"

i18n
  .use(initReactI18next)
  .use(detector)
  .init({
    fallbackLng: ["pl", "de"],
    debug: process.env.NODE_ENV !== "production",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      pl: { ...translationPL },
      de: { ...translationDE }
    },
    detection: {
      order: ["localStorage"],
      caches: ["localStorage"],
      lookupLocalStorage: "winbros.lang",
    },
  })

export default i18n