import React from "react"
import { FieldProps, getIn } from "formik"
import TextField, { TextFieldProps } from "@material-ui/core/TextField"
import useStyles from "./FormFields.styles"
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@material-ui/core"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import Typography from "@material-ui/core/Typography"
import { DatePicker } from "@material-ui/pickers"
import moment from "moment"
import { ParsableDate } from "@material-ui/pickers/constants/prop-types"

// TODO: move components to separate files

export const FormTextField: <T>(
  p: FieldProps<T> & TextFieldProps
) => React.ReactElement<FieldProps<T> & TextFieldProps> = ({ field, form, ...props }) => {
  const error = !!getIn(form.touched, field.name) && getIn(form.errors, field.name)
  return (
    <TextField
      {...field}
      {...props}
      value={field.value !== undefined && field.value !== null ? field.value : ""}
      onChange={(e) => form.setFieldValue(field.name, e.target.value === "" ? null : e.target.value)}
      error={!!error}
      helperText={error}
    />
  )
}

export const StandardTextField: <T>(
  p: FieldProps<T> & TextFieldProps
) => React.ReactElement<FieldProps<T> & TextFieldProps> = (props) => {
  const classes = useStyles()
  return <FormTextField {...props} className={classes.denseMargin} fullWidth />
}

export const MultilineTextField: <T>(p: FieldProps<T>) => React.ReactElement<FieldProps<T>> = ({
  field,
  form,
}) => {
  const classes = useStyles()
  return (
    <FormControl fullWidth>
      <OutlinedInput
        {...field}
        value={field.value || ""}
        onChange={(e) => form.setFieldValue(field.name, e.target.value === "" ? null : e.target.value)}
        multiline
        maxRows="10"
        className={classes.commentsInput}
      />
    </FormControl>
  )
}

interface DateFieldProps {
  label: string
  required?: boolean
  format?: string
  clearLabel?: string
}

export const DateField: <T>(p: FieldProps<T> & DateFieldProps) => JSX.Element = ({
  field,
  form,
  label,
  required,
  format,
  clearLabel,
}) => {
  const classes = useStyles()
  const error = !!getIn(form.touched, field.name) && getIn(form.errors, field.name)
  return (
    <FormControl variant="outlined" className={classes.denseMargin} fullWidth>
      <DatePicker
        {...field}
        autoOk
        clearable
        label={label}
        required={required}
        value={field.value && moment(field.value).isValid() ? moment(field.value).toDate() : null}
        helperText={error}
        error={!!error}
        clearLabel={clearLabel}
        onChange={(date: ParsableDate) =>
          form.setFieldValue(
            field.name,
            date && moment(date).isValid() ? moment(date).toISOString() : null,
            true
          )
        }
        format={format}
      />
    </FormControl>
  )
}

interface SelectFormFieldProps<T> {
  label: string
  options: {
    value: T
    label: JSX.Element | string
  }[]
  uniqueIdentifier?: (element: T) => string
  placeholder?: string
  displayEmpty?: boolean
}

export const SelectFormField: <T extends { toString: () => string }>(
  p: FieldProps<T> & SelectFormFieldProps<T>
) => React.ReactElement = ({
  field,
  form,
  label,
  options,
  placeholder,
  displayEmpty,
  uniqueIdentifier = (e) => e.toString(),
}) => {
  const classes = useStyles()
  const error = Boolean(getIn(form.touched, field.name)) && getIn(form.errors, field.name)
  return (
    <FormControl className={classes.denseMargin} fullWidth error={Boolean(error)}>
      <InputLabel htmlFor={field.name} required>
        {label}
      </InputLabel>
      <Select
        {...field}
        placeholder={placeholder}
        displayEmpty={displayEmpty}
        value={field.value ? uniqueIdentifier(field.value) : ""}
        onChange={(e) => {
          form.setFieldValue(
            field.name,
            options.find((o) => uniqueIdentifier(o.value) === e.target.value)?.value,
            true
          )
        }}
        fullWidth
      >
        {displayEmpty && <MenuItem value="">&nbsp;</MenuItem>}
        {options.map((option) => (
          <MenuItem key={uniqueIdentifier(option.value)} value={uniqueIdentifier(option.value)}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  )
}

interface MultiselectCheckboxFormFieldProps {
  label: string
  options: {
    value: string
    label: string
  }[]
}

export const MultiselectCheckboxFormField: <T extends string[]>(
  p: FieldProps<T> & MultiselectCheckboxFormFieldProps
) => React.ReactElement = ({ field, form, label, options }) => {
  const classes = useStyles()
  const error = !!getIn(form.touched, field.name) && getIn(form.errors, field.name)
  return (
    <FormControl required fullWidth component="fieldset">
      <FormLabel error={!!error} component="legend" className={classes.role} required>
        {label}
      </FormLabel>
      <FormGroup>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            control={
              <Checkbox
                value={option.value}
                color="primary"
                checked={field.value.indexOf(option.value) > -1}
                onChange={() =>
                  form.setFieldValue(
                    field.name,
                    field.value.indexOf(option.value) > -1
                      ? field.value.filter((v: string) => v !== option.value)
                      : [...field.value, option.value]
                  )
                }
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}

export const SectionHeader: React.FC<{ title: string }> = ({ title }) => (
  <Typography variant="h6">{title}</Typography>
)
