import React, { useContext } from "react"
import { Typography, Button, Avatar, Container, Paper } from "@material-ui/core"
import LockOutlined from "@material-ui/icons/LockOutlined"
import useStyles from "./LoginForm.styles"
import { Formik, FieldProps, Field, Form } from "formik"
import authApi from "api/auth/authApi"
import logo from "assets/logo.jpg"
import LangSwitch from "components/langSwitch/LangSwitch"
import { FormTextField } from "components/forms/FormFields"
import validationSchema from "./LoginForm.validation"
import FeedbackContext from "state/FeedbackContext"
import { useTranslation } from "react-i18next"

export interface LoginFormValues {
  login: string
  password: string
}

const LoginForm: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation("login")
  const { showSnackbar, setLoading } = useContext(FeedbackContext)

  const onSubmit = ({ login, password }: LoginFormValues) => {
    async function postLogin() {
      setLoading(true)
      try {
        await authApi.postLogin({ username: login, password })
        window.location.href = "/"
      } catch (e) {
        showSnackbar("error", t("Logging in failed"))
      }
      setLoading(false)
    }
    postLogin()
  }

  return (
    <Container className={classes.container}>
      <div className={classes.paper}>
        <Paper className={classes.logoPaper}>
          <img src={logo} alt="Logo" className={classes.logoImage} />
        </Paper>
        <Avatar className={classes.avatar}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("Sign in")}
        </Typography>
        <Formik<LoginFormValues>
          initialValues={{
            login: "",
            password: "",
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnChange={false}
        >
          {() => (
            <Form noValidate className={classes.form}>
              <Field name="login">
                {(fieldProps: FieldProps<LoginFormValues>) => (
                  <div>
                    <FormTextField<LoginFormValues>
                      {...fieldProps}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      label={t("Email Address")}
                      autoComplete="email"
                      autoFocus
                    />
                  </div>
                )}
              </Field>
              <Field name="password">
                {(fieldProps: FieldProps<LoginFormValues>) => (
                  <FormTextField<LoginFormValues>
                    {...fieldProps}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label={t("Password")}
                    autoComplete="current-password"
                    type="password"
                  />
                )}
              </Field>
              <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                {t("Sign in")}
              </Button>
            </Form>
          )}
        </Formik>
        <div className={classes.langContainer}>
          <LangSwitch />
        </div>
      </div>
    </Container>
  )
}

export default LoginForm
