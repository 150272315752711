import * as Yup from "yup"
import { getTranslation } from "i18n"
import { MountingMaterial } from "api/mountingMaterials/models"
import { ValidationObjectShape } from "validation"

const validationSchema = Yup.object<ValidationObjectShape<MountingMaterial>>({
  supplier: Yup.object()
    .required(() => getTranslation("validation", "Field is required"))
    .nullable(),
  type: Yup.object()
    .required(() => getTranslation("validation", "Field is required"))
    .nullable(),
  description: Yup.string()
    .required(() => getTranslation("validation", "Field is required"))
    .nullable(),
  indexNumber: Yup.string()
    .required(() => getTranslation("validation", "Field is required"))
    .nullable(),
  location: Yup.string()
    .required(() => getTranslation("validation", "Field is required"))
    .nullable(),
  minStock: Yup.number()
    .required(() => getTranslation("validation", "Field is required"))
    .typeError(() => getTranslation("validation", "NaN")),
  maxStock: Yup.number()
    .required(() => getTranslation("validation", "Field is required"))
    .typeError(() => getTranslation("validation", "NaN")),
  purchasePrice: Yup.string()
    .required(() => getTranslation("validation", "Field is required"))
    .nullable(),
  markup: Yup.number()
    .required(() => getTranslation("validation", "Field is required"))
    .typeError(() => getTranslation("validation", "NaN")),
})

export default validationSchema
