import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filtersContainer: {
      paddingTop: 64,
      display: "flex",
      flexDirection: "column",
    }
  }),
)

export default useStyles
