import React, { FunctionComponent } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { useTranslation } from "react-i18next"

interface ConfirmationDialogInterface {
  open: boolean
  setOpen: (open: boolean) => void
  onConfirm: () => void
 }

const ConfirmationDialog: FunctionComponent<ConfirmationDialogInterface> = ({ open, setOpen, onConfirm }) => {
  const { t } = useTranslation("confirmationDialog")

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("Confirmation title")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("Confirmation description")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            {t("Cancel")}
          </Button>
          <Button onClick={onConfirm} color="secondary" autoFocus>
             {t("Delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConfirmationDialog
