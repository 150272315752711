import React, { useMemo } from "react"
import DataTable, { TableData, HeadRow } from "components/dataTable/DataTable"
import { useTranslation } from "react-i18next"
import { useList } from "hooks/useList"
import productsApi from "api/products/productsApi"
import { useHistory } from "react-router-dom"
import useRowDelete from "hooks/useRowDelete"
import { UnitOfMeasurement } from "api/common/models"
import { usePermissions } from "hooks/usePermissions"

interface Data extends TableData {
  name: string
  unit: UnitOfMeasurement
  comment: string
}

const filters: { [key: string]: string } = {}

const ProductsList: React.FC = () => {
  const { t } = useTranslation(["products", "general", "api", "unitsOfMeasurement"])
  const headRows: HeadRow<Data>[] = useMemo(() => [
    { id: "id", numeric: false, disablePadding: false, label: "Id" },
    { id: "name", numeric: false, disablePadding: false, label: t("Name") },
    { id: "unit", numeric: false, disablePadding: false, label: t("Unit") },
    { id: "comment", numeric: false, disablePadding: false, label: t("Comment") },
  ], [t])
  const history = useHistory()

  const { pageData, data, setPageSize, setPageNumber, onSearch, getList } = useList(
    filters,
    productsApi.getProductsList,
  )

  const addRowAction = () => history.push("/management/products/add")
  const editRowAction = (rowId: string) => history.push(`/management/products/${rowId}/edit`)
  const deleteRowAction = useRowDelete(productsApi.deleteProduct, getList)

  const authorize = usePermissions()

  const rows: Data[] | undefined = data && data.map(row => ({
    id: row.id,
    name: row.name,
    unit: t(`unitsOfMeasurement:${row.unit}`),
    comment: row.comment
  }))
  return (
    <DataTable
      rows={rows}
      headRows={headRows}
      pageData={pageData}
      addRowAction={authorize(["PRODUCTS_WRITE"]) ? addRowAction : undefined}
      rowClickAction={authorize(["PRODUCTS_WRITE"]) ? editRowAction : undefined}
      editRowAction={authorize(["PRODUCTS_WRITE"]) ? editRowAction : undefined}
      deleteRowAction={authorize(["PRODUCTS_WRITE"]) ? deleteRowAction : undefined}
      setPageNumber={setPageNumber}
      setPageLimit={setPageSize}
      onSearch={onSearch}
    />
  )
}

export default ProductsList
