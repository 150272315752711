/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useCallback, useEffect, useState, useContext } from "react"
import { ListModel, ListPage } from "api/common/models"
import FeedbackContext from "state/FeedbackContext"
import { useTranslation } from "react-i18next"

export const useList = <DataModel>(
  serializedFilters: { [key: string]: string },
  fetchList: (
    page: number,
    limit: number,
    filters: { [key: string]: string },
    searchPhrase: string
  ) => Promise<ListModel<DataModel>>
) => {
  const { t } = useTranslation("api")
  const [searchPhrase, setSearchPhrase] = useState("")
  const [pageData, setPageData] = useState<ListPage>({
    number: 0,
    size: 10,
    totalElements: 0,
    totalPages: 0,
  })
  const [data, setData] = useState<DataModel[] | undefined>()
  const { showSnackbar, setLoading } = useContext(FeedbackContext)

  const onSearch = useCallback((searchPhrase: string) => setSearchPhrase(searchPhrase), [])

  const getList = useCallback(async () => {
    setLoading(true)
    try {
      const getData = await fetchList(pageData.number, pageData.size, serializedFilters, searchPhrase)
      setData(getData.data)
      setPageData(getData.page)
    } catch (e) {
      showSnackbar("error", t("Error getting data"))
    }
    setLoading(false)
  }, [
    pageData.number,
    pageData.size,
    searchPhrase,
    setLoading,
    showSnackbar,
    serializedFilters,
    fetchList,
    t,
  ])

  useEffect(() => {
    getList()
  }, [getList])

  const setPageNumber = useCallback(
    (pageNumber: number) => {
      setPageData((prev) => ({ ...prev, number: pageNumber }))
    },
    [setPageData]
  )

  const setPageSize = useCallback(
    (size: number) => {
      setPageData((prev) => ({ ...prev, size }))
    },
    [setPageData]
  )

  return {
    pageData,
    setPageNumber,
    setPageSize,
    data,
    onSearch,
    getList,
  }
}
