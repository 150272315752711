const translation = {
  validation: {
    "Field is required": "Pole jest wymagane",
    "Value is too long": "Wartość jest zbyt długa",
    "Value is too short": "Wartość jest zbyt krótka",
    "Incorrect email": "Niepoprawny adres email",
    NaN: "Wartość nie jest liczbą",
    "Invalid date": "Invalid date",
  },
  api: {
    "Error saving data": "Error saving data",
    "Error getting data": "Error getting data",
    "Error getting dealers": "Error getting dealers",
    "Error getting clients": "Error getting clients",
    "Error getting offer data": "Error getting offer data",
    "Form saved": "Form saved",
    "Error deleting data": "Error deleting data",
    "Row deleted": "Row deleted",
    "Error uploading file": "Error uploading file",
    "Error getting offers summary stats": "Error getting offers summary stats",
    "Error getting offers acquisition stats": "Error getting offers acquisition stats",
    "Error getting product data": "Error getting product data",
    "Error getting products": "Error getting products",
    "Error getting suppliers": "Error getting suppliers",
    "Error getting material types": "Error getting material types",
    "Error getting mounting materials": "Error getting mounting materials",
  },
  menu: {
    Sales: "Verkauf",
    Counterparties: "Kunden",
    "Counterparty editing": "Kundesausgabe",
    "Counterparty addition": "Dodawanie kontrahenta",
    "Counterparty details": "Szczegóły kontrahenta",
    Orders: "Aufträge",
    Offers: "Angebote",
    Stock: "Lager",
    Products: "Produkte",
    "Product editing": "Edycja produktu",
    "Product addition": "Dodawanie produktu",
    Complaints: "Reklamation",
    Logout: "Ausloggen",
    "Offer details": "Szczegóły oferty",
    "Offer editing": "Edycja oferty",
    "Offer addition": "Dodawanie oferty",
    "Offer acquisition": "Pozyskanie ofert",
    "Offer execution": "Reisevorbereitung",
    Statistics: "Statystyki",
    Profits: "Statystyka zysków",
    Management: "Verwaltung",
    "Material types": "Typy materiałów montażowych",
    "Material type addition": "Dodawanie typu materiałów montażowych",
    "Material type editing": "Edycja typu materiałów montażowych",
    "Mounting materials": "Materiały montażowe",
    "Mounting material editing": "Edycja materiału montażowego",
    "Mounting material addition": "Dodawanie materiału montażowego",
    "Mounting material calculation editing": "Edycja kalkulacji wstępnej",
    "Adding mounting material calculation": "Dodawanie kalkulacji wstępnej",
    Users: "Użytkownicy",
    "User editing": "Edycja użytkownika",
    "User addition": "Dodawanie użytkownika",
  },
  login: {
    "Email Address": "E-Mail-Addresse",
    "Sign in": "Einloggen",
    Password: "Passwort",
    "Logging in failed": "Logowanie nie powiodło się",
  },
  "counterparties.edit": {
    "Main data": "Allgemeine Daten",
    "Address data": "Adressdaten",
    "Contact data": "Kontaktdaten",
    "Detailed data": "Einzelheiten",
    "Additional contacts": "Zusätzliche Ansprechpartner",
    Name: "Name",
    "Legal form": "Rechtsform",
    INDIVIDUAL: "Osoba",
    COMPANY: "Firma",
    Honorific: "Nagłówek",
    MR: "Pan",
    MRS: "Pani",
    MR_MRS: "Państwo",
    Role: "Typ(de)",
    CUSTOMER: "Kunde",
    SUPPLIER: "Versorger",
    Street: "Straße",
    House: "Hausnummer",
    Apartment: "Wohnungsnummer",
    "Zip Code": "PLZ",
    City: "Stadt",
    Country: "Kraj",
    Phone: "Telefon",
    MobilePhone: "Telefon komórkowy",
    Fax: "Fax",
    Email: "Email",
    NIP: "NIP",
    "Payment method": "Sposób płatności",
    BANK_TRANSFER: "Przelew",
    CASH: "Gotówka",
    CHEQUE: "Czek",
    CREDIT: "Kredyt",
    "Down payment": "% przedpłaty",
    "Payment period": "Termin płatności",
    Discount: "% upustu",
    Comments: "Uwagi",
    "Contact person": "Osoba kontaktowa",
    ACName: "Imię i nazwisko",
    ACEmail: "Email",
    ACPhone: "Telefon",
    ACComments: "Uwagi",
    "Acquisition method": "Sposób zdobycia klienta",
    "Edit counterparty": "Edytuj kontrahenta",
  },
  confirmationDialog: {
    "Confirmation title": "Potwierdzenie usunięcia",
    "Confirmation description": "Czy chcesz usunąć ten rekord?",
    Cancel: "Anuluj",
    Delete: "Usuń",
  },
  list: {
    Add: "Dodaj element",
    Delete: "Usuń",
    Edit: "Edytuj",
    Details: "Wyświetl szczegóły",
    "Rows per page": "Wiersze na stronę",
    Actions: "Akcje",
    "No data to display": "Keine Daten zum Anzeigen",
  },
  general: {
    Filters: "Filtern",
    PL: "Polen",
    DE: "Deutschland",
    Yes: "Ja",
    No: "Nein",
    DateFormat: "DD.MM.YYYY",
    Save: "Speichern",
    Clear: "Wyczyść",
    Urgent: "Dringend",
    Important: "Wichtig",
    Normal: "Normal",
    "N/A": "n.a.",
    "Add image": "Add image",
  },
  acquisitionMethods: {
    RECOMMENDATION: "Weiterempfehlung",
    GOOGLE: "Google",
    FACEBOOK: "Facebook",
    INSTAGRAM: "Instagram",
    CAR: "Fahrzeuge",
    EXHIBITION: "Messe",
    BUILDING: "Gebaude",
    FLYER: "Flyer/Prospekt",
    RADIO: "Radio",
    TV: "TV",
    PRESS: "Zeitung",
    EXISTING_CLIENT: "Bestehender",
    CLIENT: "Kunde",
    WEBSITE: "Strona internetowa",
    ARCHITECT: "Architekt",
    OTHER: "Inny",
  },
  offers: {
    Offer: "Oferta",
    Details: "Szczegóły oferty",
    Measurement: "Pomiar",
    "Offer amounts": "Wartość oferty",
    Installation: "Montage",
    Comments: "Uwagi",
    "Client files": "Pliki dla klienta",
    "Internal files": "Pliki wewnętrzne",
    "Construction address": "BV",
    Dealer: "Sprzedawca",
    "Select dealer": "Select dealer",
    Status: "Status",
    "Creation date": "Data utworzenia",
    ID: "ID",
    Priority: "Priorytet",
    Client: "Klient",
    "Select client": "Select client",
    "Offer number": "Numer oferty",
    "Construction type": "Typ budowy",
    "Client wish date": "Wunschtermin",
    "Acquisition method": "Sposób pozyskania oferty",
    "Delivery place": "Miejsce dostawy",
    "Offer with measurement": "Aufmass",
    "Measurement until": "Aufmassdatum bis",
    "Measurement date": "Aufmasstermin",
    "Order in progress": "Auftragsversion in arbeit",
    "Sent to customer": "Gesendet an Kunde",
    "Customer confirmed": "Auftrag erhalten",
    "Order checked": "Auftrag gepruft",
    "Offer with advance payment": "Abschlag",
    "Advance payment date": "Abschlagdatum",
    "Payment completed": "Bezahlt",
    "Payment date": "Bezahltdatum",
    "Payment place": "Payment place",
    "Offer amount": "Wartość oferty",
    Costs: "Koszt",
    Profit: "Zysk",
    "Profit percentage": "Zysk %",
    "Installation days": "Dni montażystów",
    "Assemblers count": "Liczba montażystów",
    "Delivery place other": "Inne miejsce dostawy",
    "Add client": "Dodaj nowego klienta",
    "Edit offer": "Edytuj ofertę",
    Type: "Typ",
    Supplier: "Dostawca",
    Quantity: "Anzahl",
    Unit: "Jednostka",
    "Running meters": "Lfdm",
    Price: "Preis",
    Product: "Produkt",
    Products: "Produkte",
    "Add product type": "Dodaj nowy typ produktu",
    "Select product type": "Select product type",
    "Product type": "Produkttyp",
    "Add supplier": "Dodaj nowego dostawcę",
    "Select supplier": "Select supplier",
    "Offer with installation": "Montage",
    "Add offer product": "Dodaj kolejny produkt",
    "Calculation value": "Wartość kalulacji",
    "Edit calculation": "Edytuj kalkulację",
    "Mounting materials calculation": "Kalkulacja wstępna",
    "Add offer calculation": "Dodaj kalkulację wstępną",
  },
  "offers.status": {
    OPENED: "Offen",
    IN_PROCESSING: "In Bearbeitung",
    OFFER_READY: "Angebot fertig: Termin&Abschluss!",
    OFFER_AT_CUSTOMER: "Angebot beim Kunden: Abschluss!",
    GET_ORDER: "Auftrag holen (Druck!)",
    ENDED_WITHOUT_ORDER: "Beendet ohne Auftrag",
    ENDED_WITH_ORDER: "Beendet mit Auftrag",
    TRADE: "Handel",
  },
  "offers.constructionType": {
    NEW: "Nowa budowa",
    RENOVATION: "Renowacja",
  },
  "offers.deliveryPlaceType": {
    OFFICE: "Biuro",
    CONSTRUCTION: "BV",
    OTHER: "Inne",
  },
  calculations: {
    "Mounting materials calculation": "Materiały montażowe",
    Material: "Materiał",
    "Select material type": "Wybierz rodzaj materiału",
    "Material type": "Rodzaj materiału",
    "Add material": "Dodaj kolejny materiał",
    "Add material type": "Dodaj nowy typ materiału",
    "Price x Quantity": "Cena x Ilość",
    "Error getting mounting materials": "Error getting mounting materials",
    "Add new mounting material": "Dodaj materiał montażowy",
    Quantity: "Anzahl",
    "Unit price": "Cena jednostkowa",
  },
  "stats.profits": {
    "Offers summary": "Podsumowanie ofert",
    "Total count": "Liczba ofert",
    "Total amount": "Całkowita kwota",
    "Total profit": "Całkowity zysk",
    "Average profit": "Średni zysk",
    "Average profit percentage": "Średni zysk procentowy",
  },
  searchBar: {
    Search: "Suche",
  },
  offerExecution: {
    "Measurement until": "Aufmassdatum",
    "Measurement date": "Aufmasstermin",
    "Order version": "Auftragsversion",
    "Sent to customer": "Gesendet an Kunde",
    "Customer confirmed": "Auftrag erhalten",
    Payment: "Zahlung",
    "Advance payment not paid": "Abschlag nicht bezahlt",
    "Advance payment paid": "Abschlag bezahlt",
    "No advance payment": "Ohne Abschlag",
  },
  products: {
    Name: "Name",
    Unit: "Maßeinheiten",
    Comment: "Bemerkungen",
  },
  unitsOfMeasurement: {
    PIECE: "Stck.",
    PACKAGE: "Packung",
    METRE: "lfdm",
    KILOGRAM: "kg",
  },
  materialTypes: {
    Name: "Name",
    Comments: "Bemerkungen",
  },
  mountingMaterials: {
    Description: "Artikel Bezeichnung",
    Type: "Typ",
    Supplier: "Lieferant",
    "Index number": "Indexnummer",
    Location: "Lokalizacja",
    "Purchase price": "EK Preis",
    "Selling price": "VK Preis",
    "Add type": "Dodaj typ",
    "Add supplier": "Dodaj dostawcę",
    "Stock minimum": "Min bestand",
    "Stock maximum": "Max bestand",
    "Select type": "Wybierz typ",
    "Select supplier": "Wybierz dostawcę",
    Markup: "Marża",
    "Purchase unit": "Bestelleinheit",
    "Selling unit": "Kalkulationeinheit",
    Norm: "Norma",
  },
  users: {
    Name: "Name",
    Login: "Login",
    Email: "Email",
    Roles: "Role",
    "New password": "Nowe hasło",
    "First name": "Imię",
    "Last name": "Nazwisko",
    "Select roles": "Wybierz role",
  },
  userRoles: {
    ROLE_ADMIN: "Administrator",
    ROLE_DEALER: "Händler",
  },
}

export default translation
