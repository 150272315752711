import { useEffect, useContext, useState } from "react"
import { useParams } from "react-router-dom"
import FeedbackContext from "state/FeedbackContext"

export const useGetDetails = <T>(
  getDetails: (id: number) => Promise<T>,
  errorMessage: string,
  setLoading?: (loading: boolean) => void
): T | undefined => {
  const { id } = useParams()
  const [details, setDetails] = useState<T | undefined>(undefined)
  const { showSnackbar, setLoading: globalSetLoading } = useContext(FeedbackContext)
  const onLoading = setLoading || globalSetLoading
  useEffect(() => {
    const fetchDetails = async () => {
      onLoading(true)
      try {
        const fetchedDetails = await getDetails(+id)
        setDetails(fetchedDetails)
      } catch {
        showSnackbar("error", errorMessage)
      }
      onLoading(false)
    }
    fetchDetails()
  }, [id, errorMessage, onLoading, showSnackbar, getDetails])

  return details
}
