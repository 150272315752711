import React, { useCallback } from "react"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useSubmit } from "hooks/useSubmit"
import { Product } from "api/products/models"
import ProductEditForm from "./editForm/ProductEditForm"
import productsApi from "api/products/productsApi"
import { UnitOfMeasurement } from "api/common/models"

const emptyProduct: Omit<Product, "id"> & { id?: string } = {
  comment: "",
  name: "",
  unit: UnitOfMeasurement.PIECE
}

const ProductAdd: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation("api")
  const additionSuccessAction = useCallback(() => history.push("/management/products"), [history])
  const onSubmit = useSubmit(
    productsApi.createProduct,
    t("Form saved"),
    t("Error saving data"),
    additionSuccessAction
  )
  return (
    <ProductEditForm
      productData={emptyProduct}
      onSubmit={onSubmit}
    />
  )
}

export default ProductAdd
