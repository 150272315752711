import React, { Fragment, useCallback } from "react"
import { useHistory, useParams } from "react-router-dom"
import userApi from "api/user/userApi"
import { useTranslation } from "react-i18next"
import { useGetDetails } from "hooks/useGetDetails"
import { useSubmit } from "hooks/useSubmit"
import useQueryParam from "hooks/useQueryParam"
import { EditUserRequest } from "api/user/models"
import UserEditForm from "./editForm/UserEditForm"
import rolesApi from "api/roles/rolesApi"
import { useConcurrentLoading } from "hooks/useConcurrentLoading"
import { useGetAll } from "hooks/useGetAll"

const UserEdit: React.FC = () => {
  const { t } = useTranslation("api")
  const history = useHistory()
  const { id } = useParams()

  const onLoading = useConcurrentLoading(2)
  const roles = useGetAll(rolesApi.getAllRoles, t("Error getting data"), onLoading)
  const userData = useGetDetails(userApi.getUser, t("Error getting data"), onLoading)

  const referrer = useQueryParam("referrer")
  const editSuccessAction = useCallback(
    () => history.push(referrer || "/management/users"),
    [history, referrer]
  )
  const submitAction = useCallback((formData: EditUserRequest) => userApi.editUser(+id, formData), [id])
  const onSubmit = useSubmit(
    submitAction,
    t("Form saved"),
    t("Error saving data"),
    editSuccessAction
  )

  return (
    <Fragment>
      {userData && 
        <UserEditForm
          userData={userData}
          roles={roles}
          onSubmit={onSubmit}
        />
      }
    </Fragment>
  )
}

export default UserEdit
