import React, { useMemo, Fragment } from "react"
import DataTable, { TableData, HeadRow } from "components/dataTable/DataTable"
import { useTranslation } from "react-i18next"
import { useList } from "hooks/useList"
import mountingMaterialsApi from "api/mountingMaterials/mountingMaterialsApi"
import { useHistory } from "react-router-dom"
import useRowDelete from "hooks/useRowDelete"
import { getCounterpartyShortName } from "api/counterparties/models"
import { getSellingPrice } from "api/mountingMaterials/utils"
import Pills from "components/filters/Pills"
import FiltersDrawer from "components/filters/FiltersDrawer"
import Filters from "components/filters/Filters"
import { useFilters } from "hooks/useFilters"
import { getFilterSerializers } from "components/filters/utils"
import filtersConfig, { FiltersModel } from "./filters/FiltersConfig"
import { useGetAll } from "hooks/useGetAll"
import counterpartiesApi from "api/counterparties/counterpartiesApi"
import materialTypesApi from "api/materialTypes/materialTypesApi"
import { usePermissions } from "hooks/usePermissions"

interface Data extends TableData {
  description: string
  type: string
  indexNumber: string
  location: string
  purchasePrice: string
  sellingPrice: string
  supplier: string
}

const serializers = getFilterSerializers(filtersConfig)

const MountingMaterialsList: React.FC = () => {
  const { t } = useTranslation(["mountingMaterials", "api"])
  const headRows: HeadRow<Data>[] = useMemo(
    () => [
      { id: "id", numeric: false, disablePadding: false, label: "Id" },
      { id: "description", numeric: false, disablePadding: false, label: t("Description") },
      { id: "type", numeric: false, disablePadding: false, label: t("Type") },
      { id: "supplier", numeric: false, disablePadding: false, label: t("Supplier") },
      { id: "indexNumber", numeric: false, disablePadding: false, label: t("Index number") },
      { id: "location", numeric: false, disablePadding: false, label: t("Location") },
      { id: "purchasePrice", numeric: false, disablePadding: false, label: t("Purchase price") },
      { id: "sellingPrice", numeric: false, disablePadding: false, label: t("Selling price") },
    ],
    [t]
  )
  const history = useHistory()
  const {
    filterDrawerOpened,
    onFilterChange,
    toggleFilterDrawer,
    filters,
    serializedFilters,
    onFilterRemove,
  } = useFilters<FiltersModel>({ type: [], supplier: [] }, serializers)

  const { pageData, data, setPageSize, setPageNumber, onSearch, getList } = useList(
    serializedFilters,
    mountingMaterialsApi.getMountingMaterialsList
  )

  const addRowAction = () => history.push("/stock/mountingMaterials/add")
  const editRowAction = (rowId: string) => history.push(`/stock/mountingMaterials/${rowId}/edit`)
  const deleteRowAction = useRowDelete(mountingMaterialsApi.deleteMountingMaterial, getList)

  const types = useGetAll(materialTypesApi.getAllMaterialTypes, t("Error getting data", { ns: "api" }))
  const suppliers = useGetAll(counterpartiesApi.getAllSuppliers, t("Error getting suppliers", { ns: "api" }))

  const authorize = usePermissions()

  const filterLabels = useMemo(
    () => ({
      type: t("Type"),
      supplier: t("Supplier"),
    }),
    [t]
  )

  const availableFilters = useMemo(
    () => ({
      type: types?.map((t) => ({ label: t.name, value: t.id })) ?? [],
      supplier: suppliers?.map((s) => ({ label: s.name, value: s.id })) ?? [],
    }),
    [types, suppliers]
  )

  const rows: Data[] | undefined =
    data &&
    data.map((row) => ({
      id: row.id,
      description: row.description,
      type: row.type.name,
      indexNumber: row.indexNumber,
      location: row.location ?? "",
      purchasePrice: `${row.purchasePrice} €`,
      sellingPrice: `${getSellingPrice(row.purchasePrice, row.markup)} €`,
      supplier: getCounterpartyShortName(row.supplier),
    }))
  return (
    <Fragment>
      <Pills
        selectedFilters={filters}
        filtersConfig={filtersConfig}
        onRemove={onFilterRemove}
        filtersLabels={filterLabels}
      />
      <FiltersDrawer opened={filterDrawerOpened} onClose={toggleFilterDrawer}>
        <Filters
          selectedFilters={filters}
          availableFilters={availableFilters}
          onFilterChange={onFilterChange}
          filtersConfig={filtersConfig}
          filtersLabels={filterLabels}
        />
      </FiltersDrawer>
      <DataTable
        rows={rows}
        headRows={headRows}
        pageData={pageData}
        addRowAction={authorize(["MOUNTING_MATERIALS_WRITE"]) ? addRowAction : undefined}
        rowClickAction={authorize(["MOUNTING_MATERIALS_WRITE"]) ? editRowAction : undefined}
        editRowAction={authorize(["MOUNTING_MATERIALS_WRITE"]) ? editRowAction : undefined}
        deleteRowAction={authorize(["MOUNTING_MATERIALS_WRITE"]) ? deleteRowAction : undefined}
        setPageNumber={setPageNumber}
        setPageLimit={setPageSize}
        onSearch={onSearch}
        onFiltersToggle={toggleFilterDrawer}
      />
    </Fragment>
  )
}

export default MountingMaterialsList
