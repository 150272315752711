import { makeStyles, createStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      maxWidth: "1000px",
      display: "flex",
      flexDirection: "column",
      margin: theme.spacing(3),
    },
    paper: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(3),
      width: "100%",
    },
    rowContainer: {
      display: "flex",
    },
    rowCenterContainer: {
      display: "flex",
      alignItems: "center",
    },
    statusCircle: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: 0,
    },
    amountsField: {
      fontWeight: theme.typography.fontWeightMedium,
      paddingLeft: theme.spacing(0.5)
    },
    submit: {
      marginTop: theme.spacing(1),
    },
    measurementFieldGroup: {
      paddingLeft: theme.spacing(5),
    },
    addButton: {
      marginTop: theme.spacing(1),
    },
  }))

export default useStyles