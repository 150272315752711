import { useRef, useState, useEffect, RefObject } from "react"
import { getBackgroundColor as defaultGetBackgroundColor } from "views/stats/common"
import { ChartConfiguration } from "chart.js"
import Chart from "chart.js"

export interface ChartData {
  labels: string[]
  data: number[]
}

function useForceUpdate() {
  const [, setValue] = useState(0)
  return () => setValue((value) => ++value)
}

const useChart = (
  config: { chartConfig: ChartConfiguration; getBackgroundColor?: Chart.Scriptable<Chart.ChartColor> },
  chartData: ChartData
): RefObject<HTMLCanvasElement> => {
  const chartContainerRef = useRef<HTMLCanvasElement>(null)
  const chartRef = useRef<Chart>()
  const forceUpdate = useForceUpdate()

  useEffect(() => {
    const chart = chartRef.current
    if (chart) {
      chart.data.labels = chartData.labels
      chart.data.datasets = [
        {
          data: chartData.data,
          backgroundColor: config.getBackgroundColor || defaultGetBackgroundColor,
        },
      ]
      chart.update()
    } else if (chartContainerRef.current) {
      chartRef.current = new Chart(chartContainerRef.current, config.chartConfig)
      forceUpdate()
    }
  }, [chartData, chartRef, config, forceUpdate])

  useEffect(() => {
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy()
      }
    }
  }, [])

  return chartContainerRef
}

export default useChart
