import React, { Fragment, useCallback } from "react"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import useStyles from "./MountingMaterialEditForm.styles"
import { Formik, Form, Field, FieldProps } from "formik"
import { SelectFormField, StandardTextField } from "components/forms/FormFields"
import validationSchema from "./MountingMaterialEditForm.validation"
import { useTranslation } from "react-i18next"
import { MountingMaterial, EditMountingMaterialRequest } from "api/mountingMaterials/models"
import { Counterparty, getCounterpartyShortName } from "api/counterparties/models"
import { MaterialType } from "api/materialTypes/models"
import { UnitOfMeasurement, UploadedImage } from "api/common/models"
import AutocompleteField from "components/forms/AutocompleteField"
import Tooltip from "@material-ui/core/Tooltip"
import IconButton from "@material-ui/core/IconButton"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import { Link, useLocation } from "react-router-dom"
import AddCircle from "@material-ui/icons/AddCircle"
import useFormFieldStyles from "components/forms/FormFields.styles"
import { getSellingPrice } from "api/mountingMaterials/utils"
import useAddFile from "hooks/useAddFile"
import ImageUpload from "components/ImageUpload"

export type MountingMaterialEditData = Omit<MountingMaterial, "id" | "supplier" | "type"> & {
  id?: string
  supplier: Counterparty | null
  type: MaterialType | null
}

type MountingMaterialSubmitRequest = EditMountingMaterialRequest

const MountingMaterialEditForm: React.FC<{
  data?: MountingMaterialEditData
  onSubmit: (formData: MountingMaterialSubmitRequest) => Promise<void>
  suppliers?: Counterparty[]
  types?: MaterialType[]
  addImageApi: (formData: FormData) => Promise<UploadedImage>
}> = ({ data, onSubmit, suppliers, types, addImageApi }) => {
  const formStylesClasses = useFormFieldStyles()
  const { t } = useTranslation(["mountingMaterials", "general", "unitsOfMeasurement"])
  const { pathname } = useLocation()
  const submitHandler = useCallback(
    (formData: MountingMaterialEditData) => {
      const { supplier, type, ...payload } = formData
      if (supplier && type) {
        onSubmit({ ...payload, supplier: { id: supplier.id }, type: { id: type.id } })
      }
    },
    [onSubmit]
  )
  const { addFileHandler, fileUploading } = useAddFile(addImageApi)
  const classes = useStyles({ thumbnail: data?.images[0]?.urls.thumbnail })
  return (
    <Fragment>
      {data && suppliers && types && (
        <Formik
          initialValues={data}
          onSubmit={submitHandler}
          validationSchema={validationSchema}
          validateOnChange={false}
        >
          {({ values, setFieldValue }) => (
            <Form noValidate className={classes.container}>
              <Paper className={classes.paper}>
                <Grid container spacing={1}>
                  <Grid item xs={9}>
                    <div className={classes.rowContainer}>
                      <Field name="type">
                        {(fieldProps: FieldProps) => (
                          <AutocompleteField<MaterialType | null>
                            {...fieldProps}
                            placeholder={t("Select type")}
                            label={t("Type")}
                            required
                            multiple={false}
                            options={types}
                            getOptionSelected={(option, value) =>
                              option && value ? option.id === value.id : false
                            }
                            getOptionLabel={(t) => (t && t.name) ?? ""}
                          />
                        )}
                      </Field>
                      <Tooltip title={t("Add type") || ""}>
                        <Link to={`/management/materialTypes/add?referrer=${encodeURIComponent(pathname)}`}>
                          <IconButton
                            className={classes.addButton}
                            aria-label={t("Add type")}
                            component="span"
                          >
                            <AddCircle />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    </div>
                    <div className={classes.rowContainer}>
                      <Field name="supplier">
                        {(fieldProps: FieldProps) => (
                          <AutocompleteField<Counterparty | null>
                            {...fieldProps}
                            placeholder={t("Select supplier")}
                            label={t("Supplier")}
                            required
                            multiple={false}
                            options={suppliers}
                            getOptionSelected={(option, value) =>
                              option && value ? option.id === value.id : false
                            }
                            getOptionLabel={(c) => (c && getCounterpartyShortName(c)) ?? ""}
                          />
                        )}
                      </Field>
                      <Tooltip title={t("Add supplier") || ""}>
                        <Link to={`/sales/counterparties/add?referrer=${encodeURIComponent(pathname)}`}>
                          <IconButton
                            className={classes.addButton}
                            aria-label={t("Add supplier")}
                            component="span"
                          >
                            <AddCircle />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    </div>
                    <Field name="description">
                      {(fieldProps: FieldProps) => (
                        <StandardTextField {...fieldProps} required label={t("Description")} />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={3}>
                    <ImageUpload
                      fileUploading={fileUploading}
                      onUpload={(files) => addFileHandler(files, (file) => setFieldValue("images", [file]))}
                      image={values?.images[0]?.urls}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Field name="indexNumber">
                      {(fieldProps: FieldProps) => (
                        <StandardTextField {...fieldProps} required label={t("Index number")} />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={4}>
                    <Field name="location">
                      {(fieldProps: FieldProps) => (
                        <StandardTextField {...fieldProps} label={t("Location")} />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={2}>
                    <Field name="minStock">
                      {(fieldProps: FieldProps) => (
                        <StandardTextField {...fieldProps} label={t("Stock minimum")} />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={2}>
                    <Field name="maxStock">
                      {(fieldProps: FieldProps) => (
                        <StandardTextField {...fieldProps} label={t("Stock maximum")} />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Field name="purchasePrice">
                      {(fieldProps: FieldProps) => (
                        <StandardTextField
                          {...fieldProps}
                          required
                          label={t("Purchase price")}
                          InputProps={{
                            endAdornment: <InputAdornment position="start">€</InputAdornment>,
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={4}>
                    <Field name="markup">
                      {(fieldProps: FieldProps) => (
                        <StandardTextField
                          {...fieldProps}
                          label={t("Markup")}
                          required
                          InputProps={{
                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      className={formStylesClasses.denseMargin}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                        endAdornment: <InputAdornment position="start">€</InputAdornment>,
                      }}
                      value={getSellingPrice(values.purchasePrice, values.markup)}
                      label={t("Selling price")}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Field name="purchaseUnit">
                      {(fieldProps: FieldProps) => (
                        <SelectFormField
                          {...fieldProps}
                          label={t("Purchase unit")}
                          options={(Object.keys(UnitOfMeasurement) as UnitOfMeasurement[]).map((el) => ({
                            value: el,
                            label: t(`unitsOfMeasurement:${el}`),
                          }))}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={4}>
                    <Field name="sellingUnit">
                      {(fieldProps: FieldProps) => (
                        <SelectFormField
                          {...fieldProps}
                          label={t("Selling unit")}
                          options={(Object.keys(UnitOfMeasurement) as UnitOfMeasurement[]).map((el) => ({
                            value: el,
                            label: t(`unitsOfMeasurement:${el}`),
                          }))}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={4}>
                    <Field name="norm">
                      {(fieldProps: FieldProps) => <StandardTextField {...fieldProps} label={t("Norm")} />}
                    </Field>
                  </Grid>
                </Grid>
              </Paper>
              <Grid container justifyContent="center">
                <Grid item xs={6}>
                  <Button
                    className={classes.submit}
                    type="submit"
                    variant="contained"
                    fullWidth
                    color="primary"
                  >
                    {t("general:Save")}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Fragment>
  )
}

export default MountingMaterialEditForm
