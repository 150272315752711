import React from "react"
import { makeStyles, createStyles } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import classNames from "classnames"

const useStyles = makeStyles(theme =>
  createStyles({
    element: {
      padding: theme.spacing(0.125),
    },
    label: {
      paddingRight: theme.spacing(1),
      color: theme.palette.grey[600],
    },
  })
)

interface DetailFieldProps {
  label: string
  value?: string | null
  className?: string
}

const DetailField: React.FC<DetailFieldProps> = ({ label, value, className }) => {
  const classes = useStyles()
  return (
    <div className={classNames(classes.element, className)}>
      <Typography variant="body2" component="span" className={classes.label}>{label}:</Typography>
      <Typography variant="body1" component="span">{value || ""}</Typography>
    </div>
  )
}

export default DetailField