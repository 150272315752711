import { makeStyles } from "@material-ui/core/styles"

const drawerWidth = 260

const useStyles = makeStyles(theme => ({
  navLink: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  active: {
    color: theme.palette.primary.main,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 4px",
    cursor: "pointer",
    ...theme.mixins.toolbar,
    maxHeight: theme.mixins.toolbar.minHeight,
  },
  nestedOpen: {
    paddingLeft: theme.spacing(4)
  },
  nestedClosed: {
    paddingLeft: theme.spacing(2)
  },
  logo: {
    width: "100%",
    padding: theme.spacing(2)
  },
  menuIcon: {
    color: "inherit",
    minWidth: theme.spacing(5)
  },
  menuLabel: {
    "& span": {
      width: "100%",
      textOverflow: "ellipsis",
      overflow: "hidden",
    }
  }
}))

export default useStyles