import React, { FunctionComponent, useState } from "react"
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Button } from "@material-ui/core"
import useStyles from "./TopBar.styles"
import MenuIcon from "@material-ui/icons/Menu"
import AccountCircle from "@material-ui/icons/AccountCircle"
import routesConfig from "config/RoutesConfig"
import { Route, Switch } from "react-router-dom"
import LangSwitch from "components/langSwitch/LangSwitch"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

const TopBar: FunctionComponent<{
  menuOpen: boolean
  onMenuToggle: () => void
  userName: string
  onLogout: () => void
}> = ({ menuOpen, onMenuToggle, userName, onLogout }) => {
  const classes = useStyles()
  const { t } = useTranslation("menu")
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleUserMenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleUserMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <AppBar
        position="fixed"
        className={classNames(classes.appBar, {
          [classes.appBarShift]: menuOpen,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={onMenuToggle}
            edge="start"
            className={classNames(classes.menuButton, {
              [classes.hide]: menuOpen,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            <Switch>
              {routesConfig.map((route) => (
                <Route exact key={route.path} path={route.path} render={() => t(route.title || "")} />
              ))}
            </Switch>
          </Typography>
          <div className={classes.spacer}></div>
          <LangSwitch />
          <div>
            <Button variant="outlined" onClick={handleUserMenuClick} className={classes.userButton}>
              <AccountCircle className={classes.userIcon} />
              {userName}
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={!!anchorEl}
              onClose={handleUserMenuClose}
            >
              <MenuItem onClick={onLogout}>{t("Logout")}</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default TopBar
