import React, { Fragment } from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import Paper from "@material-ui/core/Paper"
import useStyles from "./CounterpartiesEditForm.styles"
import { Formik, Form, Field, FieldProps, FieldArray } from "formik"
import { Counterparty, AdditionalContact, LegalForm, Honorific, Role, Country, PaymentMethod, AcquisitionMethod } from "api/counterparties/models"
import { StandardTextField, SelectFormField, MultiselectCheckboxFormField } from "components/forms/FormFields"
import Delete from "@material-ui/icons/Delete"
import AddCircle from "@material-ui/icons/AddCircle"
import validationSchema from "./CounterpartiesEditForm.validation"
import { useTranslation } from "react-i18next"
import StickySubmit from "components/forms/StickySubmit"

const CounterpartiesEditForm: React.FC<{
  counterpartyData?: Counterparty
  onSubmit: (formData: Counterparty) => void
}> =
  ({ counterpartyData, onSubmit }) => {
    const classes = useStyles()
    const { t } = useTranslation(["counterparties.edit", "general", "acquisitionMethods"])
    return (
      <Fragment>
        {counterpartyData !== undefined &&
          <Paper className={classes.container}>
            <Formik
              initialValues={counterpartyData}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              validateOnChange={false}>
              {props => {
                const isCustomer = props.values.roles.some(role => role === Role.CUSTOMER)
                return <Form noValidate>
                  <Grid container spacing={1}>
                    <Grid item xs={12}><Typography variant="h6">{t("Main data")}</Typography></Grid>
                    <Grid item xs={8}>
                      <Field name="name">
                        {(fieldProps: FieldProps) => (
                          <StandardTextField
                            {...fieldProps}
                            required
                            label={t("Name")}
                            autoFocus
                          />)}
                      </Field>
                    </Grid>
                    <Grid item xs={2}>
                      <Field name="legalForm">
                        {(fieldProps: FieldProps) => (
                          <SelectFormField
                            {...fieldProps}
                            label={t("Legal form")}
                            options={(Object.keys(LegalForm) as LegalForm[]).map(el => ({ value: el, label: t(el) }))}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={2}>
                      <Field name="honorific">
                        {(fieldProps: FieldProps) => (
                          <SelectFormField
                            {...fieldProps}
                            label={t("Honorific")}
                            options={(Object.keys(Honorific) as Honorific[]).map(el => ({ value: el, label: t(el) }))}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="roles">
                        {(fieldProps: FieldProps<Role[]>) => (
                          <MultiselectCheckboxFormField<Role[]>
                            {...fieldProps}
                            label={t("Role")}
                            options={(Object.keys(Role) as Role[]).map(el => ({ value: el, label: t(el) }))}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}><Typography variant="h6">{t("Address data")}</Typography></Grid>
                    <Grid item xs={6}>
                      <Field name="address.street">
                        {(fieldProps: FieldProps) => (
                          <StandardTextField
                            {...fieldProps}
                            required
                            label={t("Street")}
                          />)}
                      </Field>
                    </Grid>
                    <Grid item xs={3}>
                      <Field name="address.houseNumber">
                        {(fieldProps: FieldProps) => (
                          <StandardTextField
                            {...fieldProps}
                            required
                            label={t("House")}
                          />)}
                      </Field>
                    </Grid>
                    <Grid item xs={3}>
                      <Field name="address.apartmentNumber">
                        {(fieldProps: FieldProps) => (
                          <StandardTextField
                            {...fieldProps}
                            label={t("Apartment")}
                          />)}
                      </Field>
                    </Grid>
                    <Grid item xs={3}>
                      <Field name="address.postalCode">
                        {(fieldProps: FieldProps) => (
                          <StandardTextField
                            {...fieldProps}
                            required
                            label={t("Zip Code")}
                          />)}
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      <Field name="address.city">
                        {(fieldProps: FieldProps) => (
                          <StandardTextField
                            {...fieldProps}
                            required
                            label={t("City")}
                          />)}
                      </Field>
                    </Grid>
                    <Grid item xs={3}>
                      <Field name="address.country">
                        {(fieldProps: FieldProps) => (
                          <SelectFormField
                            {...fieldProps}
                            label={t("Country")}
                            options={(Object.keys(Country) as Country[]).map(el => ({ value: el, label: t(`general:${el}`) }))}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={3}>
                      <Field name="contact.phone">
                        {(fieldProps: FieldProps) => (
                          <StandardTextField
                            {...fieldProps}
                            label={t("Phone")}
                          />)}
                      </Field>
                    </Grid>
                    <Grid item xs={3}>
                      <Field name="contact.mobilePhone">
                        {(fieldProps: FieldProps) => (
                          <StandardTextField
                            {...fieldProps}
                            label={t("MobilePhone")}
                          />)}
                      </Field>
                    </Grid>
                    <Grid item xs={2}>
                      <Field name="contact.fax">
                        {(fieldProps: FieldProps) => (
                          <StandardTextField
                            {...fieldProps}
                            label={t("Fax")}
                          />)}
                      </Field>
                    </Grid>
                    <Grid item xs={4}>
                      <Field name="contact.email">
                        {(fieldProps: FieldProps) => (
                          <StandardTextField
                            {...fieldProps}
                            label={t("Email")}
                          />)}
                      </Field>
                    </Grid>
                    <Grid item xs={4}>
                      <Field name="nip">
                        {(fieldProps: FieldProps) => (
                          <StandardTextField
                            {...fieldProps}
                            label={t("NIP")}
                          />)}
                      </Field>
                    </Grid>
                    <Grid item xs={12}><Typography variant="h6">{t("Detailed data")}</Typography></Grid>
                    <Grid item xs={isCustomer ? 3 : 4}>
                      <Field name="payment.method">
                        {(fieldProps: FieldProps) => (
                          <SelectFormField
                            {...fieldProps}
                            label={t("Payment method")}
                            options={(Object.keys(PaymentMethod) as PaymentMethod[])
                              .map(el => ({ value: el, label: t(el) }))}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={isCustomer ? 3 : 4}>
                      <Field name="payment.downPaymentPercentage">
                        {(fieldProps: FieldProps) => (
                          <StandardTextField
                            {...fieldProps}
                            label={t("Down payment")}
                          />)}
                      </Field>
                    </Grid>
                    {isCustomer && (
                      <Grid item xs={isCustomer ? 3 : 4}>
                        <Field name="payment.discountPercentage">
                          {(fieldProps: FieldProps) => (
                            <StandardTextField
                              {...fieldProps}
                              label={t("Discount")}
                            />)}
                        </Field>
                      </Grid>
                    )}
                    <Grid item xs={isCustomer ? 3 : 4}>
                      <Field name="payment.period">
                        {(fieldProps: FieldProps) => (
                          <StandardTextField
                            {...fieldProps}
                            label={t("Payment period")}
                          />)}
                      </Field>
                    </Grid>
                    {isCustomer && (
                      <Grid item xs={4}>
                        <Field name="acquisitionMethod">
                          {(fieldProps: FieldProps) => (
                            <SelectFormField
                              {...fieldProps}
                              label={t("Acquisition method")}
                              options={(Object.keys(AcquisitionMethod) as AcquisitionMethod[]).map(el => ({ value: el, label: t(`acquisitionMethods:${el}`) }))}
                            />
                          )}
                        </Field>
                      </Grid>
                    )}
                    <Grid item xs={isCustomer ? 8 : 12}>
                      <Field name="comments">
                        {(fieldProps: FieldProps) => (
                          <StandardTextField
                            {...fieldProps}
                            multiline
                            label={t("Comments")}
                          />)}
                      </Field>
                    </Grid>
                    <FieldArray name="additionalContacts">
                      {arrayHelpers => (
                        <Fragment>
                          <Grid item xs={12}>
                            <div className={classes.contactContainer}>
                              <Typography variant="h6">{t("Additional contacts")}</Typography>
                              <IconButton
                                className={classes.addButton} aria-label="Add"
                                onClick={() => arrayHelpers.push({
                                  name: "",
                                  phone: "",
                                  email: "",
                                  comments: ""
                                })}
                              >
                                <AddCircle />
                              </IconButton>
                            </div>
                          </Grid>
                          {props.values.additionalContacts.map((additionalContact, index) => (
                            <Fragment key={index}>
                              <Grid item xs={12}>
                                <div className={classes.contactContainer}>
                                  <Typography className={classes.contactLabel} variant="body1">
                                    {`${t("Contact person")} #${index + 1}`}
                                  </Typography>
                                  <IconButton
                                    className={classes.deleteButton} aria-label="Delete"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <Delete />
                                  </IconButton>
                                </div>
                              </Grid>
                              <Grid item xs={4}>
                                <Field name={`additionalContacts[${index}].name`}>
                                  {(fieldProps: FieldProps<AdditionalContact>) => (
                                    <StandardTextField<AdditionalContact>
                                      {...fieldProps}
                                      required
                                      label={t("ACName")}
                                    />)}
                                </Field>
                              </Grid>
                              <Grid item xs={4}>
                                <Field name={`additionalContacts[${index}].phone`}>
                                  {(fieldProps: FieldProps<AdditionalContact>) => (
                                    <StandardTextField<AdditionalContact>
                                      {...fieldProps}
                                      label={t("ACPhone")}
                                    />)}
                                </Field>
                              </Grid>
                              <Grid item xs={4}>
                                <Field name={`additionalContacts[${index}].email`}>
                                  {(fieldProps: FieldProps<AdditionalContact>) => (
                                    <StandardTextField<AdditionalContact>
                                      {...fieldProps}
                                      label={t("ACEmail")}
                                    />)}
                                </Field>
                              </Grid>

                              <Grid item xs={12}>
                                <Field name={`additionalContacts[${index}].comments`}>
                                  {(fieldProps: FieldProps<AdditionalContact>) => (
                                    <StandardTextField<AdditionalContact>
                                      {...fieldProps}
                                      label={t("ACComments")}
                                    />)}
                                </Field>
                              </Grid>
                            </Fragment>
                          ))}
                        </Fragment>
                      )}
                    </FieldArray>
                  </Grid>
                  <StickySubmit />
                </Form>
              }
              }
            </Formik>
          </Paper>
        }
      </Fragment>
    )
  }

export default CounterpartiesEditForm
