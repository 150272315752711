import React, { Fragment, useCallback } from "react"
import counterpartiesApi from "api/counterparties/counterpartiesApi"
import { Counterparty, EditCounterpartyRequest } from "api/counterparties/models"
import CounterpartiesEditForm from "./editForm/CounterpartiesEditForm"
import { useTranslation } from "react-i18next"
import { useGetDetails } from "hooks/useGetDetails"
import { useHistory, useParams } from "react-router-dom"
import { useSubmit } from "hooks/useSubmit"
import useQueryParam from "hooks/useQueryParam"

const CounterpartiesEdit: React.FC = () => {
  const { t } = useTranslation("api")
  const history = useHistory()
  const { id } = useParams()

  const editedCounterparty = useGetDetails<Counterparty>(counterpartiesApi.getCounterparty, t("Error getting data"))

  const referrer = useQueryParam("referrer")
  const editSuccessAction = useCallback(
    () => history.push(referrer || "/sales/counterparties"),
    [history, referrer]
  )
  const submitAction = useCallback((formData: EditCounterpartyRequest) => counterpartiesApi.editCounterparty(+id, formData), [id])
  const onSubmit = useSubmit(
    submitAction,
    t("Form saved"),
    t("Error saving data"),
    editSuccessAction
  )

  return (
    <Fragment>
      {editedCounterparty &&
        <CounterpartiesEditForm
          counterpartyData={editedCounterparty}
          onSubmit={onSubmit}
        />
      }
    </Fragment>
  )
}

export default CounterpartiesEdit