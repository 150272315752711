import { Permission } from "api/user/models"
import { useContext } from "react"
import UserContext from "state/UserContext"

export const usePermissions = (): ((permissions: Permission[] | undefined) => boolean) => {
  const value = useContext(UserContext)
  if (value === null) {
    throw new Error("usePermissions cannot be used outside UserContext.Provider")
  }
  const userPermissions = value.permissions
  const authorize = (permissions: Permission[] | undefined) =>
    permissions === undefined ||
    permissions.filter((p) => userPermissions.includes(p)).length === permissions.length
  return authorize
}
