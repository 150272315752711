import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  mainContainer: {
    maxWidth: "1000px",
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(3)
  },
  name: {
    borderBottom: `${theme.palette.grey[600]} ${theme.spacing(0.125)}px solid`,
    paddingBottom: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
  },
  nameSubtitle: {
    padding: theme.spacing(0.25)
  },
  label: {
    paddingRight: theme.spacing(1),
    color: theme.palette.grey[600],
  },
  section: {
    paddingTop: theme.spacing(2)
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
  chip: {
    margin: theme.spacing(1),
  },
  icon: {
    padding: theme.spacing(0.25),
  },
  additionalContact: {
    paddingBottom: theme.spacing(2),
  },
  editButton: {
    padding: theme.spacing(0.5),
    marginTop: theme.spacing(-0.5),
    marginLeft: theme.spacing(1),
  },
}))

export default useStyles