import React from "react"
import { HeadRow, TableData } from "components/dataTable/DataTable"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { Offer } from "api/offers/models"
import offerExecutionApi from "api/offerExecution/offerExecutionApi"
import OfferExecutionTab from "./OfferExecutionTab"

export interface Data extends TableData {
  measurementUntil: string
  measurementUntilComments: string
  client: string
  constructionAddress: string
  installationsDays: string
  assemblersCount: string
  clientWishDate: string
  constructionType: string
}

const MeasurementUntil: React.FC = () => {
  const { t } = useTranslation(["offers", "general", "offers.constructionType"])

  const headRows: HeadRow<Data>[] = [
    { id: "id", numeric: false, disablePadding: false, label: t("ID") },
    { id: "measurementUntil", numeric: false, disablePadding: false, label: t("Measurement until") },
    {
      id: "measurementUntilComments",
      numeric: false,
      disablePadding: false,
      label: `${t("Comments")}`,
    },
    { id: "client", numeric: false, disablePadding: false, label: t("Client") },
    {
      id: "constructionAddress",
      numeric: false,
      disablePadding: false,
      label: t("Construction address"),
    },
    { id: "installationsDays", numeric: false, disablePadding: false, label: t("Installation days") },
    { id: "assemblersCount", numeric: false, disablePadding: false, label: t("Assemblers count") },
    { id: "clientWishDate", numeric: false, disablePadding: false, label: t("Client wish date") },
    { id: "constructionType", numeric: false, disablePadding: false, label: t("Construction type") },
  ]

  const mapRows: (data: Offer[]) => Data[] = (data) =>
    data.map((r) => ({
      id: r.id,
      measurementUntil: moment(r.measurement?.measurementUntil?.date ?? "").format(
        t("DateFormat", { ns: "general" })
      ),
      measurementUntilComments: r.measurement?.measurementUntil?.comments ?? "",
      client: `${r.client.name}, ${r.client.address.city}, ${t(`${r.client.address.country}`, {
        ns: "general",
      })}`,
      constructionAddress: r.construction.address ?? "",
      installationsDays: r.installation?.days?.toString() ?? "",
      assemblersCount: r.installation?.assemblersCount?.toString() ?? "",
      clientWishDate: moment(r.creationDate).format(t("DateFormat", { ns: "general" })),
      constructionType: t(`offers.constructionType:${r.construction.type}`),
    }))

  return (
    <OfferExecutionTab
      headRows={headRows}
      mapRows={mapRows}
      getData={offerExecutionApi.getMeasurementUntil}
    />
  )
}

export default MeasurementUntil
