import React, { useMemo, useCallback } from "react"
import DataTable, { HeadRow, TableData } from "components/dataTable/DataTable"
import counterpartiesApi from "api/counterparties/counterpartiesApi"
import { Address, Role, LegalForm } from "api/counterparties/models"
import FiltersDrawer from "components/filters/FiltersDrawer"
import Filters from "components/filters/Filters"
import filtersConfig, { FiltersModel } from "views/sales/counterparties/filters/FiltersConfig"
import Pills from "components/filters/Pills"
import { useTranslation } from "react-i18next"
import { getTranslation } from "i18n"
import { useList } from "hooks/useList"
import { useFilters } from "hooks/useFilters"
import { getFilterSerializers } from "components/filters/utils"
import useRowDelete from "hooks/useRowDelete"
import { useHistory } from "react-router-dom"
import { usePermissions } from "hooks/usePermissions"

export interface Data extends TableData {
  name: string
  roles: string
  address: string
  phone: string
  mobile: string
  email: string
  nip: string
}

const formatAddress = (address: Address) => {
  const number = address.apartmentNumber
    ? `${address.houseNumber}/${address.apartmentNumber}`
    : address.houseNumber
  return `${address.street} ${number}, ${address.postalCode} ${address.city}, ${getTranslation(
    "general",
    address.country
  )}`
}

const serializers = getFilterSerializers(filtersConfig)

const CounterpartiesList: React.FC = () => {
  const { t } = useTranslation("counterparties.edit")
  const {
    filterDrawerOpened,
    onFilterChange,
    toggleFilterDrawer,
    filters,
    serializedFilters,
    onFilterRemove,
  } = useFilters<FiltersModel>({ role: [], legalForm: [] }, serializers)
  const history = useHistory()

  const { pageData, getList, data, setPageSize, setPageNumber, onSearch } = useList(
    serializedFilters,
    counterpartiesApi.getCounterpartyList
  )

  const deleteRowAction = useRowDelete(counterpartiesApi.deleteCounterparty, getList)
  const addRowAction = () => history.push("/sales/counterparties/add")
  const rowDetailsAction = (rowId: string) => history.push(`/sales/counterparties/${rowId}/details`)
  const editRowAction = (rowId: string) => history.push(`/sales/counterparties/${rowId}/edit`)
  const valueTranslator = useCallback((label) => t(label), [t])
  const authorize = usePermissions()

  const filterLabels = useMemo(
    () => ({
      role: t("Role"),
      legalForm: t("Legal form"),
    }),
    [t]
  )

  const availableFilters = useMemo(
    () => ({
      role: (Object.keys(Role) as Role[]).map((v) => ({ label: v, value: v })),
      legalForm: (Object.keys(LegalForm) as LegalForm[]).map((v) => ({ label: v, value: v })),
    }),
    []
  )

  const headRows: HeadRow<Data>[] = useMemo(
    () => [
      { id: "id", numeric: false, disablePadding: false, label: "ID" },
      { id: "name", numeric: false, disablePadding: false, label: t("Name") },
      { id: "roles", numeric: false, disablePadding: false, label: t("Role") },
      { id: "address", numeric: false, disablePadding: false, label: t("Address data") },
      { id: "phone", numeric: false, disablePadding: false, label: t("Phone") },
      { id: "mobile", numeric: false, disablePadding: false, label: t("MobilePhone") },
      { id: "email", numeric: false, disablePadding: false, label: t("Email") },
      { id: "nip", numeric: false, disablePadding: false, label: t("NIP") },
    ],
    [t]
  )

  const rows =
    data &&
    data.map((row) => {
      return {
        id: row.id,
        name: row.name,
        roles: row.roles.map((role) => t(role)).join(", "),
        address: formatAddress(row.address),
        phone: row.contact.phone,
        mobile: row.contact.mobilePhone,
        email: row.contact.email,
        nip: row.nip,
      }
    })

  return (
    <React.Fragment>
      <Pills
        selectedFilters={filters}
        filtersConfig={filtersConfig}
        onRemove={onFilterRemove}
        filtersLabels={filterLabels}
        valueTranslator={valueTranslator}
      />
      <FiltersDrawer opened={filterDrawerOpened} onClose={toggleFilterDrawer}>
        <Filters
          selectedFilters={filters}
          availableFilters={availableFilters}
          onFilterChange={onFilterChange}
          filtersConfig={filtersConfig}
          filtersLabels={filterLabels}
          valueTranslator={valueTranslator}
        />
      </FiltersDrawer>
      <DataTable
        rows={rows}
        headRows={headRows}
        addRowAction={authorize(["COUNTERPARTIES_WRITE"]) ? addRowAction : undefined}
        rowDetailsAction={rowDetailsAction}
        editRowAction={authorize(["COUNTERPARTIES_WRITE"]) ? editRowAction : undefined}
        pageData={pageData}
        setPageNumber={setPageNumber}
        setPageLimit={setPageSize}
        deleteRowAction={authorize(["COUNTERPARTIES_WRITE"]) ? deleteRowAction : undefined}
        onFiltersToggle={toggleFilterDrawer}
        onSearch={onSearch}
      />
    </React.Fragment>
  )
}

export default CounterpartiesList
