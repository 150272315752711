import {
  GetCounterpartyResponse,
  CreateCounterpartyResponse,
  CreateCounterpartyRequest,
  EditCounterpartyResponse,
  EditCounterpartyRequest,
  DeleteCounterpartyResponse,
  Counterparty,
  Role,
} from "./models"
import Axios from "axios"
import { ListModel, EditSubmit, ListPage } from "api/common/models"
import pickBy from "lodash/pickBy"
import identity from "lodash/identity"

interface CounterpartiesApi {
  getCounterparty: (id: number) => Promise<GetCounterpartyResponse>
  createCounterparty: (data: CreateCounterpartyRequest) => Promise<CreateCounterpartyResponse>
  editCounterparty: EditSubmit<EditCounterpartyRequest, EditCounterpartyResponse>
  getCounterpartyList: (
    page: number,
    limit: number,
    filters: { [key: string]: string },
    searchPhrase: string
  ) => Promise<ListModel<Counterparty>>
  getAllClients: () => Promise<Counterparty[]>
  getAllSuppliers: () => Promise<Counterparty[]>
  deleteCounterparty: (id: string) => Promise<DeleteCounterpartyResponse>
}

const counterpartiesApi: CounterpartiesApi = {
  getCounterparty: (id) =>
    Axios.get<GetCounterpartyResponse>(`/api/v1/counterparties/${id}`).then((response) => response.data),
  createCounterparty: (data) =>
    Axios.post<CreateCounterpartyResponse>("/api/v1/counterparties", data).then((response) => response.data),
  editCounterparty: (id, data) =>
    Axios.put<EditCounterpartyResponse>(`/api/v1/counterparties/${id}`, data).then(
      (response) => response.data
    ),
  getCounterpartyList: async (page, limit, filters, searchPhrase) => {
    const params = pickBy(
      { page: page.toString(10), limit: limit.toString(10), ...filters, searchPhrase },
      identity
    )
    const urlParams = new URLSearchParams(params)
    const { data } = await Axios.get<{ result: Counterparty[]; page: ListPage }>(
      `/api/v1/counterparties?${urlParams.toString()}`
    )
    return { data: data.result, page: data.page }
  },
  getAllClients: async () => {
    const urlParams = new URLSearchParams({
      limit: "9999",
      role: Role.CUSTOMER,
    })
    const {
      data: { result },
    } = await Axios.get<{ result: Counterparty[] }>(`/api/v1/counterparties?${urlParams.toString()}`)
    return result
  },
  getAllSuppliers: async () => {
    const urlParams = new URLSearchParams({
      limit: "9999",
      role: Role.SUPPLIER,
    })
    const {
      data: { result },
    } = await Axios.get<{ result: Counterparty[] }>(`/api/v1/counterparties?${urlParams.toString()}`)
    return result
  },
  deleteCounterparty: (id) =>
    Axios.delete<DeleteCounterpartyResponse>(`/api/v1/counterparties/${id}`).then(
      (response) => response.data
    ),
}

export default counterpartiesApi
