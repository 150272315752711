import React, { Fragment } from "react"
import counterpartiesApi from "api/counterparties/counterpartiesApi"
import { Counterparty, Address, Contact, Payment, Role, AdditionalContact } from "api/counterparties/models"
import { Paper, Typography, Grid, Chip } from "@material-ui/core"
import LocalShipping from "@material-ui/icons/LocalShippingOutlined"
import LocalGroceryStore from "@material-ui/icons/LocalGroceryStoreOutlined"
import useStyles from "./CounterpartiesDetails.styles"
import { useTranslation } from "react-i18next"
import omit from "lodash/omit"
import DetailField from "components/DetailField"
import { useGetDetails } from "hooks/useGetDetails"
import EditIcon from "@material-ui/icons/Edit"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import { Link, useLocation } from "react-router-dom"
import Authorize from "components/Authorize"

const addressKeysTranslations: { [key in keyof Address]: [string, boolean] } = {
  city: ["City", false],
  apartmentNumber: ["Apartment", false],
  country: ["Country", true],
  houseNumber: ["House", false],
  postalCode: ["Zip Code", false],
  street: ["Street", false],
}

const contactKeysTranslations: { [key in keyof Contact]: string } = {
  email: "Email",
  fax: "Fax",
  phone: "Phone",
  mobilePhone: "MobilePhone",
}

type DetailedData = keyof Payment | "comments" | "nip"

const detailedDataKeysTranslations: { [key in DetailedData]: [string, boolean] } = {
  comments: ["Comments", false],
  discountPercentage: ["Discount", false],
  downPaymentPercentage: ["Down payment", false],
  method: ["Payment method", true],
  nip: ["NIP", false],
  period: ["Payment period", false],
}

const acKeysTranslations: { [key in keyof AdditionalContact]: string } = {
  name: "ACName",
  comments: "ACComments",
  email: "ACEmail",
  phone: "Phone",
}

const getFilteredDetailedData = (isCustomer: boolean, counterparty: Counterparty) => {
  const payment = counterparty.payment
  const { discountPercentage, ...filteredPayment } = payment

  if (isCustomer && counterparty !== undefined) {
    return {
      ...counterparty.payment,
      nip: counterparty.nip,
      comments: counterparty.comments,
    }
  }
  return {
    ...filteredPayment,
    nip: counterparty.nip,
    comments: counterparty.comments,
  }
}

const CounterpartiesDetails: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation(["api", "general", "acquisitionMethods"])
  const { t: tc } = useTranslation("counterparties.edit")
  const errorMessage = t("Error getting data")
  const counterparty = useGetDetails<Counterparty>(counterpartiesApi.getCounterparty, errorMessage)
  const isCustomer = counterparty !== undefined && counterparty.roles.some((role) => role === Role.CUSTOMER)
  const { pathname } = useLocation()

  return (
    <Fragment>
      {counterparty && (
        <div className={classes.mainContainer}>
          <Paper className={classes.paper}>
            <Typography variant="body1" component="div" className={classes.nameSubtitle}>
              {tc(counterparty.honorific)}
            </Typography>
            <Typography variant="h4" component="div" className={classes.name}>
              {counterparty.name}
              <Authorize permissions={["COUNTERPARTIES_WRITE"]}>
                <Tooltip title={tc("Edit counterparty") || ""}>
                  <Link
                    to={`/sales/counterparties/${counterparty.id}/edit?referrer=${encodeURIComponent(
                      pathname
                    )}`}
                  >
                    <IconButton
                      className={classes.editButton}
                      aria-label={tc("Edit counterparty")}
                      component="span"
                    >
                      <EditIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
              </Authorize>
            </Typography>
            <div className={classes.nameSubtitle}>
              <Typography variant="body2" component="span" className={classes.label}>
                {tc("Legal form")}:
              </Typography>
              <Typography variant="body1" component="span">
                {tc(counterparty.legalForm)}
              </Typography>
            </div>
            {counterparty.roles.map((role) => (
              <Chip
                key={role}
                icon={
                  role === Role.SUPPLIER ? (
                    <LocalShipping className={classes.icon} />
                  ) : (
                    <LocalGroceryStore className={classes.icon} />
                  )
                }
                label={tc(role)}
                className={classes.chip}
              />
            ))}
            <Grid container spacing={1} justifyContent="space-between">
              <Grid item xs={12} sm={6} md={4}>
                <div className={classes.section}>
                  <Typography variant="h6" component="div" className={classes.title}>
                    {tc("Address data")}
                  </Typography>
                  {Object.entries(omit(counterparty.address, ["country"])).map(([key, value]) => (
                    <DetailField
                      key={key}
                      label={tc(addressKeysTranslations[key as keyof Address][0])}
                      value={
                        addressKeysTranslations[key as keyof Address][1]
                          ? tc(value.toString())
                          : value.toString()
                      }
                    />
                  ))}
                  <DetailField
                    key="country"
                    label={tc(`Country`)}
                    value={t(`general:${counterparty.address.country.toString()}`)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className={classes.section}>
                  <Typography variant="h6" component="div" className={classes.title}>
                    {tc("Contact data")}
                  </Typography>
                  {Object.entries(counterparty.contact).map(([key, value]) => (
                    <DetailField
                      key={key}
                      label={tc(contactKeysTranslations[key as keyof Contact])}
                      value={value !== null ? value.toString() : ""}
                    />
                  ))}
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className={classes.section}>
                  <Typography variant="h6" component="div" className={classes.title}>
                    {tc("Detailed data")}
                  </Typography>
                  {Object.entries(getFilteredDetailedData(isCustomer, counterparty)).map(([key, value]) => (
                    <DetailField
                      key={key}
                      label={tc(detailedDataKeysTranslations[key as DetailedData][0])}
                      value={
                        value !== undefined
                          ? detailedDataKeysTranslations[key as DetailedData][1]
                            ? tc(value.toString())
                            : value.toString()
                          : ""
                      }
                    />
                  ))}
                  <DetailField
                    label={tc("Acquisition method")}
                    value={t(`acquisitionMethods:${counterparty.acquisitionMethod}`)}
                  />
                </div>
              </Grid>

              {counterparty.additionalContacts && counterparty.additionalContacts.length > 0 && (
                <Grid item xs={12}>
                  <div className={classes.section}>
                    <Typography variant="h6" component="div" className={classes.title}>
                      {tc("Additional contacts")}
                    </Typography>
                  </div>
                  {counterparty.additionalContacts.map((ac, index) => (
                    <div key={index} className={classes.additionalContact}>
                      <Typography variant="body1" component="div" className={classes.title}>{`${tc(
                        "Contact person"
                      )} #${index + 1}`}</Typography>
                      {Object.entries(ac).map(([key, value]) => (
                        <DetailField
                          key={key}
                          label={tc(acKeysTranslations[key as keyof AdditionalContact])}
                          value={value}
                        />
                      ))}
                    </div>
                  ))}
                </Grid>
              )}
            </Grid>
          </Paper>
        </div>
      )}
    </Fragment>
  )
}

export default CounterpartiesDetails
