import React, { useCallback } from "react"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { OfferStatus, ConstructionType, AcquisitionMethod, DeliveryPlaceType } from "api/offers/models"
import OfferEditForm, { OfferEditData } from "./editForm/OfferEditForm"
import offersApi from "api/offers/offersApi"
import moment from "moment"
import { useConcurrentLoading } from "hooks/useConcurrentLoading"
import { useGetAll } from "hooks/useGetAll"
import counterpartiesApi from "api/counterparties/counterpartiesApi"
import dealersApi from "api/dealers/dealersApi"
import { useSubmit } from "hooks/useSubmit"
import filesApi from "api/files/filesApi"
import useQueryParam from "hooks/useQueryParam"
import productsApi from "api/products/productsApi"

const emptyOffer: OfferEditData = {
  status: OfferStatus.OPENED,
  priority: 1,
  construction: {
    type: ConstructionType.NEW
  },
  dealer: null,
  client: null,
  offerNumber: null,
  creationDate: moment().toISOString(),
  amounts: {
    offerAmount: "0",
  },
  clientWishDate: null,
  acquisitionMethod: AcquisitionMethod.RECOMMENDATION,
  comments: null,
  installation: null,
  offerProducts: null,
  deliveryPlace: {
    type: DeliveryPlaceType.CONSTRUCTION
  },
  withMeasurement: false
}

const OfferAdd: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation("api")
  const onLoading = useConcurrentLoading(4)
  const clients = useGetAll(counterpartiesApi.getAllClients, t("Error getting clients"), onLoading)
  const dealers = useGetAll(dealersApi.getAllDealers, t("Error getting dealers"), onLoading)
  const products = useGetAll(productsApi.getAllProducts, t("Error getting products"), onLoading)
  const suppliers = useGetAll(counterpartiesApi.getAllSuppliers, t("Error getting suppliers"), onLoading)
  const additionSuccessAction = useCallback(() => history.push("/sales/offers"), [history])
  const onSubmit = useSubmit(
    offersApi.createOffer,
    t("Form saved"),
    t("Error saving data"),
    additionSuccessAction
  )
  const counterpartyId = useQueryParam("counterpartyId")
  const client = (counterpartyId && clients) ? clients.find(c => c.id === counterpartyId) : undefined
  const offerData = client ? { ...emptyOffer, client } : emptyOffer
  return (
    <OfferEditForm
      offerData={offerData}
      clients={clients}
      dealers={dealers}
      products={products}
      suppliers={suppliers}
      onSubmit={onSubmit}
      addFileApi={filesApi.addFile}
    />
  )
}

export default OfferAdd
