import Axios from "axios"
import { Role } from "./models"

interface RolesApi {
  getAllRoles: () => Promise<Role[]>
}

const rolesApi: RolesApi = {
  getAllRoles: async () => {
    const { data } = await Axios.get<Role[]>("/api/v1/roles")
    return data
  },
}

export default rolesApi
