import React, { useState, Fragment, useEffect, useCallback } from "react"
import CssBaseline from "@material-ui/core/CssBaseline"
import { Route, Switch, Redirect, withRouter, RouteComponentProps } from "react-router-dom"
import Menu from "components/menu/Menu"
import TopBar from "components/topBar/TopBar"
import useStyles from "./App.styles"
import routesConfig from "config/RoutesConfig"
import menuConfig from "config/MenuConfig"
import LoginForm from "components/loginForm/LoginForm"
import authApi from "api/auth/authApi"
import UserContext from "state/UserContext"
import { LinearProgress, Snackbar } from "@material-ui/core"
import SnackbarWrapper, { SnackbarVariant } from "components/snackbar/SnackbarWrapper"
import FeedbackContext from "state/FeedbackContext"
import userApi from "api/user/userApi"
import { UserProfile } from "api/user/models"
import Cookies from "js-cookie"

const App: React.FC<RouteComponentProps> = ({ location: { pathname } }) => {
  const classes = useStyles()
  const [menuOpen, setMenuOpen] = useState(true)
  const [loading, setLoading] = useState(false)
  const [initLoading, setInitLoading] = useState(true)
  const [user, setUser] = useState<UserProfile | null>(null)
  const [snackbarState, setSnackbarState] = useState<{
    open: boolean
    variant: SnackbarVariant
    message: string
  }>({ open: false, variant: "success", message: "" })
  const onMenuToggle = () => setMenuOpen(!menuOpen)
  useEffect(() => {
    async function getUserProfile() {
      const user = await userApi.getUserProfile()
      setUser(user)
      setInitLoading(false)
    }
    if (pathname !== "/login" || !Cookies.get("XSRF-TOKEN")) {
      getUserProfile()
    } else {
      setInitLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const onLogout = () => {
    async function postLogout() {
      setLoading(true)
      try {
        await authApi.postLogout()
      } finally {
        window.location.href = "/login"
      }
    }
    postLogout()
  }
  const showSnackbar = useCallback(
    (variant: SnackbarVariant, message: string) => {
      setSnackbarState({ open: true, variant, message })
    },
    [setSnackbarState]
  )
  const closeSnackbar = () => {
    setSnackbarState({ ...snackbarState, open: false })
  }

  return (
    <UserContext.Provider value={user}>
      <FeedbackContext.Provider value={{ showSnackbar, setLoading }}>
        <div className={classes.root}>
          <CssBaseline />
          {loading && <LinearProgress className={classes.progress} />}
          {!initLoading && (
            <Switch>
              <Route path="/login">{!user ? <LoginForm /> : <Redirect to="/sales/counterparties" />}</Route>
              {routesConfig.map((route) => (
                <Route exact key={route.path} path={route.path}>
                  <Fragment>
                    <TopBar
                      menuOpen={menuOpen}
                      onMenuToggle={onMenuToggle}
                      userName={user ? `${user.firstName} ${user.lastName} (${user.login})` : ""}
                      onLogout={onLogout}
                    />
                    <Menu open={menuOpen} onMenuToggle={onMenuToggle} menuConfig={menuConfig} />
                    <div className={classes.viewContainer}>{route.component}</div>
                  </Fragment>
                </Route>
              ))}
              <Route exact path="/">
                <Redirect to="/sales/counterparties" />
              </Route>
              <Route>
                <Redirect to="/sales/counterparties" />
              </Route>
            </Switch>
          )}
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbarState.open}
            autoHideDuration={5000}
            onClose={closeSnackbar}
          >
            <SnackbarWrapper
              onClose={closeSnackbar}
              variant={snackbarState.variant}
              message={snackbarState.message}
            />
          </Snackbar>
        </div>
      </FeedbackContext.Provider>
    </UserContext.Provider>
  )
}

export default withRouter(App)
