import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  progress: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.modal,
  },
  viewContainer: {
    width: "100%",
    marginTop: 64,
  }
}))

export default useStyles
