import React from "react"
import CounterpartiesList from "views/sales/counterparties/CounterpartiesList"
import CounterpartiesEdit from "views/sales/counterparties/CounterpartiesEdit"
import CounterpartiesAdd from "views/sales/counterparties/CounterpartiesAdd"
import CounterpartiesDetails from "views/sales/counterparties/CounterpartiesDetails"
import OffersList from "views/sales/offers/OffersList"
import OfferDetails from "views/sales/offers/OfferDetails"
import OfferEdit from "views/sales/offers/OfferEdit"
import OfferAdd from "views/sales/offers/OfferAdd"
import OfferAcquisition from "views/stats/offerAcquisition/OfferAcquisition"
import Profits from "views/stats/profits/Profits"
import OfferExecution from "views/sales/offerExecution/OfferExecution"
import ProductsList from "views/management/products/ProductsList"
import ProductEdit from "views/management/products/ProductEdit"
import ProductAdd from "views/management/products/ProductAdd"
import MaterialTypesList from "views/management/materialTypes/MaterialTypesList"
import MaterialTypeAdd from "views/management/materialTypes/MaterialTypeAdd"
import MaterialTypeEdit from "views/management/materialTypes/MaterialTypeEdit"
import MountingMaterialsList from "views/stock/mountingMaterials/MountingMaterialsList"
import MountingMaterialEdit from "views/stock/mountingMaterials/MountingMaterialEdit"
import MountingMaterialAdd from "views/stock/mountingMaterials/MountingMaterialAdd"
import MountingMaterialCalculationEdit from "views/sales/offers/calculation/MountingMaterialCalculationEdit"
import MountingMaterialCalculationAdd from "views/sales/offers/calculation/MountingMaterialCalculationAdd"
import UsersList from "views/management/users/UsersList"
import UserAdd from "views/management/users/UserAdd"
import UserEdit from "views/management/users/UserEdit"
import { Permission } from "api/user/models"

interface RouteConfigItem {
  path: string
  component: JSX.Element
  title?: string
  permissions?: Permission[]
}

const routesConfig: RouteConfigItem[] = [
  {
    path: "/sales/counterparties",
    component: <CounterpartiesList />,
    title: "Counterparties",
    permissions: ["COUNTERPARTIES_READ"],
  },
  {
    path: "/sales/counterparties/:id/edit",
    component: <CounterpartiesEdit />,
    title: "Counterparty editing",
    permissions: ["COUNTERPARTIES_WRITE"],
  },
  {
    path: "/sales/counterparties/add",
    component: <CounterpartiesAdd />,
    title: "Counterparty addition",
    permissions: ["COUNTERPARTIES_WRITE"],
  },
  {
    path: "/sales/counterparties/:id/details",
    component: <CounterpartiesDetails />,
    title: "Counterparty details",
    permissions: ["COUNTERPARTIES_READ"],
  },
  {
    path: "/sales/offers",
    component: <OffersList />,
    title: "Offers",
    permissions: ["OFFERS_READ"],
  },
  {
    path: "/sales/offers/:id/details",
    component: <OfferDetails />,
    title: "Offer details",
    permissions: ["OFFERS_READ"],
  },
  {
    path: "/sales/offers/:id/edit",
    component: <OfferEdit />,
    title: "Offer editing",
    permissions: ["OFFERS_WRITE"],
  },
  {
    path: "/sales/offers/add",
    component: <OfferAdd />,
    title: "Offer addition",
    permissions: ["OFFERS_WRITE"],
  },
  {
    path: "/sales/offerExecution/:tab",
    component: <OfferExecution />,
    title: "Offer execution",
    permissions: ["OFFER_EXECUTION_READ"],
  },
  {
    path: "/stats/offerAcquisition",
    component: <OfferAcquisition />,
    title: "Offer acquisition",
    permissions: ["STATS_OFFER_ACQUISITION_READ"],
  },
  {
    path: "/stats/profit",
    component: <Profits />,
    title: "Profits",
    permissions: ["STATS_PROFIT_READ"],
  },
  {
    path: "/management/products",
    component: <ProductsList />,
    title: "Products",
    permissions: ["PRODUCTS_READ"],
  },
  {
    path: "/management/products/add",
    component: <ProductAdd />,
    title: "Product addition",
    permissions: ["PRODUCTS_WRITE"],
  },
  {
    path: "/management/products/:id/edit",
    component: <ProductEdit />,
    title: "Product editing",
    permissions: ["PRODUCTS_WRITE"],
  },
  {
    path: "/management/materialTypes",
    component: <MaterialTypesList />,
    title: "Material types",
    permissions: ["MOUNTING_MATERIAL_TYPES_READ"],
  },
  {
    path: "/management/materialTypes/add",
    component: <MaterialTypeAdd />,
    title: "Material type addition",
    permissions: ["MOUNTING_MATERIAL_TYPES_WRITE"],
  },
  {
    path: "/management/materialTypes/:id/edit",
    component: <MaterialTypeEdit />,
    title: "Material type editing",
    permissions: ["MOUNTING_MATERIAL_TYPES_WRITE"],
  },
  {
    path: "/management/users",
    component: <UsersList />,
    title: "Users",
    permissions: ["ROLES_READ"],
  },
  {
    path: "/management/users/add",
    component: <UserAdd />,
    title: "User addition",
    permissions: ["ROLES_READ"],
  },
  {
    path: "/management/users/:id/edit",
    component: <UserEdit />,
    title: "User editing",
    permissions: ["ROLES_READ"],
  },
  {
    path: "/stock/mountingMaterials",
    component: <MountingMaterialsList />,
    title: "Mounting materials",
    permissions: ["MOUNTING_MATERIALS_READ"],
  },
  {
    path: "/stock/mountingMaterials/add",
    component: <MountingMaterialAdd />,
    title: "Mounting material addition",
    permissions: ["MOUNTING_MATERIALS_WRITE"],
  },
  {
    path: "/stock/mountingMaterials/:id/edit",
    component: <MountingMaterialEdit />,
    title: "Mounting material editing",
    permissions: ["MOUNTING_MATERIALS_WRITE"],
  },
  {
    path: "/sales/offers/:offerId/calculation/:id/edit",
    component: <MountingMaterialCalculationEdit />,
    title: "Mounting material calculation editing",
    permissions: ["CALCULATIONS_WRITE"],
  },
  {
    path: "/sales/offers/calculation/:id/add",
    component: <MountingMaterialCalculationAdd />,
    title: "Adding mounting material calculation",
    permissions: ["CALCULATIONS_WRITE"],
  },
]

export default routesConfig
