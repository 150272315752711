import React, { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { Field, FieldProps } from "formik"
import { DateField, StandardTextField } from "components/forms/FormFields"
import Grid from "@material-ui/core/Grid"

const MeasurementField: React.FC<{
  dateName: string
  dateLabel: string
  commentsName: string
  commentsLabel: string
}> = ({ dateName, dateLabel, commentsName, commentsLabel }) => {
  const { t } = useTranslation("general")
  return (
    <Fragment>
      <Grid item xs={3}>
        <Field name={dateName}>
          {(fieldProps: FieldProps) => (
            <DateField
              {...fieldProps}
              label={dateLabel}
              format={t("DateFormat")}
              clearLabel={t("Clear")}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={9}>
        <Field name={commentsName}>
          {(fieldProps: FieldProps) => (
            <StandardTextField
              {...fieldProps}
              label={commentsLabel}
              multiline
              maxRows="3"
            />
          )}
        </Field>
      </Grid>
    </Fragment>
  )
}

export default MeasurementField
