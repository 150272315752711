import { OfferStatus, OfferProduct } from "./models"

export const getProfit: (offerAmount: string, costs: string) => string = (offerAmount, costs) =>
  (Number(offerAmount) - Number(costs)).toFixed(2).toString()

export const getProfitPercentage: (offerAmount: string, costs: string) => string = (offerAmount, costs) =>
  `${Number(offerAmount) !== 0 ? ((Number(offerAmount) - Number(costs)) / Number(offerAmount) * 100).toFixed(2) : 0} %`

export const getCosts: (products: OfferProduct[] | null | undefined, calculation: string | undefined) => string = (products, calculation) => {
  const productsValue = products ? products.reduce((prev, curr) => 1.0 * prev + 1.0 * (curr.price || 0.0), 0.0) || 0.0 : 0.0
  const calculationValue = calculation ? parseFloat(calculation) : 0.0
  return (productsValue + calculationValue).toString(10)
}

export const colorByOfferStatus: { [key in OfferStatus]: string } = {
  OPENED: "#FF0000",
  IN_PROCESSING: "#FFA500",
  OFFER_READY: "#5D88FF",
  OFFER_AT_CUSTOMER: "#28FF05",
  GET_ORDER: "#298402",
  ENDED_WITHOUT_ORDER: "#888888",
  ENDED_WITH_ORDER: "#FFFFFF",
  TRADE: "#FF33B7",
}

export const colorByOfferField = {
  offerAmount: "#6CB52E",
  costs: "orangered",
  profit: "skyblue",
  profitPercentage: "lightblue"
}