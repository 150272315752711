import React, { Fragment } from "react"
import DataTable, { HeadRow, TableData } from "components/dataTable/DataTable"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { Offer } from "api/offers/models"
import offerExecutionApi from "api/offerExecution/offerExecutionApi"
import PriorityExpansionPanel from "./PriorityExpansionPanel"
import { useHistory, useLocation } from "react-router-dom"
import { useGetAll } from "hooks/useGetAll"
import { makeStyles, Theme, createStyles } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"

export interface Data extends TableData {
  customerConfirmed: string
  customerConfirmedComments: string
  advancePayment: string
  advancePaymentDate: string
  advancePaymentComments: string
  client: string
  constructionAddress: string
  installationsDays: string
  assemblersCount: string
  clientWishDate: string
  constructionType: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: "100%",
    },
  })
)

const Payment: React.FC = () => {
  const { t } = useTranslation(["offers", "general", "offers.constructionType", "api", "offerExecution"])
  const history = useHistory()
  const classes = useStyles()
  const { pathname } = useLocation()

  const headRows: HeadRow<Data>[] = [
    { id: "id", numeric: false, disablePadding: false, label: t("ID") },
    { id: "customerConfirmed", numeric: false, disablePadding: false, label: t("Customer confirmed") },
    {
      id: "customerConfirmedComments",
      numeric: false,
      disablePadding: false,
      label: `${t("Comments")}`,
    },
    {
      id: "advancePayment",
      numeric: false,
      disablePadding: false,
      label: t("Offer with advance payment"),
    },
    {
      id: "advancePaymentDate",
      numeric: false,
      disablePadding: false,
      label: t("Advance payment date"),
    },
    { id: "advancePaymentComments", numeric: false, disablePadding: false, label: `${t("Comments")}` },
    { id: "client", numeric: false, disablePadding: false, label: t("Client") },
    {
      id: "constructionAddress",
      numeric: false,
      disablePadding: false,
      label: t("Construction address"),
    },
    { id: "installationsDays", numeric: false, disablePadding: false, label: t("Installation days") },
    { id: "assemblersCount", numeric: false, disablePadding: false, label: t("Assemblers count") },
    { id: "clientWishDate", numeric: false, disablePadding: false, label: t("Client wish date") },
    { id: "constructionType", numeric: false, disablePadding: false, label: t("Construction type") },
  ]

  const mapRows: (data: Offer[]) => Data[] = (data) =>
    data.map((r) => ({
      id: r.id,
      customerConfirmed: moment(r.measurement?.customerConfirmed?.date ?? "").format(
        t("DateFormat", { ns: "general" })
      ),
      customerConfirmedComments: r.measurement?.customerConfirmed?.comments ?? "",
      advancePayment: r.measurement?.advancePayment?.withAdvancePayment
        ? t("Yes", { ns: "general" })
        : t("No", { ns: "general" }),
      advancePaymentDate: r.measurement?.advancePayment?.date
        ? moment(r.measurement?.advancePayment?.date).format(t("DateFormat", { ns: "general" }))
        : "",
      advancePaymentComments: r.measurement?.advancePayment?.comments ?? "",
      client: `${r.client.name}, ${r.client.address.city}, ${t(`${r.client.address.country}`, {
        ns: "general",
      })}`,
      constructionAddress: r.construction.address ?? "",
      installationsDays: r.installation?.days?.toString() ?? "",
      assemblersCount: r.installation?.assemblersCount?.toString() ?? "",
      clientWishDate: moment(r.creationDate).format(t("DateFormat", { ns: "general" })),
      constructionType: t(`${r.construction.type}`, { ns: "offers.constructionType" }),
    }))
  const rowDetailsAction = (rowId: string) => history.push(`/sales/offers/${rowId}/details`)
  const editRowAction = (rowId: string) =>
    history.push(`/sales/offers/${rowId}/edit/?referrer=${encodeURIComponent(pathname)}`)

  const data = useGetAll(offerExecutionApi.getPayment, t("Error getting data", { ns: "api" }))

  return (
    <Fragment>
      <PriorityExpansionPanel
        urgent={
          <DataTable
            className={classes.table}
            rows={data && mapRows(data.advancePaymentNotPaid)}
            headRows={headRows}
            rowDetailsAction={rowDetailsAction}
            editRowAction={editRowAction}
          />
        }
        urgentHeader={<Typography>{t("Advance payment not paid", { ns: "offerExecution" })}</Typography>}
        important={
          <DataTable
            className={classes.table}
            rows={data && mapRows(data.advancePaymentPaid)}
            headRows={headRows}
            rowDetailsAction={rowDetailsAction}
            editRowAction={editRowAction}
          />
        }
        importantHeader={<Typography>{t("Advance payment paid", { ns: "offerExecution" })}</Typography>}
        normal={
          <DataTable
            className={classes.table}
            rows={data && mapRows(data.offerNotPaid)}
            headRows={headRows}
            rowDetailsAction={rowDetailsAction}
            editRowAction={editRowAction}
          />
        }
        normalHeader={<Typography>{t("No advance payment", { ns: "offerExecution" })}</Typography>}
      />
    </Fragment>
  )
}

export default Payment
