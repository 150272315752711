import { createTheme, ThemeOptions } from "@material-ui/core"
import { orange } from "@material-ui/core/colors"

const theme: ThemeOptions = {
  palette: {
    primary: {
      main: "#6CB52E",
      dark: "#65A92C",
    },
    secondary: orange,
  },
}

const winbrosTheme = createTheme(theme)

export default winbrosTheme
