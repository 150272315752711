import React from "react"
import { makeStyles, Theme, createStyles } from "@material-ui/core"
import classNames from "classnames"

const useStyles = makeStyles<Theme, { color: string }>(theme =>
  createStyles({
    statusCircle: ({ color }) => ({
      backgroundColor: color,
      marginTop: theme.spacing(0.5),
      height: "18px",
      width: "18px",
      borderRadius: "50%",
      display: "inline-block",
      boxShadow: `0 0 ${theme.spacing(0.5)}px rgba(0,0,0,0.6)`
    })
  })
)

const StatusCircle: React.FC<{ color: string, status?: string, className?: string }> = ({ color, status, className }) => {
  const classes = useStyles({ color })
  return (
    <div className={classNames(classes.statusCircle, className)} title={status}></div>
  )
}

export default StatusCircle
