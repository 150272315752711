import { makeStyles, createStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme =>
  createStyles({
    offerHeading: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    statusCircle: {
      margin: `${theme.spacing(0)}px 0 ${-theme.spacing(0.5)}px ${theme.spacing(1)}px`
    },
    priority: {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
      backgroundColor: "lightgray",
      fontSize: theme.typography.body1.fontSize,
      fontWeight: "bold"
    },
    customer: {
      color: "lightgray",
      fontSize: theme.typography.h3.fontSize,
    },
    mainContainer: {
      maxWidth: "1000px",
      padding: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    name: {
      borderBottom: `${theme.palette.grey[600]} ${theme.spacing(0.125)}px solid`,
      paddingBottom: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5)
    },
    label: {
      paddingRight: theme.spacing(1),
      color: theme.palette.grey[600],
    },
    comments: {
      width: "100%",
      marginTop: theme.spacing(1.25),
      marginBottom: theme.spacing(1),
    },
    commentsInput: {
      fontSize: theme.typography.body2.fontSize,
      color: theme.palette.grey[600],
    },
    amountsContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    badge: {
      display: "flex",
      alignItems: "center",
    },
    customerData: {
      padding: `${theme.spacing(0.25)}px 0px ${theme.spacing(0.5)}px ${theme.spacing(2.5)}px`
    },
    fileIcon: {
      paddingRight: theme.spacing(0.25),
    },
    fileLink: {
      display: "flex",
      textDecoration: "none",
      color: theme.palette.text.primary,
      padding: theme.spacing(1),
      "&:hover": {
        textDecoration: "underline",
      }
    },
    editButton: {
      padding: theme.spacing(0.5),
      marginLeft: theme.spacing(1),
    },
    section: {
      paddingTop: theme.spacing(2)
    },
    title: {
      fontWeight: theme.typography.fontWeightMedium,
      borderBottom: "solid 1px",
      marginBottom: theme.spacing(1),
      paddingBottom: theme.spacing(0.25),
    },
    multiValueSection: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    table: {
      minWidth: 0,
    }
  }))

export default useStyles