import React from "react"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import { makeStyles, createStyles } from "@material-ui/core/styles"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: "1000px",
      textAlign: "center",
      position: "fixed",
      bottom: 0,
      height: "50px",
    },
    submit: {
      marginTop: theme.spacing(1),
      width: "150px",
    },
  })
)

const StickySubmit: React.FC = () => {
  const { t } = useTranslation("general")
  const classes = useStyles()
  return (
    <Paper className={classes.container}>
      <Button className={classes.submit} type="submit" variant="contained" fullWidth color="primary">
        {t("Save")}
      </Button>
    </Paper>
  )
}

export default StickySubmit
