import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import i18n from "config/i18nConfig"
import { I18nextProvider } from "react-i18next"
import "config/ApiConfig"
import { ThemeProvider } from "@material-ui/styles"
import winbrosTheme from "config/ThemeConfig"
import MomentUtils from "@date-io/moment"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import Chart from "chart.js"
import "chartjs-plugin-datalabels"

Chart.defaults.global.defaultFontFamily = "Roboto"

Chart.helpers.merge(Chart.defaults.global.plugins?.datalabels, {
  color: "white",
  font: {
    weight: "bold",
    size: "18",
  },
  textShadowColor: "black",
  textShadowBlur: "2"
})

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <BrowserRouter>
        <ThemeProvider theme={winbrosTheme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </MuiPickersUtilsProvider>
  </I18nextProvider>,
  document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
