import { Counterparty } from "api/counterparties/models"
import { Dealer } from "api/dealers/models"
import { UploadedFile } from "api/common/models"
import { Product } from "api/products/models"

export type GetOfferResponse = Offer

export type EditOfferRequest = Omit<Offer, "id" | "client" | "dealer" | "internalFiles" | "customerFiles"> & {
  id?: string
  clientId: string
  dealerId: string,
  internalFiles?: { id: string }[] | null
  customerFiles?: { id: string }[] | null
}

export type CreateOfferRequest = Omit<Offer, "id" | "client" | "dealer" | "internalFiles" | "customerFiles"> & {
  id?: string
  clientId: string
  dealerId: string,
  internalFiles?: { id: string }[] | null
  customerFiles?: { id: string }[] | null
}

export type EditOfferResponse = Offer

export interface CreateOfferResponse {
  id: number
}

export interface GetOfferSummaryStatsResponse {
  count: string;
  totalAmount: string;
  totalProfit: string;
  averageProfit: string;
  averageProfitPercentage: string;
  statusesShare: Record<string, number>;
}

export type GetOfferAcquisitionStatsResponse = Record<string, number>

export enum OfferStatus {
  OPENED = "OPENED", // Offen
  IN_PROCESSING = "IN_PROCESSING", // In Bearbeitung
  OFFER_READY = "OFFER_READY", // Angebot fertig: Termin&Abschluss!
  OFFER_AT_CUSTOMER = "OFFER_AT_CUSTOMER", // Angebot beim Kunden: Abschluss!
  GET_ORDER = "GET_ORDER", // Auftrag holen (Druck!)
  ENDED_WITHOUT_ORDER = "ENDED_WITHOUT_ORDER", // Beendet ohne Auftrag
  ENDED_WITH_ORDER = "ENDED_WITH_ORDER", // Beendet mit Auftrag
  TRADE = "TRADE", // Handel
}

export enum AcquisitionMethod {
  RECOMMENDATION = "RECOMMENDATION",
  GOOGLE = "GOOGLE",
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  CAR = "CAR",
  EXHIBITION = "EXHIBITION",
  BUILDING = "BUILDING",
  FLYER = "FLYER",
  RADIO = "RADIO",
  TV = "TV",
  PRESS = "PRESS",
  EXISTING_CLIENT = "EXISTING_CLIENT",
  CLIENT = "CLIENT",
  WEBSITE = "WEBSITE",
  ARCHITECT = "ARCHITECT",
  OTHER = "OTHER",
}

export enum ConstructionType {
  NEW = "NEW",
  RENOVATION = "RENOVATION"
}

export enum DeliveryPlaceType {
  OFFICE = "OFFICE",
  CONSTRUCTION = "CONSTRUCTION",
  OTHER = "OTHER"
}

export interface DeliveryPlace {
  type: DeliveryPlaceType
  other?: string
}

export interface OfferAmounts {
  offerAmount: string
}

export interface ConstructionSite {
  address?: string | null
  type: ConstructionType
}

export interface MeasurementField {
  date?: string
  comments?: string
}

export interface AdvancePaymentField extends MeasurementField {
  withAdvancePayment: boolean
}

export interface PaymentField {
  paymentCompleted?: boolean
  date?: string
  paymentPlace?: string
}

export interface Measurement {
  measurementUntil?: MeasurementField | null
  measurementDate?: MeasurementField | null
  orderInProgress?: MeasurementField | null
  sentToCustomer?: MeasurementField | null
  customerConfirmed?: MeasurementField | null
  orderChecked?: MeasurementField | null
  advancePayment?: AdvancePaymentField | null
  payment?: PaymentField | null
}

export interface Installation {
  days?: number | null
  assemblersCount?: number | null
}

export interface Offer {
  id: string
  status: OfferStatus
  dealer: Dealer
  priority: number
  client: Counterparty
  construction: ConstructionSite
  offerNumber?: string | null
  creationDate: string
  amounts: OfferAmounts
  clientWishDate?: string | null
  acquisitionMethod: AcquisitionMethod
  comments?: string | null
  installation?: Installation | null
  deliveryPlace: DeliveryPlace
  withMeasurement: boolean
  measurement?: Measurement | null
  internalFiles?: UploadedFile[] | null
  customerFiles?: UploadedFile[] | null
  offerProducts?: OfferProduct[] | null
  calculation?: OfferCalculation | null
}

export interface OfferProduct {
  id: string
  product: Product
  supplier: Counterparty
  quantity?: number | null
  runningMeters?: number | null
  price?: number | null
  withInstallation: boolean
}

export interface OfferCalculation {
  amount: string
  id: string
}

export interface DeleteOfferResponse {
  id: string
}
