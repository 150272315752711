import React, { useMemo, useCallback } from "react"
import DataTable, { HeadRow, TableData } from "components/dataTable/DataTable"
import { useTranslation } from "react-i18next"
import { useList } from "hooks/useList"
import { TableCell } from "@material-ui/core"
import offersApi from "api/offers/offersApi"
import { useFilters } from "hooks/useFilters"
import FiltersDrawer from "components/filters/FiltersDrawer"
import Filters from "components/filters/Filters"
import filtersConfig, { FiltersModel } from "./filters/FiltersConfig"
import Pills from "components/filters/Pills"
import { ConstructionType, OfferStatus } from "api/offers/models"
import { getFilterSerializers } from "components/filters/utils"
import moment from "moment"
import StatusCircle from "components/StatusCircle"
import { colorByOfferStatus } from "api/offers/utils"
import dealersApi from "api/dealers/dealersApi"
import { useGetAll } from "hooks/useGetAll"
import { useHistory } from "react-router-dom"
import { getCounterpartyShortName } from "api/counterparties/models"
import { usePermissions } from "hooks/usePermissions"

export interface Data extends TableData {
  status: OfferStatus
  dealer: string
  priority: string
  client: string
  constructionAddress: string
  offerNumber: string
  creationDate: string
}

const serializers = getFilterSerializers(filtersConfig)

const OffersList: React.FC = () => {
  const { t } = useTranslation(["offers", "general", "api"])
  const {
    filterDrawerOpened,
    onFilterChange,
    toggleFilterDrawer,
    filters,
    serializedFilters,
    onFilterRemove,
  } = useFilters<FiltersModel>(
    { constructionType: [], dealer: [], status: [], creationDate: [] },
    serializers
  )
  const history = useHistory()

  const dealers = useGetAll(dealersApi.getAllDealers, t("Error getting dealers", { ns: "api" }))

  const addRowAction = () => history.push("/sales/offers/add")
  const rowDetailsAction = (rowId: string) => history.push(`/sales/offers/${rowId}/details`)
  const editRowAction = (rowId: string) => history.push(`/sales/offers/${rowId}/edit`)
  const valueTranslator = useCallback((label) => t(label), [t])
  const authorize = usePermissions()

  const filterLabels = useMemo(
    () => ({
      constructionType: t("Construction type"),
      dealer: t("Dealer"),
      status: t("Status"),
      creationDate: t("Creation date"),
    }),
    [t]
  )

  const availableFilters = useMemo(
    () => ({
      constructionType: (Object.keys(ConstructionType) as ConstructionType[]).map((v) => ({
        label: v,
        value: v,
      })),
      dealer: dealers?.map((d) => ({ label: d.name, value: d.id })) ?? [],
      status: (Object.keys(OfferStatus) as OfferStatus[]).map((v) => ({ label: v, value: v })),
      creationDate: [],
    }),
    [dealers]
  )

  const { pageData, data, setPageSize, setPageNumber, onSearch } = useList(
    serializedFilters,
    offersApi.getOfferList
  )

  const headRows: HeadRow<Data>[] = useMemo(
    () => [
      { id: "status", numeric: false, disablePadding: false, label: t("Status") },
      { id: "id", numeric: false, disablePadding: false, label: t("ID") },
      { id: "dealer", numeric: false, disablePadding: false, label: t("Dealer") },
      { id: "priority", numeric: false, disablePadding: false, label: t("Priority") },
      { id: "client", numeric: false, disablePadding: false, label: t("Client") },
      { id: "constructionAddress", numeric: false, disablePadding: false, label: t("Construction address") },
      { id: "offerNumber", numeric: false, disablePadding: false, label: t("Offer number") },
      { id: "creationDate", numeric: false, disablePadding: false, label: t("Creation date") },
    ],
    [t]
  )

  const rows: Data[] | undefined =
    data &&
    data.map((row) => ({
      status: row.status,
      id: row.id,
      dealer: row.dealer.name,
      priority: row.priority.toString(10),
      client: getCounterpartyShortName(row.client),
      constructionAddress: row.construction.address || "",
      offerNumber: row.offerNumber || "",
      creationDate: moment(row.creationDate).format(t("DateFormat", { ns: "general" })),
    }))

  return (
    <React.Fragment>
      <Pills
        selectedFilters={filters}
        filtersConfig={filtersConfig}
        onRemove={onFilterRemove}
        filtersLabels={filterLabels}
        valueTranslator={valueTranslator}
      />
      <FiltersDrawer opened={filterDrawerOpened} onClose={toggleFilterDrawer}>
        <Filters
          selectedFilters={filters}
          availableFilters={availableFilters}
          onFilterChange={onFilterChange}
          filtersConfig={filtersConfig}
          filtersLabels={filterLabels}
          valueTranslator={valueTranslator}
        />
      </FiltersDrawer>
      <DataTable
        rows={rows}
        headRows={headRows}
        pageData={pageData}
        addRowAction={authorize(["OFFERS_WRITE"]) ? addRowAction : undefined}
        rowDetailsAction={rowDetailsAction}
        editRowAction={authorize(["OFFERS_WRITE"]) ? editRowAction : undefined}
        setPageNumber={setPageNumber}
        setPageLimit={setPageSize}
        onFiltersToggle={toggleFilterDrawer}
        onSearch={onSearch}
        customColumnRenderers={{
          status: (columnId, row) => (
            <TableCell key={columnId.toString()} align="center">
              <StatusCircle
                color={colorByOfferStatus[row.status]}
                status={t(`offers.status:${row.status}`)}
              />
            </TableCell>
          ),
        }}
      />
    </React.Fragment>
  )
}

export default OffersList
