import { FilterValue, FiltersPillsConfig } from "components/filters/model"
import { getStandardPill, getMultiselectFilterRenderer } from "components/filters/utils"

export type FiltersModel = {
  type: FilterValue<string>[]
  supplier: FilterValue<string>[]
}

type FiltersFields = keyof FiltersModel

const filtersConfig: FiltersPillsConfig<FiltersFields> = {
  type: {
    filterRenderer: getMultiselectFilterRenderer,
    pillRenderer: getStandardPill,
    customSerializer: (filterName: string, filters: FilterValue<string>[]) => ({
      [`${filterName}Ids`]: filters.map(fv => fv.value).join()
    })
  },
  supplier: {
    filterRenderer: getMultiselectFilterRenderer,
    pillRenderer: getStandardPill,
    customSerializer: (filterName: string, filters: FilterValue<string>[]) => ({
      [`${filterName}Ids`]: filters.map(fv => fv.value).join()
    })
  },
}

export default filtersConfig