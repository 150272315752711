import React from "react"
import { SnackbarVariant } from "components/snackbar/SnackbarWrapper"
import { noop } from "lodash"

export type FeedbackContextValue = (variant: SnackbarVariant, message: string) => void

interface FeedbackContextModel {
  showSnackbar: (variant: SnackbarVariant, message: string) => void
  setLoading: (loading: boolean) => void
}

const FeedbackContext = React.createContext<FeedbackContextModel>({
  showSnackbar: noop,
  setLoading: noop,
})

export default FeedbackContext
