import React from "react"
import { HeadRow, TableData } from "components/dataTable/DataTable"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { Offer } from "api/offers/models"
import offerExecutionApi from "api/offerExecution/offerExecutionApi"
import OfferExecutionTab from "./OfferExecutionTab"

export interface Data extends TableData {
  sentToCustomer: string
  sentToCustomerComments: string
  customerConfirmed: string
  customerConfirmedComments: string
  client: string
  constructionAddress: string
  installationsDays: string
  assemblersCount: string
  clientWishDate: string
  constructionType: string
}

const CustomerConfirmed: React.FC = () => {
  const { t } = useTranslation(["general", "offers.constructionType", "offers"])

  const headRows: HeadRow<Data>[] = [
    { id: "id", numeric: false, disablePadding: false, label: t("ID", { ns: "offers" }) },
    { id: "sentToCustomer", numeric: false, disablePadding: false, label: t("Sent to customer", { ns: "offers" }) },
    { id: "sentToCustomerComments", numeric: false, disablePadding: false, label: `${t("Comments", { ns: "offers" })}` },
    { id: "customerConfirmed", numeric: false, disablePadding: false, label: t("Customer confirmed", { ns: "offers" }) },
    {
      id: "customerConfirmedComments",
      numeric: false,
      disablePadding: false,
      label: `${t("Comments", { ns: "offers" })}`,
    },
    { id: "client", numeric: false, disablePadding: false, label: t("Client", { ns: "offers" }) },
    {
      id: "constructionAddress",
      numeric: false,
      disablePadding: false,
      label: t("Construction address", { ns: "offers" }),
    },
    { id: "installationsDays", numeric: false, disablePadding: false, label: t("Installation days", { ns: "offers" }) },
    { id: "assemblersCount", numeric: false, disablePadding: false, label: t("Assemblers count", { ns: "offers" }) },
    { id: "clientWishDate", numeric: false, disablePadding: false, label: t("Client wish date", { ns: "offers" }) },
    { id: "constructionType", numeric: false, disablePadding: false, label: t("Construction type", { ns: "offers" }) },
  ]

  const mapRows: (data: Offer[]) => Data[] = (data) =>
    data.map((r) => ({
      id: r.id,
      sentToCustomer: moment(r.measurement?.sentToCustomer?.date ?? "").format(t("DateFormat")),
      sentToCustomerComments: r.measurement?.sentToCustomer?.comments ?? "",
      customerConfirmed: moment(r.measurement?.customerConfirmed?.date ?? "").format(t("DateFormat")),
      customerConfirmedComments: r.measurement?.customerConfirmed?.comments ?? "",
      client: `${r.client.name}, ${r.client.address.city}, ${t(`${r.client.address.country}`)}`,
      constructionAddress: r.construction.address ?? "",
      installationsDays: r.installation?.days?.toString() ?? "",
      assemblersCount: r.installation?.assemblersCount?.toString() ?? "",
      clientWishDate: moment(r.creationDate).format(t("DateFormat")),
      constructionType: t(r.construction.type, { ns: "offers.constructionType" }),
    }))

  return (
    <OfferExecutionTab
      headRows={headRows}
      mapRows={mapRows}
      getData={offerExecutionApi.getCustomerConfirmed}
    />
  )
}

export default CustomerConfirmed
