import React, { useState } from "react"
import { KeyboardDatePicker } from "@material-ui/pickers"
import { ParsableDate } from "@material-ui/pickers/constants/prop-types"
import moment from "moment"
import { FormControl, FormLabel, FormGroup } from "@material-ui/core"
import { FilterComponentProps } from "./model"

type DateRangeFilterType = <T>(props: FilterComponentProps<T> & { dateFormat: string }) => JSX.Element

interface SelectedValue {
  value: {
    start: string | null
    end: string | null
  }
  label: string
}

const getLabel = (date: ParsableDate, dateFormat: string) => (date && moment(date).isValid()) ? moment(date).format(dateFormat) : "n/a"
const getValue = (date: ParsableDate) => date ? moment(date).toISOString() : null

const setValidDate = (
  oppositeInitialDate: ParsableDate,
  setDate: (date: ParsableDate) => void,
  onChange: (selected: SelectedValue) => void,
  dateFormat: string,
  type: "start" | "end"
) => (date: ParsableDate) => {
  setDate(date)
  const start = type === "start" ? getValue(date) : getValue(oppositeInitialDate)
  const end = type === "start" ? getValue(oppositeInitialDate) : getValue(date)
  if (onChange && ((date !== null && moment(date).isValid()) || date === null)) {
    onChange({
      value: { start, end },
      label: `${getLabel(start, dateFormat)} - ${getLabel(end, dateFormat)}`
    })
  }
}

const DateRangeFilter: DateRangeFilterType = ({ id, selected, name, onChange, dateFormat }) => {
  const [start, setStart] = useState<ParsableDate>(selected && selected.length > 0 && selected[0].value.start ? selected[0].value.start : null)
  const [end, setEnd] = useState<ParsableDate>(selected && selected.length > 0 && selected[0].value.end ? selected[0].value.end : null)

  const onSelectedChange: (selected: SelectedValue) => void = (selected) => {
    if (onChange) {
      onChange(id, [selected])
    }
  }

  const setValidStart = setValidDate(end, setStart, onSelectedChange, dateFormat, "start")
  const setValidEnd = setValidDate(start, setEnd, onSelectedChange, dateFormat, "end")

  return (
    <FormControl variant="outlined" style={{ width: "100%" }}>
      <FormLabel style={{ padding: "20px 0 5px" }}>{name}</FormLabel>
      <FormGroup>
        <KeyboardDatePicker
          autoOk
          disableToolbar
          variant="inline"
          format={dateFormat}
          margin="none"
          label={`Start date`}
          value={start}
          onChange={setValidStart}
        />
        <KeyboardDatePicker
          autoOk
          disableToolbar
          variant="inline"
          format={dateFormat}
          minDate={start !== null ? start : undefined}
          margin="dense"
          label={`End date`}
          value={end}
          onChange={setValidEnd}
        />
      </FormGroup>
    </FormControl>
  )
}

export default DateRangeFilter