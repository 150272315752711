import React, { useMemo } from "react"
import DataTable, { TableData, HeadRow } from "components/dataTable/DataTable"
import { useTranslation } from "react-i18next"
import { useList } from "hooks/useList"
import materialTypesApi from "api/materialTypes/materialTypesApi"
import { useHistory } from "react-router-dom"
import useRowDelete from "hooks/useRowDelete"
import { usePermissions } from "hooks/usePermissions"

interface Data extends TableData {
  name: string
  comments: string
}

const filters: { [key: string]: string } = {}

const MaterialTypesList: React.FC = () => {
  const { t } = useTranslation(["materialTypes"])
  const headRows: HeadRow<Data>[] = useMemo(() => [
    { id: "id", numeric: false, disablePadding: false, label: "Id" },
    { id: "name", numeric: false, disablePadding: false, label: t("Name") },
    { id: "comments", numeric: false, disablePadding: false, label: t("Comments") },
  ], [t])
  const history = useHistory()

  const { pageData, data, setPageSize, setPageNumber, onSearch, getList } = useList(
    filters,
    materialTypesApi.getMaterialTypesList,
  )

  const addRowAction = () => history.push("/management/materialTypes/add")
  const editRowAction = (rowId: string) => history.push(`/management/materialTypes/${rowId}/edit`)
  const deleteRowAction = useRowDelete(materialTypesApi.deleteMaterialType, getList)

  const authorize = usePermissions()

  const rows: Data[] | undefined = data && data.map(row => ({
    id: row.id,
    name: row.name,
    comments: row.comments
  }))
  return (
    <DataTable
      rows={rows}
      headRows={headRows}
      pageData={pageData}
      addRowAction={authorize(["MOUNTING_MATERIAL_TYPES_WRITE"]) ? addRowAction : undefined}
      rowClickAction={authorize(["MOUNTING_MATERIAL_TYPES_WRITE"]) ? editRowAction : undefined}
      editRowAction={authorize(["MOUNTING_MATERIAL_TYPES_WRITE"]) ? editRowAction : undefined}
      deleteRowAction={authorize(["MOUNTING_MATERIAL_TYPES_WRITE"]) ? deleteRowAction : undefined}
      setPageNumber={setPageNumber}
      setPageLimit={setPageSize}
      onSearch={onSearch}
    />
  )
}

export default MaterialTypesList
