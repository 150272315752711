import React, { Fragment, useCallback } from "react"
import { useHistory, useParams } from "react-router-dom"
import productsApi from "api/products/productsApi"
import { useTranslation } from "react-i18next"
import { useGetDetails } from "hooks/useGetDetails"
import { useSubmit } from "hooks/useSubmit"
import useQueryParam from "hooks/useQueryParam"
import { EditProductRequest } from "api/products/models"
import ProductEditForm from "./editForm/ProductEditForm"

const ProductEdit: React.FC = () => {
  const { t } = useTranslation("api")
  const history = useHistory()
  const { id } = useParams()

  const productData = useGetDetails(productsApi.getProduct, t("Error getting product data"))

  const referrer = useQueryParam("referrer")
  const editSuccessAction = useCallback(
    () => history.push(referrer || "/management/products"),
    [history, referrer]
  )
  const submitAction = useCallback((formData: EditProductRequest) => productsApi.editProduct(+id, formData), [id])
  const onSubmit = useSubmit(
    submitAction,
    t("Form saved"),
    t("Error saving data"),
    editSuccessAction
  )

  return (
    <Fragment>
      {productData && 
        <ProductEditForm
          productData={productData}
          onSubmit={onSubmit}
        />
      }
    </Fragment>
  )
}

export default ProductEdit
