import Axios from "axios"
import { PostLoginRequest } from "./models"

interface AuthApi {
  // TODO backend: this endpoint should return 401 if user passes incorrect credentials
  postLogin: (request: PostLoginRequest) => Promise<void>
  postLogout: () => Promise<void>
}

const authApi: AuthApi = {
  postLogin: (request) => Axios.post("/api/v1/auth/login", request),
  postLogout: () => Axios.post("/api/v1/auth/logout"),
}

export default authApi
