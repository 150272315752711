import * as Yup from "yup"
import { LoginFormValues } from "./LoginForm"
import { getTranslation } from "i18n"
import { ValidationObjectShape } from "validation"

const validationSchema = Yup.object<ValidationObjectShape<LoginFormValues>>({
  login: Yup.string()
    .required(() => getTranslation("validation", "Field is required"))
    .nullable(),
  password: Yup.string()
    .required(() => getTranslation("validation", "Field is required"))
    .nullable(),
})

export default validationSchema
