import React, { useMemo, useCallback } from "react"
import { useGetAll } from "hooks/useGetAll"
import offersApi from "api/offers/offersApi"
import { useTranslation } from "react-i18next"
import { useFilters } from "hooks/useFilters"
import { getFilterSerializers } from "components/filters/utils"
import filtersConfig, { FiltersModel } from "./FiltersConfig"
import Filters from "components/filters/Filters"
import { OfferStatus } from "api/offers/models"
import dealersApi from "api/dealers/dealersApi"
import Paper from "@material-ui/core/Paper"
import { makeStyles, createStyles } from "@material-ui/core"
import { doughnutChartConfig } from "../common"
import useChart, { ChartData } from "hooks/useChart"
import pickBy from "lodash/pickBy"
import identity from "lodash/identity"

const serializers = getFilterSerializers(filtersConfig)

const config = {
  chartConfig: doughnutChartConfig
}

const useStyles = makeStyles(theme => createStyles({
  viewContainer: {
    display: "flex",
    margin: theme.spacing(1),
  },
  filterPaper: { height: "100%" },
  chartContainer: { width: "100%", height: "100%" }
}))

const OfferAcquisition: React.FC = () => {
  const { t } = useTranslation("offers")
  const { t: tApi } = useTranslation("api")
  const { t: tAcquisition } = useTranslation("acquisitionMethods")
  const classes = useStyles()

  const valueTranslator = useCallback(label => t(label), [t])
  const filterLabels = useMemo(() => ({
    dealer: t("Dealer"),
    status: t("Status"),
    creationDate: t("Creation date")
  }), [t])

  const {
    onFilterChange,
    filters,
    serializedFilters,
  } = useFilters<FiltersModel>({ dealer: [], status: [], creationDate: [] }, serializers)

  const dealers = useGetAll(dealersApi.getAllDealers, tApi("Error getting dealers"))
  const getOfferAcquisitionStats = useCallback(() => offersApi.getOfferAcquisitionStats(serializedFilters), [serializedFilters])
  const offerAcquisitionAll = useGetAll(getOfferAcquisitionStats, tApi("Error getting offers acquisition stats"))
  const offerAcquisition = useMemo(() => pickBy(offerAcquisitionAll, identity), [offerAcquisitionAll])

  const availableFilters = useMemo(() => ({
    dealer: dealers?.map(d => ({ label: d.name, value: d.id })) ?? [],
    status: (Object.keys(OfferStatus) as OfferStatus[]).map(v => ({ label: v, value: v })),
    creationDate: []
  }), [dealers])

  const chartData: ChartData = useMemo(() => ({
    data: Object.values(offerAcquisition),
    labels: Object.keys(offerAcquisition).map(k => tAcquisition(k))
  }), [offerAcquisition, tAcquisition])

  const chartContainerRef = useChart(config, chartData)

  return (
    <div className={classes.viewContainer}>
      <Paper className={classes.filterPaper}>
        <Filters<FiltersModel>
          selectedFilters={filters}
          availableFilters={availableFilters}
          onFilterChange={onFilterChange}
          filtersConfig={filtersConfig}
          filtersLabels={filterLabels}
          valueTranslator={valueTranslator}
        />
      </Paper>
      <div className={classes.chartContainer}>
        <canvas ref={chartContainerRef}></canvas>
      </div>
    </div>
  )
}

export default OfferAcquisition
