import React, { useMemo, useCallback } from "react"
import { useGetAll } from "hooks/useGetAll"
import offersApi from "api/offers/offersApi"
import { useTranslation } from "react-i18next"
import { useFilters } from "hooks/useFilters"
import { getFilterSerializers } from "components/filters/utils"
import filtersConfig, { FiltersModel } from "./FiltersConfig"
import Filters from "components/filters/Filters"
import { OfferStatus } from "api/offers/models"
import dealersApi from "api/dealers/dealersApi"
import Paper from "@material-ui/core/Paper"
import { makeStyles, createStyles } from "@material-ui/core"
import { doughnutChartConfig } from "../common"
import useChart, { ChartData } from "hooks/useChart"
import DetailsSection from "components/details/DetailsSection"
import DetailField from "components/DetailField"
import { getBackgroundColor as defaultGetBackgroundColor } from "views/stats/common"
import { colorByOfferStatus } from "api/offers/utils"
import pickBy from "lodash/pickBy"
import identity from "lodash/identity"

const serializers = getFilterSerializers(filtersConfig)

const getBackgroundColorByTranslation: (
  translator: (v: string) => string
) => Chart.Scriptable<Chart.ChartColor> = (t) => (context) => {
  const dataLabel =
    context.dataIndex !== undefined && context.chart?.data.labels
      ? context.chart?.data.labels[context.dataIndex]
      : ""
  const key = Object.keys(colorByOfferStatus).find((offer) => t(offer) === dataLabel)
  return key ? colorByOfferStatus[key as OfferStatus] : defaultGetBackgroundColor(context)
}

const useStyles = makeStyles((theme) =>
  createStyles({
    viewContainer: {
      display: "flex",
      margin: theme.spacing(1),
    },
    summaryContainer: { display: "flex", flexDirection: "column", width: "100%", alignItems: "center" },
    filterPaper: { height: "100%" },
    offerSummary: {
      minWidth: "300px",
      padding: theme.spacing(2),
    },
    chartContainer: { width: "100%", height: "100%" },
  })
)

const Profits: React.FC = () => {
  const { t } = useTranslation(["offers", "stats.profits"])
  const { t: tApi } = useTranslation("api")
  const { t: tStatus } = useTranslation("offers.status")
  const classes = useStyles()

  const valueTranslator = useCallback((label) => t(label), [t])
  const filterLabels = useMemo(
    () => ({
      dealer: t("Dealer"),
      status: t("Status"),
      creationDate: t("Creation date"),
    }),
    [t]
  )

  const { onFilterChange, filters, serializedFilters } = useFilters<FiltersModel>(
    { dealer: [], status: [], creationDate: [] },
    serializers
  )

  const dealers = useGetAll(dealersApi.getAllDealers, tApi("Error getting dealers"))
  const getOfferSummaryStats = useCallback(
    () => offersApi.getOfferSummaryStats(serializedFilters),
    [serializedFilters]
  )
  const summary = useGetAll(getOfferSummaryStats, tApi("Error getting offers summary stats"))

  const availableFilters = useMemo(
    () => ({
      dealer: dealers?.map((d) => ({ label: d.name, value: d.id })) ?? [],
      status: (Object.keys(OfferStatus) as OfferStatus[]).map((v) => ({ label: v, value: v })),
      creationDate: [],
    }),
    [dealers]
  )

  const config = useMemo(
    () => ({
      chartConfig: doughnutChartConfig,
      getBackgroundColor: getBackgroundColorByTranslation(tStatus),
    }),
    [tStatus]
  )

  const chartData: ChartData = useMemo(() => {
    const statuses = pickBy(summary?.statusesShare || {}, identity)
    return {
      data: Object.values(statuses),
      labels: Object.keys(statuses).map((k) => tStatus(k)),
    }
  }, [summary, tStatus])

  const chartContainerRef = useChart(config, chartData)

  return (
    <div className={classes.viewContainer}>
      <Paper className={classes.filterPaper}>
        <Filters<FiltersModel>
          selectedFilters={filters}
          availableFilters={availableFilters}
          onFilterChange={onFilterChange}
          filtersConfig={filtersConfig}
          filtersLabels={filterLabels}
          valueTranslator={valueTranslator}
        />
      </Paper>
      {summary && (
        <div className={classes.summaryContainer}>
          <Paper className={classes.offerSummary}>
            <DetailsSection title={t("stats.profits:Offers summary")}>
              <DetailField label={t("stats.profits:Total count")} value={summary.count} />
              <DetailField label={t("stats.profits:Total amount")} value={`${summary.totalAmount} €`} />
              <DetailField label={t("stats.profits:Total profit")} value={`${summary.totalProfit} €`} />
              <DetailField label={t("stats.profits:Average profit")} value={`${summary.averageProfit} €`} />
              <DetailField
                label={t("stats.profits:Average profit percentage")}
                value={`${summary.averageProfitPercentage} %`}
              />
            </DetailsSection>
          </Paper>
          <div className={classes.chartContainer}>
            <canvas ref={chartContainerRef}></canvas>
          </div>
        </div>
      )}
    </div>
  )
}

export default Profits
