import React, { useCallback } from "react"
import { Theme, makeStyles, createStyles } from "@material-ui/core"
import InputBase from "@material-ui/core/InputBase"
import Paper from "@material-ui/core/Paper"
import SearchIcon from "@material-ui/icons/Search"
import IconButton from "@material-ui/core/IconButton"
import debounce from "lodash/debounce"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "2px 4px",
      margin: "10px 0",
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
  })
)

const SearchBar: React.FC<{ onSearch?: (searchPhrase: string) => void; placeholder: string }> = ({
  onSearch,
  placeholder,
}) => {
  const classes = useStyles()
  const debounced = debounce((value: string) => {
    if (onSearch) {
      onSearch(value)
    }
  }, 500)
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => debounced(e.target.value),
    [debounced]
  )
  return (
    <Paper component="form" className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder={placeholder}
        inputProps={{ "aria-label": placeholder }}
        onChange={onChange}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault()
          }
        }}
      />
      <IconButton className={classes.iconButton} disabled>
        <SearchIcon />
      </IconButton>
    </Paper>
  )
}

export default SearchBar
