import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "center"
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logoPaper: {
    width: "50%",
    marginBottom: theme.spacing(6),
  },
  logoImage: {
    width: "100%",
    padding: theme.spacing(2)
  },
  langContainer: {
    display: "flex",
    marginTop: theme.spacing(8),
  }
}))

export default useStyles