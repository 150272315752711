import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pillsContainer: {
      display: "flex",
      justifyContent: "left",
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(3),
    }
  }),
)

export default useStyles
