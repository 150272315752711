import * as Yup from "yup"
import { getTranslation } from "i18n"
import { Product } from "api/products/models"
import { ValidationObjectShape } from "validation"

const validationSchema = Yup.object<ValidationObjectShape<Product>>({
  name: Yup.string()
    .required(() => getTranslation("validation", "Field is required"))
    .nullable(),
})

export default validationSchema
