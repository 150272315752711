import React, { useState, Fragment, useEffect } from "react"
import { Drawer, Divider, ListItem, ListItemIcon, ListItemText, List } from "@material-ui/core"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import Collapse from "@material-ui/core/Collapse"
import { NavLink, Route, withRouter, RouteComponentProps } from "react-router-dom"
import { MenuConfigItem } from "config/MenuConfig"
import useStyles from "./Menu.styles"
import logo from "assets/logo.jpg"
import { useTranslation } from "react-i18next"
import flatten from "lodash/flatten"
import Authorize from "components/Authorize"
import classNames from "classnames"

const getIsActiveHandler = (path: string[] | undefined) => {
  if (path) {
    return (_: unknown, location: { pathname: string }) =>
      path ? path.some((p) => location.pathname.includes(p)) : false
  }
  return undefined
}

interface MenuProps {
  open?: boolean
  onMenuToggle?: () => void
  menuConfig: MenuConfigItem[]
}

const Menu: React.FunctionComponent<MenuProps & RouteComponentProps> = ({
  open = true,
  onMenuToggle,
  location,
  menuConfig,
}) => {
  const classes = useStyles()
  const [menuState, setMenuState] = useState<{ [key: string]: boolean }>({})
  const { t } = useTranslation("menu")
  const onToggle = (key: string) => {
    setMenuState({ ...menuState, [key]: menuState[key] ? !menuState[key] : true })
  }
  useEffect(() => {
    const matchedItem = menuConfig.find((item) =>
      item.subItems ? item.subItems.some((subItem) => subItem.link === location.pathname) : false
    )
    if (matchedItem) {
      setMenuState({ ...menuState, [matchedItem.label]: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Drawer
      variant="permanent"
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      open={open}
    >
      <div className={classes.toolbar} onClick={onMenuToggle}>
        {open && <img src={logo} alt="Logo" className={classes.logo} />}
      </div>
      <Divider />
      <List>
        {menuConfig.map((menuItem) => (
          <Route
            key={menuItem.label}
            path={
              menuItem.subItems
                ? flatten(
                    menuItem.subItems.map((subItem) => (subItem.path ? subItem.path : subItem.link) || "")
                  ).filter((item) => item)
                : []
            }
            children={({ match }) => (
              <Fragment>
                <TopLevelMenuItem link={menuItem.link}>
                  <ListItem button onClick={menuItem.subItems ? () => onToggle(menuItem.label) : undefined}>
                    <ListItemIcon className={classes.menuIcon}>
                      {!open && menuState[menuItem.label] ? <ExpandLess /> : menuItem.icon}
                    </ListItemIcon>
                    <ListItemText primary={t(menuItem.label)} />
                    {menuItem.subItems && (menuState[menuItem.label] ? <ExpandLess /> : <ExpandMore />)}
                  </ListItem>
                </TopLevelMenuItem>
                {menuItem.subItems && (
                  <Collapse
                    in={menuState[menuItem.label] === undefined ? match !== null : menuState[menuItem.label]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {menuItem.subItems.map((subItem) => (
                        <Authorize key={subItem.label} permissions={subItem.permissions}>
                          <NavLink
                            isActive={getIsActiveHandler(subItem.path)}
                            key={subItem.label}
                            to={subItem.link || ""}
                            className={classes.navLink}
                            activeClassName={classes.active}
                          >
                            <ListItem button className={open ? classes.nestedOpen : classes.nestedClosed}>
                              <ListItemIcon className={classes.menuIcon}>{subItem.icon}</ListItemIcon>
                              <ListItemText className={classes.menuLabel} primary={t(subItem.label)} />
                            </ListItem>
                          </NavLink>
                        </Authorize>
                      ))}
                    </List>
                    <Divider />
                  </Collapse>
                )}
              </Fragment>
            )}
          />
        ))}
      </List>
    </Drawer>
  )
}

const TopLevelMenuItem: React.FC<{ link?: string }> = ({ link, children }) => {
  const classes = useStyles()
  return link ? (
    <NavLink to={link} activeClassName={classes.active} className={classes.navLink}>
      {children}
    </NavLink>
  ) : (
    <Fragment>{children}</Fragment>
  )
}

export default withRouter(Menu)
