/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as Yup from "yup"
import { getTranslation } from "i18n"
import { User } from "api/user/models"
import { Role } from "api/roles/models"
import { ValidationObjectShape } from "validation"

const getValidationSchema = (create: boolean) => {
  const schema = {
    login: Yup.string()
      .required(() => getTranslation("validation", "Field is required"))
      .nullable(),
    email: Yup.string()
      .required(() => getTranslation("validation", "Field is required"))
      .nullable(),
    firstName: Yup.string()
      .required(() => getTranslation("validation", "Field is required"))
      .nullable(),
    lastName: Yup.string()
      .required(() => getTranslation("validation", "Field is required"))
      .nullable(),
    roles: Yup.array<Role>()
      .required(() => getTranslation("validation", "Field is required"))
      .nullable(),
    password: Yup.string()
      .required(() => getTranslation("validation", "Field is required"))
      .nullable(),
  }
  const { password, ...editSchema } = schema
  return Yup.object<ValidationObjectShape<User>>(create ? schema : editSchema)
}

export default getValidationSchema
