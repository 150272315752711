import React, { Fragment, useState } from "react"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm"
import CheckIcon from "@material-ui/icons/Check"
import { makeStyles, createStyles, Theme } from "@material-ui/core"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      margin: `${theme.spacing(0.25)}px 0 0 ${theme.spacing(1)}px`,
      fontWeight: theme.typography.fontWeightRegular,
    },
    expansionDetails: {
      justifyContent: "center",
      padding: 0,
    },
    urgent: {
      color: "red",
    },
    important: {
      color: "orange",
    },
    normal: {
      color: theme.palette.primary.main,
    }
  }),
)

interface PriorityExpansionPanelProps {
  urgent: JSX.Element
  urgentHeader?: JSX.Element
  important: JSX.Element
  importantHeader?: JSX.Element
  normal: JSX.Element
  normalHeader?: JSX.Element
}

const PriorityExpansionPanel: React.FC<PriorityExpansionPanelProps> = ({
  urgent,
  important,
  normal,
  urgentHeader,
  importantHeader,
  normalHeader
}) => {
  const classes = useStyles()
  const [urgentExpanded, setUrgentExpanded] = useState(true)
  const [importantExpanded, setImportantExpanded] = useState(true)
  const [normalExpanded, setNormalExpanded] = useState(true)
  const { t } = useTranslation("general")
  return (
    <Fragment>
      <Accordion expanded={urgentExpanded} onChange={(e, expanded) => setUrgentExpanded(expanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="urgent-panel-content"
        >
          {urgentHeader ||
            <Fragment>
              <ErrorOutlineIcon className={classes.urgent} />
              <Typography className={classes.heading}>{t("Urgent")}</Typography>
            </Fragment>
          }
        </AccordionSummary>
        <AccordionDetails className={classes.expansionDetails}>
          {urgent}
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={importantExpanded} onChange={(e, expanded) => setImportantExpanded(expanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="important-panel-content"
        >
          {importantHeader ||
            <Fragment>
              <AccessAlarmIcon className={classes.important} />
              <Typography className={classes.heading}>{t("Important")}</Typography>
            </Fragment>
          }
        </AccordionSummary>
        <AccordionDetails className={classes.expansionDetails}>
          {important}
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={normalExpanded} onChange={(e, expanded) => setNormalExpanded(expanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="normal-panel-content"
        >
          {normalHeader ||
            <Fragment>
              <CheckIcon className={classes.normal} />
              <Typography className={classes.heading}>{t("Normal")}</Typography>
            </Fragment>
          }
        </AccordionSummary>
        <AccordionDetails className={classes.expansionDetails}>
          {normal}
        </AccordionDetails>
      </Accordion>
    </Fragment>
  )
}

export default PriorityExpansionPanel