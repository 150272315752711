import * as Yup from "yup"
import { Counterparty, Address, Contact, AdditionalContact } from "api/counterparties/models"
import { getTranslation } from "i18n"
import { ValidationObjectShape } from "validation"

const validationSchema = Yup.object<ValidationObjectShape<Counterparty>>({
  name: Yup.string()
    .required(() => getTranslation("validation", "Field is required"))
    .nullable(),
  roles: Yup.array().required(() => getTranslation("validation", "Field is required")),
  address: Yup.object<ValidationObjectShape<Address>>({
    street: Yup.string()
      .required(() => getTranslation("validation", "Field is required"))
      .nullable(),
    houseNumber: Yup.string()
      .required(() => getTranslation("validation", "Field is required"))
      .nullable(),
    postalCode: Yup.string()
      .required(() => getTranslation("validation", "Field is required"))
      .nullable(),
    city: Yup.string()
      .required(() => getTranslation("validation", "Field is required"))
      .nullable(),
  }),
  contact: Yup.object<ValidationObjectShape<Contact>>({
    email: Yup.string()
      .email(() => getTranslation("validation", "Incorrect email"))
      .nullable(),
  }),
  additionalContacts: Yup.array().of(
    Yup.object<ValidationObjectShape<AdditionalContact>>({
      name: Yup.string()
        .required(() => getTranslation("validation", "Field is required"))
        .nullable(),
      email: Yup.string()
        .email(() => getTranslation("validation", "Incorrect email"))
        .nullable(),
    })
  ),
})

export default validationSchema
