import { FilterValue, FiltersConfig, DateRange } from "components/filters/model"
import { getMultiselectFilterRenderer, getDateRangeFilterRenderer, dateRangeSerializer } from "components/filters/utils"
import { OfferStatus } from "api/offers/models"
import { getTranslation } from "i18n"

export type FiltersModel = {
  dealer: FilterValue<string>[]
  status: FilterValue<OfferStatus>[]
  creationDate: FilterValue<DateRange>[]
}

type FiltersFields = keyof FiltersModel

const filtersConfig: FiltersConfig<FiltersFields> = {
  dealer: {
    filterRenderer: ({ valueTranslator, ...restProps }) => getMultiselectFilterRenderer({
      valueTranslator: label => label,
      ...restProps
    }),
    customSerializer: (filterName: string, filters: FilterValue<string>[]) => ({
      [`${filterName}Id`]: filters.map(fv => fv.value).join()
    })
  },
  status: {
    filterRenderer: ({ valueTranslator, ...restProps }) => getMultiselectFilterRenderer({
      valueTranslator: label => getTranslation("offers.status", label),
      ...restProps
    }),
  },
  creationDate: {
    filterRenderer: getDateRangeFilterRenderer,
    customSerializer: dateRangeSerializer
  }
}

export default filtersConfig