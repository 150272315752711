import { makeStyles } from "@material-ui/core/styles"

const drawerWidth = 260

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  spacer: {
    flexGrow: 1,
  },
  avatar: {
    textTransform: "uppercase",
    backgroundColor: theme.palette.secondary.main,
  },
  userButton: {
    margin: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px ${theme.spacing(3)}px`,
  },
  userIcon: {
    marginRight: theme.spacing(1),
  },
}))

export default useStyles
