/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import useStyles from "./Filters.styles"
import { FiltersConfig } from "./model"
import classNames from "classnames"
import identity from "lodash/identity"

interface FiltersProps<T extends { [key: string]: any }> {
  className?: string
  selectedFilters: T
  availableFilters: T
  onFilterChange?: (filterName: keyof T, values: T[keyof T]) => void
  filtersConfig: FiltersConfig<keyof T>
  filtersLabels: { [key in keyof T]: string }
  valueTranslator?: (label: string) => string
}

const Filters = <T extends { [key: string]: any }>(props: FiltersProps<T>): JSX.Element => {
  const {
    selectedFilters,
    availableFilters,
    onFilterChange,
    filtersConfig,
    filtersLabels,
    valueTranslator = identity,
    className,
  } = props
  const classes = useStyles()

  return (
    <React.Fragment>
      {Object.keys(filtersConfig).map((fk) => (
        <div key={fk} className={classNames(classes.filterContainer, className)}>
          {filtersConfig[fk].filterRenderer({
            key: fk,
            selectedFilters: selectedFilters[fk],
            availableFilters: availableFilters[fk],
            onFilterChange,
            filterLabel: filtersLabels[fk],
            valueTranslator,
          })}
        </div>
      ))}
    </React.Fragment>
  )
}

export default Filters
