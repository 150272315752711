import React from "react"
import {
  Drawer,
  AppBar,
  Typography,
  Toolbar,
  IconButton
} from "@material-ui/core"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import useStyles from "./FiltersDrawer.styles"
import { useTranslation } from "react-i18next"

interface FiltersDrawerProps {
  opened: boolean
  onClose?: () => void
}

const FiltersDrawer: React.FunctionComponent<FiltersDrawerProps> = ({ opened, onClose, children }) => {
  const classes = useStyles()
  const { t } = useTranslation("general")

  return (
    <Drawer anchor="right" open={opened} onClose={onClose}>
      <AppBar position="absolute">
        <Toolbar>
          <IconButton onClick={onClose} edge="start" color="inherit" aria-label="close">
            <KeyboardArrowRightIcon />
          </IconButton>
          <Typography variant="h6" noWrap>{t("Filters")}</Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.filtersContainer}>
        {children}
      </div>
    </Drawer>
  )
}

export default FiltersDrawer
