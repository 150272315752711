import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(0.5),
    },
    chipLabel: {
      maxWidth: 400,
      textOverflow: "ellipsis",
      overflow: "hidden",
    }
  }),
)

export default useStyles
