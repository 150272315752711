import { makeStyles, createStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      maxWidth: "1000px",
      display: "flex",
      flexDirection: "column",
      margin: `${theme.spacing(3)}px ${theme.spacing(3)}px 50px ${theme.spacing(3)}px`,
    },
    paper: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(3),
      width: "100%",
    },
    rowContainer: {
      display: "flex",
    },
    rowCenterContainer: {
      display: "flex",
      alignItems: "center",
    },
    statusCircle: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: 0,
    },
    amountsField: {
      fontWeight: theme.typography.fontWeightMedium,
      paddingLeft: theme.spacing(0.5)
    },
    measurementFieldGroup: {
      paddingLeft: theme.spacing(5),
    },
    addButton: {
      marginTop: theme.spacing(1),
    },
    productContainer: {
      display: "flex"
    },
    productLabel: {
      marginTop: theme.spacing(0.25),
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
    },
    addProductButton: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(-0.5),
    },
    deleteButton: {
      padding: theme.spacing(1),
    },
    badge: {
      display: "flex",
      alignItems: "center",
    },
    label: {
      paddingRight: theme.spacing(1),
      color: theme.palette.grey[600],
    },
    editButton: {
      padding: theme.spacing(0.5),
      marginLeft: theme.spacing(1),
    },
    addMountingMaterialButton: {
      marginTop: -theme.spacing(0.5),
    },
    addOfferCalculation: {
      textDecoration: "none",
    }
  }))

export default useStyles